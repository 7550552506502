import type { ParseKeys } from 'i18next';
import type { Label, Action } from '../types';

const HERO_SECTION_ID = 'hero';
const CONTACT_SECTION_ID = 'contact';
const PRICING_SECTION_ID = 'pricing';
const BENEFITS_SECTION_ID = 'benefits';

// FIXME: https://carvertical.atlassian.net/browse/FE-12
const SOLUTION_KEYS: ParseKeys<'business'>[] = [
  // @ts-expect-error TS(2322)
  'heroSection.solution.0',
  // @ts-expect-error TS(2322)
  'heroSection.solution.1',
  // @ts-expect-error TS(2322)
  'heroSection.solution.2',
];

// FIXME: https://carvertical.atlassian.net/browse/FE-12
const INSURANCE_SOLUTION_KEYS: ParseKeys<'business'>[] = [
  // @ts-expect-error TS(2322)
  'insuranceHeroSection.solution.0',
  // @ts-expect-error TS(2322)
  'insuranceHeroSection.solution.1',
  // @ts-expect-error TS(2322)
  'insuranceHeroSection.solution.2',
];

// FIXME: https://carvertical.atlassian.net/browse/FE-12
const LEASING_SOLUTION_KEYS: ParseKeys<'business'>[] = [
  // @ts-expect-error TS(2322)
  'leasingHeroSection.solution.0',
  // @ts-expect-error TS(2322)
  'leasingHeroSection.solution.1',
  // @ts-expect-error TS(2322)
  'leasingHeroSection.solution.2',
];

// FIXME: https://carvertical.atlassian.net/browse/FE-12
const API_SOLUTION_KEYS: ParseKeys<'business'>[] = [
  // @ts-expect-error TS(2322)
  'apiHeroSection.solution.0',
  // @ts-expect-error TS(2322)
  'apiHeroSection.solution.1',
  // @ts-expect-error TS(2322)
  'apiHeroSection.solution.2',
];

const PRICING_ACTION: Action = {
  id: PRICING_SECTION_ID,
  labelKey: 'common:general.checkPricingActionFormal',
  variant: 'yellow',
};

const CONTACT_ACTION: Action = {
  id: CONTACT_SECTION_ID,
  labelKey: 'common:general.getInTouchActionFormal',
  variant: 'yellow',
};

const BENEFITS_ACTION: Action = {
  id: BENEFITS_SECTION_ID,
  labelKey: 'common:general.learnMoreAction',
  variant: 'outlined',
};

const CUSTOM_SOLUTION_ACTION: Action = {
  id: CONTACT_SECTION_ID,
  labelKey: 'business:general.getCustomSolutionActionFormal',
  variant: 'yellow',
};

const BUSINESS_ACTIONS: Action[] = [
  { ...PRICING_ACTION },
  { ...CONTACT_ACTION, variant: 'outlined' },
];
const INSURANCE_ACTIONS: Action[] = [{ ...CONTACT_ACTION }, { ...BENEFITS_ACTION }];
const LEASING_ACTIONS: Action[] = [{ ...CONTACT_ACTION }, { ...BENEFITS_ACTION }];
const API_ACTIONS: Action[] = [{ ...CUSTOM_SOLUTION_ACTION }, { ...BENEFITS_ACTION }];

const MAIN_ACTIVITIES: Label[] = [
  { labelKey: 'contactSection.activities.carDealerTitle' },
  { labelKey: 'contactSection.activities.insuranceTitle' },
  { labelKey: 'contactSection.activities.classifiedTitle' },
  { labelKey: 'contactSection.activities.otherTitle' },
];

const ESTIMATED_REPORT_COUNTS = [
  { label: '10-20' },
  { label: '20-50' },
  { label: '50-100' },
  { label: '100+' },
];

export {
  PRICING_SECTION_ID,
  MAIN_ACTIVITIES,
  ESTIMATED_REPORT_COUNTS,
  HERO_SECTION_ID,
  CONTACT_SECTION_ID,
  BENEFITS_SECTION_ID,
  BUSINESS_ACTIONS,
  INSURANCE_ACTIONS,
  LEASING_ACTIONS,
  API_ACTIONS,
  SOLUTION_KEYS,
  INSURANCE_SOLUTION_KEYS,
  LEASING_SOLUTION_KEYS,
  API_SOLUTION_KEYS,
};
