import { snakeCase, omitBy, isNil } from 'lodash';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import posthog, { type PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useTracking } from 'react-tracking';
import Cookies from 'js-cookie';
import { posthogConfig } from 'config';
import { mapKeysDeep, flattenPropertiesDeep } from 'utils/object';
import { isServer } from 'utils/ssr';
import withContainers from 'hoc/withContainers';
import UserContainer from 'containers/UserContainer';

type TrackingProviderProps = React.PropsWithChildren<{
  containers: {
    user: UserContainer;
  };
}>;

const APP_NAME = 'cvWeb';

const posthogClient = initPosthogClient();

const useUserIdentification = (userId?: string) => {
  useEffect(() => {
    if (userId) {
      posthog.identify(userId);
    }
    return () => {
      if (userId) {
        posthog.reset();
      }
    };
  }, [userId]);
};

const useRouteChangeTracking = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeStart = () => posthogClient?.capture('$pageleave');
    const handleRouteChangeComplete = () => posthogClient?.capture('$pageview');

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router.events]);
};

const TrackingProvider = ({ containers: { user }, children }: TrackingProviderProps) => {
  const { Track } = useTracking<{
    event: string;
    [key: string]: unknown;
  }>(
    { app: APP_NAME },
    {
      dispatch: ({ event, ...payload }) => {
        const properties = omitBy(
          flattenPropertiesDeep(mapKeysDeep(payload, (_, key) => snakeCase(key))),
          isNil,
        );
        posthogClient?.capture(event, properties);
      },
    },
  );

  useUserIdentification(user?.id);
  useRouteChangeTracking();

  return (
    <PostHogProvider client={posthogClient}>
      <Track>{children}</Track>
    </PostHogProvider>
  );
};

function initPosthogClient() {
  const { apiKey, apiHost } = posthogConfig;
  if (isServer || !apiKey || !apiHost) {
    return undefined;
  }

  const bootstrapDataCookie = Cookies.get(posthogConfig.bootstrapCookieKey);

  const bootstrapData:
    | Pick<PostHogConfig['bootstrap'], 'featureFlags' | 'featureFlagPayloads' | 'distinctID'>
    | undefined = bootstrapDataCookie ? JSON.parse(bootstrapDataCookie) : undefined;

  const posthogInstance = posthog.init(apiKey, {
    api_host: apiHost,
    autocapture: false,
    person_profiles: 'identified_only',
    bootstrap: bootstrapData,
    advanced_disable_feature_flags_on_first_load: true,
  });

  if (bootstrapData) {
    const { featureFlags, featureFlagPayloads } = bootstrapData;

    // FIXME: https://github.com/PostHog/posthog-js/issues/859
    // Setting flags via `bootstrap` skips `false` values, so it can still flicker
    posthogInstance?.featureFlags.receivedFeatureFlags({
      featureFlags,
      featureFlagPayloads,
    });
  }

  return posthogInstance ?? undefined;
}

const WrappedTrackingProvider = withContainers({ user: UserContainer })(TrackingProvider);

export { WrappedTrackingProvider as TrackingProvider };
export type { TrackingProviderProps };
