import { Stack, Text } from '@carvertical/ui';
import { RoundedImage } from '../RoundedImage';
import type { ImageBlockData } from '../../../types';
import styles from './ImageBlock.module.scss';

type ImageBlockProps = ImageBlockData;

const ImageBlock = ({ asset, caption, ...props }: ImageBlockProps) => {
  if (!asset) {
    return null;
  }

  return (
    <Stack as="figure" className={styles.root}>
      <RoundedImage image={{ caption, asset, ...props }} />

      <Text as="figcaption" variant="s" textColor="darkSecondary">
        {caption}
      </Text>
    </Stack>
  );
};

export { ImageBlock };
export type { ImageBlockProps };
