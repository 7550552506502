import { SkeletonWrapper, type SkeletonWrapperProps, Text } from '@carvertical/ui';
import { cn } from '@carvertical/utils/styling';

type ListItemRowProps = {
  label: string;
  value?: React.ReactNode;
  loading?: boolean;
  multiline?: boolean;
  highlighted?: boolean;
};

const ListItemRow = ({ label, value, loading, multiline, highlighted }: ListItemRowProps) => {
  const skeletonWrapperProps: SkeletonWrapperProps = {
    loading,
    radius: 'l',
  };

  return (
    <div className={cn('grid w-full grid-cols-2 gap-5 text-black', highlighted && 'text-red')}>
      <SkeletonWrapper {...skeletonWrapperProps}>
        <Text variant="s" textColor="darkSecondary">
          {label}
        </Text>
      </SkeletonWrapper>

      <SkeletonWrapper {...skeletonWrapperProps}>
        <Text
          as="span"
          variant="s"
          textColor={multiline ? 'inherited' : 'dark'}
          className={multiline ? 'flex flex-col' : ''}
        >
          {value ?? null}
        </Text>
      </SkeletonWrapper>
    </div>
  );
};

export { ListItemRow };
export type { ListItemRowProps };
