import Ge, { useContext as Le, createContext as Ne, useRef as j, useCallback as S, useEffect as N, useState as Y, useMemo as Se } from "react";
import { a as xe, h as U, b as Me, m as Ve, f as Re } from "./utils.371a0d8b.js";
const T = (e) => {
  var t;
  return (t = e == null ? void 0 : e.ownerDocument) !== null && t !== void 0 ? t : document;
}, L = (e) => e && "window" in e && e.window === e ? e : T(e).defaultView || window, je = /* @__PURE__ */ new Set([
  "id"
]), ze = /* @__PURE__ */ new Set([
  "aria-label",
  "aria-labelledby",
  "aria-describedby",
  "aria-details"
]), Ye = /* @__PURE__ */ new Set([
  "href",
  "hrefLang",
  "target",
  "rel",
  "download",
  "ping",
  "referrerPolicy"
]), Xe = /^(data-.*)$/;
function Ot(e, t = {}) {
  let { labelable: r, isLink: o, propNames: a } = t, c = {};
  for (const l in e)
    Object.prototype.hasOwnProperty.call(e, l) && (je.has(l) || r && ze.has(l) || o && Ye.has(l) || a != null && a.has(l) || Xe.test(l)) && (c[l] = e[l]);
  return c;
}
function O(e) {
  if (qe())
    e.focus({
      preventScroll: !0
    });
  else {
    let t = Je(e);
    e.focus(), Qe(t);
  }
}
let J = null;
function qe() {
  if (J == null) {
    J = !1;
    try {
      document.createElement("div").focus({
        get preventScroll() {
          return J = !0, !0;
        }
      });
    } catch {
    }
  }
  return J;
}
function Je(e) {
  let t = e.parentNode, r = [], o = document.scrollingElement || document.documentElement;
  for (; t instanceof HTMLElement && t !== o; )
    (t.offsetHeight < t.scrollHeight || t.offsetWidth < t.scrollWidth) && r.push({
      element: t,
      scrollTop: t.scrollTop,
      scrollLeft: t.scrollLeft
    }), t = t.parentNode;
  return o instanceof HTMLElement && r.push({
    element: o,
    scrollTop: o.scrollTop,
    scrollLeft: o.scrollLeft
  }), r;
}
function Qe(e) {
  for (let { element: t, scrollTop: r, scrollLeft: o } of e)
    t.scrollTop = r, t.scrollLeft = o;
}
function ie(e) {
  var t;
  return typeof window > "u" || window.navigator == null ? !1 : ((t = window.navigator.userAgentData) === null || t === void 0 ? void 0 : t.brands.some((r) => e.test(r.brand))) || e.test(window.navigator.userAgent);
}
function ge(e) {
  var t;
  return typeof window < "u" && window.navigator != null ? e.test(((t = window.navigator.userAgentData) === null || t === void 0 ? void 0 : t.platform) || window.navigator.platform) : !1;
}
function V() {
  return ge(/^Mac/i);
}
function Ze() {
  return ge(/^iPhone/i);
}
function Ke() {
  return ge(/^iPad/i) || // iPadOS 13 lies and says it's a Mac, but we can distinguish by detecting touch support.
  V() && navigator.maxTouchPoints > 1;
}
function be() {
  return Ze() || Ke();
}
function Ct() {
  return V() || be();
}
function et() {
  return ie(/AppleWebKit/i) && !tt();
}
function tt() {
  return ie(/Chrome/i);
}
function Fe() {
  return ie(/Android/i);
}
function rt() {
  return ie(/Firefox/i);
}
const nt = /* @__PURE__ */ Ne({
  isNative: !0,
  open: at,
  useHref: (e) => e
});
function ot() {
  return Le(nt);
}
function At(e, t) {
  let r = e.getAttribute("target");
  return (!r || r === "_self") && e.origin === location.origin && !e.hasAttribute("download") && !t.metaKey && // open in new tab (mac)
  !t.ctrlKey && // open in new tab (windows)
  !t.altKey && // download
  !t.shiftKey;
}
function R(e, t, r = !0) {
  var o, a;
  let { metaKey: c, ctrlKey: l, altKey: f, shiftKey: p } = t;
  rt() && (!((a = window.event) === null || a === void 0 || (o = a.type) === null || o === void 0) && o.startsWith("key")) && e.target === "_blank" && (V() ? c = !0 : l = !0);
  let g = et() && V() && !Ke() ? new KeyboardEvent("keydown", {
    keyIdentifier: "Enter",
    metaKey: c,
    ctrlKey: l,
    altKey: f,
    shiftKey: p
  }) : new MouseEvent("click", {
    metaKey: c,
    ctrlKey: l,
    altKey: f,
    shiftKey: p,
    bubbles: !0,
    cancelable: !0
  });
  R.isOpening = r, O(e), e.dispatchEvent(g), R.isOpening = !1;
}
R.isOpening = !1;
function it(e, t) {
  if (e instanceof HTMLAnchorElement)
    t(e);
  else if (e.hasAttribute("data-href")) {
    let r = document.createElement("a");
    r.href = e.getAttribute("data-href"), e.hasAttribute("data-target") && (r.target = e.getAttribute("data-target")), e.hasAttribute("data-rel") && (r.rel = e.getAttribute("data-rel")), e.hasAttribute("data-download") && (r.download = e.getAttribute("data-download")), e.hasAttribute("data-ping") && (r.ping = e.getAttribute("data-ping")), e.hasAttribute("data-referrer-policy") && (r.referrerPolicy = e.getAttribute("data-referrer-policy")), e.appendChild(r), t(r), e.removeChild(r);
  }
}
function at(e, t) {
  it(e, (r) => R(r, t));
}
function _t(e) {
  return {
    "data-href": e.href,
    "data-target": e.target,
    "data-rel": e.rel,
    "data-download": e.download,
    "data-ping": e.ping,
    "data-referrer-policy": e.referrerPolicy
  };
}
function Dt(e) {
  let t = ot();
  return {
    href: e != null && e.href ? t.useHref(e == null ? void 0 : e.href) : void 0,
    target: e == null ? void 0 : e.target,
    rel: e == null ? void 0 : e.rel,
    download: e == null ? void 0 : e.download,
    ping: e == null ? void 0 : e.ping,
    referrerPolicy: e == null ? void 0 : e.referrerPolicy
  };
}
let B = /* @__PURE__ */ new Map(), ce = /* @__PURE__ */ new Set();
function ye() {
  if (typeof window > "u")
    return;
  function e(o) {
    return "propertyName" in o;
  }
  let t = (o) => {
    if (!e(o) || !o.target)
      return;
    let a = B.get(o.target);
    a || (a = /* @__PURE__ */ new Set(), B.set(o.target, a), o.target.addEventListener("transitioncancel", r, {
      once: !0
    })), a.add(o.propertyName);
  }, r = (o) => {
    if (!e(o) || !o.target)
      return;
    let a = B.get(o.target);
    if (a && (a.delete(o.propertyName), a.size === 0 && (o.target.removeEventListener("transitioncancel", r), B.delete(o.target)), B.size === 0)) {
      for (let c of ce)
        c();
      ce.clear();
    }
  };
  document.body.addEventListener("transitionrun", t), document.body.addEventListener("transitionend", r);
}
typeof document < "u" && (document.readyState !== "loading" ? ye() : document.addEventListener("DOMContentLoaded", ye));
function ke(e) {
  requestAnimationFrame(() => {
    B.size === 0 ? e() : ce.add(e);
  });
}
function st() {
  let e = j(/* @__PURE__ */ new Map()), t = S((a, c, l, f) => {
    let p = f != null && f.once ? (...g) => {
      e.current.delete(l), l(...g);
    } : l;
    e.current.set(l, {
      type: c,
      eventTarget: a,
      fn: p,
      options: f
    }), a.addEventListener(c, l, f);
  }, []), r = S((a, c, l, f) => {
    var p;
    let g = ((p = e.current.get(l)) === null || p === void 0 ? void 0 : p.fn) || l;
    a.removeEventListener(c, g, f), e.current.delete(l);
  }, []), o = S(() => {
    e.current.forEach((a, c) => {
      r(a.eventTarget, a.type, c, a.options);
    });
  }, [
    r
  ]);
  return N(() => o, [
    o
  ]), {
    addGlobalListener: t,
    removeGlobalListener: r,
    removeAllGlobalListeners: o
  };
}
function ut(e, t) {
  xe(() => {
    if (e && e.ref && t)
      return e.ref.current = t.current, () => {
        e.ref && (e.ref.current = null);
      };
  });
}
function fe(e) {
  return e.mozInputSource === 0 && e.isTrusted ? !0 : Fe() && e.pointerType ? e.type === "click" && e.buttons === 1 : e.detail === 0 && !e.pointerType;
}
function lt(e) {
  return !Fe() && e.width === 0 && e.height === 0 || e.width === 1 && e.height === 1 && e.pressure === 0 && e.detail === 0 && e.pointerType === "mouse";
}
let G = "default", de = "", te = /* @__PURE__ */ new WeakMap();
function he(e) {
  if (be()) {
    if (G === "default") {
      const t = T(e);
      de = t.documentElement.style.webkitUserSelect, t.documentElement.style.webkitUserSelect = "none";
    }
    G = "disabled";
  } else
    (e instanceof HTMLElement || e instanceof SVGElement) && (te.set(e, e.style.userSelect), e.style.userSelect = "none");
}
function Q(e) {
  if (be()) {
    if (G !== "disabled")
      return;
    G = "restoring", setTimeout(() => {
      ke(() => {
        if (G === "restoring") {
          const t = T(e);
          t.documentElement.style.webkitUserSelect === "none" && (t.documentElement.style.webkitUserSelect = de || ""), de = "", G = "default";
        }
      });
    }, 300);
  } else if ((e instanceof HTMLElement || e instanceof SVGElement) && e && te.has(e)) {
    let t = te.get(e);
    e.style.userSelect === "none" && (e.style.userSelect = t), e.getAttribute("style") === "" && e.removeAttribute("style"), te.delete(e);
  }
}
const He = Ge.createContext({
  register: () => {
  }
});
He.displayName = "PressResponderContext";
function ct(e, t) {
  return t.get ? t.get.call(e) : t.value;
}
function Oe(e, t, r) {
  if (!t.has(e))
    throw new TypeError("attempted to " + r + " private field on non-instance");
  return t.get(e);
}
function ft(e, t) {
  var r = Oe(e, t, "get");
  return ct(e, r);
}
function dt(e, t) {
  if (t.has(e))
    throw new TypeError("Cannot initialize the same private elements twice on an object");
}
function pt(e, t, r) {
  dt(e, t), t.set(e, r);
}
function vt(e, t, r) {
  if (t.set)
    t.set.call(e, r);
  else {
    if (!t.writable)
      throw new TypeError("attempted to set read only private field");
    t.value = r;
  }
}
function Te(e, t, r) {
  var o = Oe(e, t, "set");
  return vt(e, o, r), r;
}
function gt(e) {
  let t = Le(He);
  if (t) {
    let { register: r, ...o } = t;
    e = Me(o, e), r();
  }
  return ut(t, e.ref), e;
}
var Z = /* @__PURE__ */ new WeakMap();
class ee {
  continuePropagation() {
    Te(this, Z, !1);
  }
  get shouldStopPropagation() {
    return ft(this, Z);
  }
  constructor(t, r, o) {
    pt(this, Z, {
      writable: !0,
      value: void 0
    }), Te(this, Z, !0), this.type = t, this.pointerType = r, this.target = o.currentTarget, this.shiftKey = o.shiftKey, this.metaKey = o.metaKey, this.ctrlKey = o.ctrlKey, this.altKey = o.altKey;
  }
}
const me = Symbol("linkClicked");
function It(e) {
  let {
    onPress: t,
    onPressChange: r,
    onPressStart: o,
    onPressEnd: a,
    onPressUp: c,
    isDisabled: l,
    isPressed: f,
    preventFocusOnPress: p,
    shouldCancelOnPointerExit: g,
    allowTextSelectionOnPress: y,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref: A,
    ...w
  } = gt(e), [v, x] = Y(!1), E = j({
    isPressed: !1,
    ignoreEmulatedMouseEvents: !1,
    ignoreClickAfterPress: !1,
    didFirePressStart: !1,
    isTriggeringEvent: !1,
    activePointerId: null,
    target: null,
    isOverTarget: !1,
    pointerType: null
  }), { addGlobalListener: k, removeAllGlobalListeners: _ } = st(), M = U((n, d) => {
    let P = E.current;
    if (l || P.didFirePressStart)
      return !1;
    let u = !0;
    if (P.isTriggeringEvent = !0, o) {
      let b = new ee("pressstart", d, n);
      o(b), u = b.shouldStopPropagation;
    }
    return r && r(!0), P.isTriggeringEvent = !1, P.didFirePressStart = !0, x(!0), u;
  }), h = U((n, d, P = !0) => {
    let u = E.current;
    if (!u.didFirePressStart)
      return !1;
    u.ignoreClickAfterPress = !0, u.didFirePressStart = !1, u.isTriggeringEvent = !0;
    let b = !0;
    if (a) {
      let i = new ee("pressend", d, n);
      a(i), b = i.shouldStopPropagation;
    }
    if (r && r(!1), x(!1), t && P && !l) {
      let i = new ee("press", d, n);
      t(i), b && (b = i.shouldStopPropagation);
    }
    return u.isTriggeringEvent = !1, b;
  }), D = U((n, d) => {
    let P = E.current;
    if (l)
      return !1;
    if (c) {
      P.isTriggeringEvent = !0;
      let u = new ee("pressup", d, n);
      return c(u), P.isTriggeringEvent = !1, u.shouldStopPropagation;
    }
    return !0;
  }), H = U((n) => {
    let d = E.current;
    d.isPressed && d.target && (d.isOverTarget && d.pointerType != null && h(K(d.target, n), d.pointerType, !1), d.isPressed = !1, d.isOverTarget = !1, d.activePointerId = null, d.pointerType = null, _(), y || Q(d.target));
  }), q = U((n) => {
    g && H(n);
  }), Be = Se(() => {
    let n = E.current, d = {
      onKeyDown(u) {
        if (se(u.nativeEvent, u.currentTarget) && u.currentTarget.contains(u.target)) {
          var b;
          Pe(u.target, u.key) && u.preventDefault();
          let i = !0;
          if (!n.isPressed && !u.repeat) {
            n.target = u.currentTarget, n.isPressed = !0, i = M(u, "keyboard");
            let s = u.currentTarget, $ = (I) => {
              se(I, s) && !I.repeat && s.contains(I.target) && n.target && D(K(n.target, I), "keyboard");
            };
            k(T(u.currentTarget), "keyup", Ve($, P), !0);
          }
          i && u.stopPropagation(), u.metaKey && V() && ((b = n.metaKeyEvents) === null || b === void 0 || b.set(u.key, u.nativeEvent));
        } else
          u.key === "Meta" && (n.metaKeyEvents = /* @__PURE__ */ new Map());
      },
      onClick(u) {
        if (!(u && !u.currentTarget.contains(u.target)) && u && u.button === 0 && !n.isTriggeringEvent && !R.isOpening) {
          let b = !0;
          if (l && u.preventDefault(), !n.ignoreClickAfterPress && !n.ignoreEmulatedMouseEvents && !n.isPressed && (n.pointerType === "virtual" || fe(u.nativeEvent))) {
            !l && !p && O(u.currentTarget);
            let i = M(u, "virtual"), s = D(u, "virtual"), $ = h(u, "virtual");
            b = i && s && $;
          }
          n.ignoreEmulatedMouseEvents = !1, n.ignoreClickAfterPress = !1, b && u.stopPropagation();
        }
      }
    }, P = (u) => {
      var b;
      if (n.isPressed && n.target && se(u, n.target)) {
        var i;
        Pe(u.target, u.key) && u.preventDefault();
        let $ = u.target;
        h(K(n.target, u), "keyboard", n.target.contains($)), _(), u.key !== "Enter" && $e(n.target) && n.target.contains($) && !u[me] && (u[me] = !0, R(n.target, u, !1)), n.isPressed = !1, (i = n.metaKeyEvents) === null || i === void 0 || i.delete(u.key);
      } else if (u.key === "Meta" && (!((b = n.metaKeyEvents) === null || b === void 0) && b.size)) {
        var s;
        let $ = n.metaKeyEvents;
        n.metaKeyEvents = void 0;
        for (let I of $.values())
          (s = n.target) === null || s === void 0 || s.dispatchEvent(new KeyboardEvent("keyup", I));
      }
    };
    if (typeof PointerEvent < "u") {
      d.onPointerDown = (s) => {
        if (s.button !== 0 || !s.currentTarget.contains(s.target))
          return;
        if (lt(s.nativeEvent)) {
          n.pointerType = "virtual";
          return;
        }
        ue(s.currentTarget) && s.preventDefault(), n.pointerType = s.pointerType;
        let $ = !0;
        n.isPressed || (n.isPressed = !0, n.isOverTarget = !0, n.activePointerId = s.pointerId, n.target = s.currentTarget, !l && !p && O(s.currentTarget), y || he(n.target), $ = M(s, n.pointerType), k(T(s.currentTarget), "pointermove", u, !1), k(T(s.currentTarget), "pointerup", b, !1), k(T(s.currentTarget), "pointercancel", i, !1)), $ && s.stopPropagation();
      }, d.onMouseDown = (s) => {
        s.currentTarget.contains(s.target) && s.button === 0 && (ue(s.currentTarget) && s.preventDefault(), s.stopPropagation());
      }, d.onPointerUp = (s) => {
        !s.currentTarget.contains(s.target) || n.pointerType === "virtual" || s.button === 0 && W(s, s.currentTarget) && D(s, n.pointerType || s.pointerType);
      };
      let u = (s) => {
        s.pointerId === n.activePointerId && (n.target && W(s, n.target) ? !n.isOverTarget && n.pointerType != null && (n.isOverTarget = !0, M(K(n.target, s), n.pointerType)) : n.target && n.isOverTarget && n.pointerType != null && (n.isOverTarget = !1, h(K(n.target, s), n.pointerType, !1), q(s)));
      }, b = (s) => {
        s.pointerId === n.activePointerId && n.isPressed && s.button === 0 && n.target && (W(s, n.target) && n.pointerType != null ? h(K(n.target, s), n.pointerType) : n.isOverTarget && n.pointerType != null && h(K(n.target, s), n.pointerType, !1), n.isPressed = !1, n.isOverTarget = !1, n.activePointerId = null, n.pointerType = null, _(), y || Q(n.target));
      }, i = (s) => {
        H(s);
      };
      d.onDragStart = (s) => {
        s.currentTarget.contains(s.target) && H(s);
      };
    } else {
      d.onMouseDown = (i) => {
        if (i.button !== 0 || !i.currentTarget.contains(i.target))
          return;
        if (ue(i.currentTarget) && i.preventDefault(), n.ignoreEmulatedMouseEvents) {
          i.stopPropagation();
          return;
        }
        n.isPressed = !0, n.isOverTarget = !0, n.target = i.currentTarget, n.pointerType = fe(i.nativeEvent) ? "virtual" : "mouse", !l && !p && O(i.currentTarget), M(i, n.pointerType) && i.stopPropagation(), k(T(i.currentTarget), "mouseup", u, !1);
      }, d.onMouseEnter = (i) => {
        if (!i.currentTarget.contains(i.target))
          return;
        let s = !0;
        n.isPressed && !n.ignoreEmulatedMouseEvents && n.pointerType != null && (n.isOverTarget = !0, s = M(i, n.pointerType)), s && i.stopPropagation();
      }, d.onMouseLeave = (i) => {
        if (!i.currentTarget.contains(i.target))
          return;
        let s = !0;
        n.isPressed && !n.ignoreEmulatedMouseEvents && n.pointerType != null && (n.isOverTarget = !1, s = h(i, n.pointerType, !1), q(i)), s && i.stopPropagation();
      }, d.onMouseUp = (i) => {
        i.currentTarget.contains(i.target) && !n.ignoreEmulatedMouseEvents && i.button === 0 && D(i, n.pointerType || "mouse");
      };
      let u = (i) => {
        if (i.button === 0) {
          if (n.isPressed = !1, _(), n.ignoreEmulatedMouseEvents) {
            n.ignoreEmulatedMouseEvents = !1;
            return;
          }
          n.target && W(i, n.target) && n.pointerType != null ? h(K(n.target, i), n.pointerType) : n.target && n.isOverTarget && n.pointerType != null && h(K(n.target, i), n.pointerType, !1), n.isOverTarget = !1;
        }
      };
      d.onTouchStart = (i) => {
        if (!i.currentTarget.contains(i.target))
          return;
        let s = bt(i.nativeEvent);
        if (!s)
          return;
        n.activePointerId = s.identifier, n.ignoreEmulatedMouseEvents = !0, n.isOverTarget = !0, n.isPressed = !0, n.target = i.currentTarget, n.pointerType = "touch", !l && !p && O(i.currentTarget), y || he(n.target), M(i, n.pointerType) && i.stopPropagation(), k(L(i.currentTarget), "scroll", b, !0);
      }, d.onTouchMove = (i) => {
        if (!i.currentTarget.contains(i.target))
          return;
        if (!n.isPressed) {
          i.stopPropagation();
          return;
        }
        let s = Ee(i.nativeEvent, n.activePointerId), $ = !0;
        s && W(s, i.currentTarget) ? !n.isOverTarget && n.pointerType != null && (n.isOverTarget = !0, $ = M(i, n.pointerType)) : n.isOverTarget && n.pointerType != null && (n.isOverTarget = !1, $ = h(i, n.pointerType, !1), q(i)), $ && i.stopPropagation();
      }, d.onTouchEnd = (i) => {
        if (!i.currentTarget.contains(i.target))
          return;
        if (!n.isPressed) {
          i.stopPropagation();
          return;
        }
        let s = Ee(i.nativeEvent, n.activePointerId), $ = !0;
        s && W(s, i.currentTarget) && n.pointerType != null ? (D(i, n.pointerType), $ = h(i, n.pointerType)) : n.isOverTarget && n.pointerType != null && ($ = h(i, n.pointerType, !1)), $ && i.stopPropagation(), n.isPressed = !1, n.activePointerId = null, n.isOverTarget = !1, n.ignoreEmulatedMouseEvents = !0, n.target && !y && Q(n.target), _();
      }, d.onTouchCancel = (i) => {
        i.currentTarget.contains(i.target) && (i.stopPropagation(), n.isPressed && H(i));
      };
      let b = (i) => {
        n.isPressed && i.target.contains(n.target) && H({
          currentTarget: n.target,
          shiftKey: !1,
          ctrlKey: !1,
          metaKey: !1,
          altKey: !1
        });
      };
      d.onDragStart = (i) => {
        i.currentTarget.contains(i.target) && H(i);
      };
    }
    return d;
  }, [
    k,
    l,
    p,
    _,
    y,
    H,
    q,
    h,
    M,
    D
  ]);
  return N(() => () => {
    var n;
    y || Q((n = E.current.target) !== null && n !== void 0 ? n : void 0);
  }, [
    y
  ]), {
    isPressed: f || v,
    pressProps: Me(w, Be)
  };
}
function $e(e) {
  return e.tagName === "A" && e.hasAttribute("href");
}
function se(e, t) {
  const { key: r, code: o } = e, a = t, c = a.getAttribute("role");
  return (r === "Enter" || r === " " || r === "Spacebar" || o === "Space") && !(a instanceof L(a).HTMLInputElement && !Ce(a, r) || a instanceof L(a).HTMLTextAreaElement || a.isContentEditable) && // Links should only trigger with Enter key
  !((c === "link" || !c && $e(a)) && r !== "Enter");
}
function bt(e) {
  const { targetTouches: t } = e;
  return t.length > 0 ? t[0] : null;
}
function Ee(e, t) {
  const r = e.changedTouches;
  for (let o = 0; o < r.length; o++) {
    const a = r[o];
    if (a.identifier === t)
      return a;
  }
  return null;
}
function K(e, t) {
  return {
    currentTarget: e,
    shiftKey: t.shiftKey,
    ctrlKey: t.ctrlKey,
    metaKey: t.metaKey,
    altKey: t.altKey
  };
}
function $t(e) {
  let t = 0, r = 0;
  return e.width !== void 0 ? t = e.width / 2 : e.radiusX !== void 0 && (t = e.radiusX), e.height !== void 0 ? r = e.height / 2 : e.radiusY !== void 0 && (r = e.radiusY), {
    top: e.clientY - r,
    right: e.clientX + t,
    bottom: e.clientY + r,
    left: e.clientX - t
  };
}
function yt(e, t) {
  return !(e.left > t.right || t.left > e.right || e.top > t.bottom || t.top > e.bottom);
}
function W(e, t) {
  let r = t.getBoundingClientRect(), o = $t(e);
  return yt(r, o);
}
function ue(e) {
  return !(e instanceof HTMLElement) || !e.hasAttribute("draggable");
}
function Pe(e, t) {
  return e instanceof HTMLInputElement ? !Ce(e, t) : e instanceof HTMLButtonElement ? e.type !== "submit" && e.type !== "reset" : !$e(e);
}
const ht = /* @__PURE__ */ new Set([
  "checkbox",
  "radio",
  "range",
  "color",
  "file",
  "image",
  "button",
  "submit",
  "reset"
]);
function Ce(e, t) {
  return e.type === "checkbox" || e.type === "radio" ? t === " " : ht.has(e.type);
}
class Tt {
  isDefaultPrevented() {
    return this.nativeEvent.defaultPrevented;
  }
  preventDefault() {
    this.defaultPrevented = !0, this.nativeEvent.preventDefault();
  }
  stopPropagation() {
    this.nativeEvent.stopPropagation(), this.isPropagationStopped = () => !0;
  }
  isPropagationStopped() {
    return !1;
  }
  persist() {
  }
  constructor(t, r) {
    this.nativeEvent = r, this.target = r.target, this.currentTarget = r.currentTarget, this.relatedTarget = r.relatedTarget, this.bubbles = r.bubbles, this.cancelable = r.cancelable, this.defaultPrevented = r.defaultPrevented, this.eventPhase = r.eventPhase, this.isTrusted = r.isTrusted, this.timeStamp = r.timeStamp, this.type = t;
  }
}
function Ae(e) {
  let t = j({
    isFocused: !1,
    observer: null
  });
  xe(() => {
    const o = t.current;
    return () => {
      o.observer && (o.observer.disconnect(), o.observer = null);
    };
  }, []);
  let r = U((o) => {
    e == null || e(o);
  });
  return S((o) => {
    if (o.target instanceof HTMLButtonElement || o.target instanceof HTMLInputElement || o.target instanceof HTMLTextAreaElement || o.target instanceof HTMLSelectElement) {
      t.current.isFocused = !0;
      let a = o.target, c = (l) => {
        t.current.isFocused = !1, a.disabled && r(new Tt("blur", l)), t.current.observer && (t.current.observer.disconnect(), t.current.observer = null);
      };
      a.addEventListener("focusout", c, {
        once: !0
      }), t.current.observer = new MutationObserver(() => {
        if (t.current.isFocused && a.disabled) {
          var l;
          (l = t.current.observer) === null || l === void 0 || l.disconnect();
          let f = a === document.activeElement ? null : document.activeElement;
          a.dispatchEvent(new FocusEvent("blur", {
            relatedTarget: f
          })), a.dispatchEvent(new FocusEvent("focusout", {
            bubbles: !0,
            relatedTarget: f
          }));
        }
      }), t.current.observer.observe(a, {
        attributes: !0,
        attributeFilter: [
          "disabled"
        ]
      });
    }
  }, [
    r
  ]);
}
function mt(e) {
  let { isDisabled: t, onFocus: r, onBlur: o, onFocusChange: a } = e;
  const c = S((p) => {
    if (p.target === p.currentTarget)
      return o && o(p), a && a(!1), !0;
  }, [
    o,
    a
  ]), l = Ae(c), f = S((p) => {
    const g = T(p.target);
    p.target === p.currentTarget && g.activeElement === p.target && (r && r(p), a && a(!0), l(p));
  }, [
    a,
    r,
    l
  ]);
  return {
    focusProps: {
      onFocus: !t && (r || a || o) ? f : void 0,
      onBlur: !t && (o || a) ? c : void 0
    }
  };
}
let F = null, X = /* @__PURE__ */ new Set(), z = /* @__PURE__ */ new Map(), C = !1, pe = !1;
const Et = {
  Tab: !0,
  Escape: !0
};
function ae(e, t) {
  for (let r of X)
    r(e, t);
}
function Pt(e) {
  return !(e.metaKey || !V() && e.altKey || e.ctrlKey || e.key === "Control" || e.key === "Shift" || e.key === "Meta");
}
function re(e) {
  C = !0, Pt(e) && (F = "keyboard", ae("keyboard", e));
}
function m(e) {
  F = "pointer", (e.type === "mousedown" || e.type === "pointerdown") && (C = !0, ae("pointer", e));
}
function _e(e) {
  fe(e) && (C = !0, F = "virtual");
}
function De(e) {
  e.target === window || e.target === document || (!C && !pe && (F = "virtual", ae("virtual", e)), C = !1, pe = !1);
}
function Ie() {
  C = !1, pe = !0;
}
function ne(e) {
  if (typeof window > "u" || z.get(L(e)))
    return;
  const t = L(e), r = T(e);
  let o = t.HTMLElement.prototype.focus;
  t.HTMLElement.prototype.focus = function() {
    C = !0, o.apply(this, arguments);
  }, r.addEventListener("keydown", re, !0), r.addEventListener("keyup", re, !0), r.addEventListener("click", _e, !0), t.addEventListener("focus", De, !0), t.addEventListener("blur", Ie, !1), typeof PointerEvent < "u" ? (r.addEventListener("pointerdown", m, !0), r.addEventListener("pointermove", m, !0), r.addEventListener("pointerup", m, !0)) : (r.addEventListener("mousedown", m, !0), r.addEventListener("mousemove", m, !0), r.addEventListener("mouseup", m, !0)), t.addEventListener("beforeunload", () => {
    We(e);
  }, {
    once: !0
  }), z.set(t, {
    focus: o
  });
}
const We = (e, t) => {
  const r = L(e), o = T(e);
  t && o.removeEventListener("DOMContentLoaded", t), z.has(r) && (r.HTMLElement.prototype.focus = z.get(r).focus, o.removeEventListener("keydown", re, !0), o.removeEventListener("keyup", re, !0), o.removeEventListener("click", _e, !0), r.removeEventListener("focus", De, !0), r.removeEventListener("blur", Ie, !1), typeof PointerEvent < "u" ? (o.removeEventListener("pointerdown", m, !0), o.removeEventListener("pointermove", m, !0), o.removeEventListener("pointerup", m, !0)) : (o.removeEventListener("mousedown", m, !0), o.removeEventListener("mousemove", m, !0), o.removeEventListener("mouseup", m, !0)), z.delete(r));
};
function wt(e) {
  const t = T(e);
  let r;
  return t.readyState !== "loading" ? ne(e) : (r = () => {
    ne(e);
  }, t.addEventListener("DOMContentLoaded", r)), () => We(e, r);
}
typeof document < "u" && wt();
function Ue() {
  return F !== "pointer";
}
function Lt() {
  return F;
}
function Wt(e) {
  F = e, ae(e, null);
}
function Ut() {
  ne();
  let [e, t] = Y(F);
  return N(() => {
    let r = () => {
      t(F);
    };
    return X.add(r), () => {
      X.delete(r);
    };
  }, []), Re() ? null : e;
}
const St = /* @__PURE__ */ new Set([
  "checkbox",
  "radio",
  "range",
  "color",
  "file",
  "image",
  "button",
  "submit",
  "reset"
]);
function xt(e, t, r) {
  var o;
  const a = typeof window < "u" ? L(r == null ? void 0 : r.target).HTMLInputElement : HTMLInputElement, c = typeof window < "u" ? L(r == null ? void 0 : r.target).HTMLTextAreaElement : HTMLTextAreaElement, l = typeof window < "u" ? L(r == null ? void 0 : r.target).HTMLElement : HTMLElement, f = typeof window < "u" ? L(r == null ? void 0 : r.target).KeyboardEvent : KeyboardEvent;
  return e = e || (r == null ? void 0 : r.target) instanceof a && !St.has(r == null || (o = r.target) === null || o === void 0 ? void 0 : o.type) || (r == null ? void 0 : r.target) instanceof c || (r == null ? void 0 : r.target) instanceof l && (r == null ? void 0 : r.target.isContentEditable), !(e && t === "keyboard" && r instanceof f && !Et[r.key]);
}
function Mt(e, t, r) {
  ne(), N(() => {
    let o = (a, c) => {
      xt(!!(r != null && r.isTextInput), a, c) && e(Ue());
    };
    return X.add(o), () => {
      X.delete(o);
    };
  }, t);
}
function Kt(e) {
  let { isDisabled: t, onBlurWithin: r, onFocusWithin: o, onFocusWithinChange: a } = e, c = j({
    isFocusWithin: !1
  }), l = S((g) => {
    c.current.isFocusWithin && !g.currentTarget.contains(g.relatedTarget) && (c.current.isFocusWithin = !1, r && r(g), a && a(!1));
  }, [
    r,
    a,
    c
  ]), f = Ae(l), p = S((g) => {
    !c.current.isFocusWithin && document.activeElement === g.target && (o && o(g), a && a(!0), c.current.isFocusWithin = !0, f(g));
  }, [
    o,
    a,
    f
  ]);
  return t ? {
    focusWithinProps: {
      // These should not have been null, that would conflict in mergeProps
      onFocus: void 0,
      onBlur: void 0
    }
  } : {
    focusWithinProps: {
      onFocus: p,
      onBlur: l
    }
  };
}
let oe = !1, le = 0;
function ve() {
  oe = !0, setTimeout(() => {
    oe = !1;
  }, 50);
}
function we(e) {
  e.pointerType === "touch" && ve();
}
function Ft() {
  if (!(typeof document > "u"))
    return typeof PointerEvent < "u" ? document.addEventListener("pointerup", we) : document.addEventListener("touchend", ve), le++, () => {
      le--, !(le > 0) && (typeof PointerEvent < "u" ? document.removeEventListener("pointerup", we) : document.removeEventListener("touchend", ve));
    };
}
function Bt(e) {
  let { onHoverStart: t, onHoverChange: r, onHoverEnd: o, isDisabled: a } = e, [c, l] = Y(!1), f = j({
    isHovered: !1,
    ignoreEmulatedMouseEvents: !1,
    pointerType: "",
    target: null
  }).current;
  N(Ft, []);
  let { hoverProps: p, triggerHoverEnd: g } = Se(() => {
    let y = (v, x) => {
      if (f.pointerType = x, a || x === "touch" || f.isHovered || !v.currentTarget.contains(v.target))
        return;
      f.isHovered = !0;
      let E = v.currentTarget;
      f.target = E, t && t({
        type: "hoverstart",
        target: E,
        pointerType: x
      }), r && r(!0), l(!0);
    }, A = (v, x) => {
      if (f.pointerType = "", f.target = null, x === "touch" || !f.isHovered)
        return;
      f.isHovered = !1;
      let E = v.currentTarget;
      o && o({
        type: "hoverend",
        target: E,
        pointerType: x
      }), r && r(!1), l(!1);
    }, w = {};
    return typeof PointerEvent < "u" ? (w.onPointerEnter = (v) => {
      oe && v.pointerType === "mouse" || y(v, v.pointerType);
    }, w.onPointerLeave = (v) => {
      !a && v.currentTarget.contains(v.target) && A(v, v.pointerType);
    }) : (w.onTouchStart = () => {
      f.ignoreEmulatedMouseEvents = !0;
    }, w.onMouseEnter = (v) => {
      !f.ignoreEmulatedMouseEvents && !oe && y(v, "mouse"), f.ignoreEmulatedMouseEvents = !1;
    }, w.onMouseLeave = (v) => {
      !a && v.currentTarget.contains(v.target) && A(v, "mouse");
    }), {
      hoverProps: w,
      triggerHoverEnd: A
    };
  }, [
    t,
    r,
    o,
    a,
    f
  ]);
  return N(() => {
    a && g({
      currentTarget: f.target
    }, f.pointerType);
  }, [
    a
  ]), {
    hoverProps: p,
    isHovered: c
  };
}
function Gt(e) {
  const t = T(e);
  if (Lt() === "virtual") {
    let r = t.activeElement;
    ke(() => {
      t.activeElement === r && e.isConnected && O(e);
    });
  } else
    O(e);
}
function Nt(e = {}) {
  let { autoFocus: t = !1, isTextInput: r, within: o } = e, a = j({
    isFocused: !1,
    isFocusVisible: t || Ue()
  }), [c, l] = Y(!1), [f, p] = Y(() => a.current.isFocused && a.current.isFocusVisible), g = S(() => p(a.current.isFocused && a.current.isFocusVisible), []), y = S((v) => {
    a.current.isFocused = v, l(v), g();
  }, [
    g
  ]);
  Mt((v) => {
    a.current.isFocusVisible = v, g();
  }, [], {
    isTextInput: r
  });
  let { focusProps: A } = mt({
    isDisabled: o,
    onFocusChange: y
  }), { focusWithinProps: w } = Kt({
    isDisabled: !o,
    onFocusWithinChange: y
  });
  return {
    isFocused: c,
    isFocusVisible: f,
    focusProps: o ? w : A
  };
}
export {
  mt as $,
  Gt as a,
  ut as b,
  It as c,
  Ot as d,
  ot as e,
  Dt as f,
  At as g,
  Bt as h,
  Nt as i,
  st as j,
  L as k,
  T as l,
  Ct as m,
  V as n,
  Lt as o,
  O as p,
  R as q,
  Kt as r,
  et as s,
  Ue as t,
  Ut as u,
  _t as v,
  He as w,
  Wt as x,
  be as y
};
