import { Text } from '@carvertical/ui';
import { Trans, useTranslation } from 'next-i18next';
import { creditValidityMonths } from 'config';
import { NS_CHECKOUT } from 'constants/i18n';
import { useRouteData } from 'context/RouteDataProvider';
import { isExistingMarket } from 'utils/market';

const CreditsValidityNotice = () => {
  const { t } = useTranslation(NS_CHECKOUT);
  const { market } = useRouteData();

  const lithuanianMarket = isExistingMarket({
    markets: ['lithuania'],
    market,
  });

  if (!lithuanianMarket) {
    return null;
  }

  return (
    <Text variant="xs" color="darkSecondary" align="center">
      <Trans
        t={t}
        i18nKey="orderDetails.validityNotice"
        values={{ months: creditValidityMonths }}
        components={{ 2: <strong /> }}
      >
        {'Credits are valid for <2>{{months}}</2> months from the date of purchase.'}
      </Trans>
    </Text>
  );
};

export { CreditsValidityNotice };
