import { useEffect, type PropsWithChildren } from 'react';
import { LayoutGroup, motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import { Modal } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { useRouteData } from 'context/RouteDataProvider';
import {
  useBisquitsStore,
  type BisquitActions,
  type SavedCookieCategories,
} from 'store/useBisquitsStore';
import * as cookieConsent from 'services/cookieConsent';
import { COOKIE_CATEGORIES, WHITELIST_PAGES } from 'constants/cookies';
import { BisquitsQuickSettings } from './BisquitsQuickSettings';
import {
  BisquitsExtendedSettings,
  type BisquitsExtendedSettingsProps,
} from './BisquitsExtendedSettings';

const allCookieCategories: SavedCookieCategories = COOKIE_CATEGORIES.reduce(
  (values, category) => ({
    ...values,
    [category.id]: true,
  }),
  {
    mandatoryCookies: false,
    statsCookies: false,
    marketingCookies: false,
  },
);

const Animation = (props: PropsWithChildren) => (
  <motion.div
    layout
    variants={{
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    }}
    initial="hidden"
    animate="visible"
    exit="hidden"
    {...props}
  />
);

const Bisquits = () => {
  const { t } = useTranslation();
  const { page } = useRouteData();
  const { modalShown, saveCookies, setModalShown, setView, view } = useBisquitsStore();

  useEffect(() => {
    const alreadyConsented = !isEmpty(cookieConsent.get());
    const shown = !alreadyConsented && !WHITELIST_PAGES.includes(page?.id || '');

    setModalShown(shown);
  }, [page, setModalShown]);

  if (!modalShown) {
    return null;
  }

  const quickViewProps: BisquitActions = {
    onAcceptAll: () => {
      saveCookies(allCookieCategories);
    },
    onOpenSettings: () => {
      setView('extended');
    },
    onAcceptNecessary: () => {
      const cookieCategories: SavedCookieCategories =
        COOKIE_CATEGORIES.reduce<SavedCookieCategories>(
          (values, category) => ({
            ...values,
            [category.id]: !!category.required,
          }),
          {
            mandatoryCookies: false,
            statsCookies: false,
            marketingCookies: false,
          },
        );

      saveCookies(cookieCategories);
    },
  };

  const extendedViewProps: BisquitsExtendedSettingsProps = {
    cookieCategories: COOKIE_CATEGORIES,
    onAccept: (cookieCategories) => saveCookies(cookieCategories || allCookieCategories),
  };

  const quickView = view === 'quick';
  const extendedView = view === 'extended';

  return (
    <Modal
      open={modalShown}
      onClose={() => setModalShown(false)}
      closeButtonShown={false}
      title={t('cookieNotice.cookieSettingsLabel')}
      size={quickView ? 's' : 'm'}
      boxClassName="m-3 max-h-96 md:max-h-[650px] rounded-l"
    >
      <LayoutGroup>
        {quickView && (
          <Animation>
            <BisquitsQuickSettings {...quickViewProps} />
          </Animation>
        )}
        {extendedView && (
          <Animation>
            <BisquitsExtendedSettings {...extendedViewProps} />
          </Animation>
        )}
      </LayoutGroup>
    </Modal>
  );
};

export { Bisquits };
