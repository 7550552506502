import { useEffect, useState } from 'react';
import { maxBy } from 'lodash';
import { useRouteData } from 'context/RouteDataProvider';
import { usePricing } from 'context/PricingProvider';
import { useFormattedPackages } from 'hooks/useFormattedPackages';
import { isExistingMarket } from 'utils/market';
import type { MarketId } from 'types/market';
import type { OptionalPackageIdRecord, Pricing } from 'types/pricing';

const PRECHECK_BUSINESS_MARKETS: MarketId[] = ['italy', 'lithuania', 'romania'];

const usePricingBlocks = () => {
  const [businessPackagesShown, setBusinessPackagesShown] = useState(false);
  const { market, page } = useRouteData();

  const businessPricing: OptionalPackageIdRecord<Pricing> = {
    bizsm: usePricing('bizsm'),
    bizmd: usePricing('bizmd'),
    bizlg: usePricing('bizlg'),
  };

  const {
    fetching: pricingLoading,
    formattedPackages,
    preselectedPackage,
    pricing: basePricing,
  } = useFormattedPackages({
    market,
  });

  const [selected, setSelected] = useState(preselectedPackage?.id);

  useEffect(() => {
    setSelected(businessPackagesShown ? 'bizsm' : preselectedPackage?.id);
  }, [preselectedPackage.id, businessPackagesShown]);

  const businessPackages = [...Object.values(businessPricing)];

  const shownPackages = businessPackagesShown ? businessPackages : formattedPackages;

  const pricing = businessPackagesShown ? businessPricing : basePricing;

  const maxBusinessDiscount = maxBy(businessPackages, 'discount')?.discount;

  const businessPriceToggleShown =
    page?.id === 'precheck' &&
    isExistingMarket({
      market,
      markets: PRECHECK_BUSINESS_MARKETS,
    });

  return {
    businessPackagesShown,
    setBusinessPackagesShown,
    shownPackages,
    maxBusinessDiscount,
    pricing,
    selected,
    setSelected,
    pricingLoading,
    businessPriceToggleShown,
  };
};

export { usePricingBlocks };
