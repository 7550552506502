import { j as e } from "./chunks/jsx-runtime.82a9ddf6.js";
import { $ as i } from "./chunks/Tabs.5ab9d535.js";
import { T as t } from "./chunks/TabsContext.9430d3b8.js";
import "react";
import "./chunks/Collection.e8e46ab5.js";
import "react-dom";
import "./chunks/utils.371a0d8b.js";
import "./chunks/clsx.0d472180.js";
import "./chunks/useFocusRing.91eeed6d.js";
import "./chunks/useSelectableItem.ca3ec579.js";
import "./chunks/useHasTabbableChild.8b7adf4c.js";
const o = ({ disabled: r, ...a }) => /* @__PURE__ */ e.jsx(t, { children: /* @__PURE__ */ e.jsx(i, { keyboardActivation: "manual", isDisabled: r, ...a }) });
try {
  o.displayName = "Tabs", o.__docgenInfo = { description: "", displayName: "Tabs", props: { disabled: { defaultValue: null, description: "", name: "disabled", required: !1, type: { name: "boolean" } } } };
} catch {
}
export {
  o as Tabs
};
