import { forwardRef } from 'react';
import dynamic from 'next/dynamic';

const options = {
  ssr: false,
  loading: () => 'Loading…',
};
const labelessOptions = {
  ...options,
  loading: () => null, // This is intentional, we don’t want to flash “Loading…” when, for example, Apple Pay method is being loaded
};

const LazyApplePayMethod = dynamic(() => import('./ApplePayMethod'), {
  ...labelessOptions,
});
const ApplePayMethod = forwardRef((props, ref) => (
  <LazyApplePayMethod methodRef={ref} {...props} />
));

const LazyGooglePayMethod = dynamic(() => import('./GooglePayMethod'), {
  ...labelessOptions,
});
const GooglePayMethod = forwardRef((props, ref) => (
  <LazyGooglePayMethod methodRef={ref} {...props} />
));

const LazyPayPalMethod = dynamic(() => import('./PayPalMethod'), {
  ...labelessOptions,
});
const PayPalMethod = forwardRef((props, ref) => <LazyPayPalMethod methodRef={ref} {...props} />);

const LazyCardMethod = dynamic(() => import('./CardMethod'), { ...options });
const CardMethod = forwardRef((props, ref) => <LazyCardMethod methodRef={ref} {...props} />);

const LazyPayseraMethod = dynamic(() => import('./PayseraMethod'), {
  ...options,
});
const PayseraMethod = forwardRef((props, ref) => <LazyPayseraMethod methodRef={ref} {...props} />);

const LazyNeopayMethod = dynamic(() => import('./NeopayMethod'), { ...options });
const NeopayMethod = forwardRef((props, ref) => <LazyNeopayMethod methodRef={ref} {...props} />);

const LazyDotpayMethod = dynamic(() => import('./DotpayMethod'), {
  ...options,
});
const DotpayMethod = forwardRef((props, ref) => <LazyDotpayMethod methodRef={ref} {...props} />);

const LazyTpayMethod = dynamic(() => import('./TpayMethod'), { ...options });
const TpayMethod = forwardRef((props, ref) => <LazyTpayMethod methodRef={ref} {...props} />);

const LazySofortMethod = dynamic(() => import('./SofortMethod'), {
  ...options,
});
const SofortMethod = forwardRef((props, ref) => <LazySofortMethod methodRef={ref} {...props} />);

// TODO: [LAUNCH-2332] - enable crypto payments once alternative to Coingate is ready
// const LazyCryptoMethod = dynamic(() => import('./CryptoMethod'), {
//   ...options,
// });
// const CryptoMethod = forwardRef((props, ref) => <LazyCryptoMethod methodRef={ref} {...props} />);

const LazyWireMethod = dynamic(() => import('./WireMethod'), { ...options });
const WireMethod = forwardRef((props, ref) => <LazyWireMethod methodRef={ref} {...props} />);

const LazyGiftMethod = dynamic(() => import('./GiftMethod'), { ...options });
const GiftMethod = forwardRef((props, ref) => <LazyGiftMethod methodRef={ref} {...props} />);

const LazyMBWayMethod = dynamic(() => import('./MBWayMethod'), { ...options });
const MBWayMethod = forwardRef((props, ref) => <LazyMBWayMethod methodRef={ref} {...props} />);

const LazyBlikMethod = dynamic(() => import('./BlikMethod'), { ...options });
const BlikMethod = forwardRef((props, ref) => <LazyBlikMethod methodRef={ref} {...props} />);

export {
  ApplePayMethod,
  GooglePayMethod,
  PayPalMethod,
  CardMethod,
  PayseraMethod,
  NeopayMethod,
  DotpayMethod,
  TpayMethod,
  SofortMethod,
  // CryptoMethod,
  WireMethod,
  GiftMethod,
  MBWayMethod,
  BlikMethod,
};
