import { j as a } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as u } from "react";
import { c } from "./chunks/cn.2ea4f521.js";
const s = u(
  ({ as: e, className: l, href: n, unstyled: p = !1, disabled: r, ...i }, t) => {
    const o = {
      className: c(
        !p && "leading-inherit mx-0 my-0 cursor-pointer appearance-none rounded-none border-0 bg-transparent px-0 py-0 text-left text-grey-900 no-underline shadow-none hover:no-underline disabled:cursor-not-allowed",
        l
      ),
      ...i
    };
    return e ? /* @__PURE__ */ a.jsx(e, { href: n, ref: t, disabled: r, ...o }) : n && !r ? /* @__PURE__ */ a.jsx("a", { href: n, ref: t, ...o }) : /* @__PURE__ */ a.jsx(
      "button",
      {
        type: "button",
        disabled: r,
        ref: t,
        ...o
      }
    );
  }
);
try {
  s.displayName = "AnchorOrButton", s.__docgenInfo = { description: "", displayName: "AnchorOrButton", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: { value: !1 }, description: "", name: "unstyled", required: !1, type: { name: "boolean" } } } };
} catch {
}
export {
  s as AnchorOrButton
};
