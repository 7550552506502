import { compact } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useRouteData } from 'context/RouteDataProvider';
import { BLOG_ROOT_PATH } from 'modules/content/constants';
import { createSubset } from 'services/site';
import { removeBasePath } from 'utils/router';
import { AppLinks } from '../AppLinks';
import type { MenuGroup, MenuItem } from '../types';
import { useHeader } from '../HeaderProvider';

type HeaderMenuItem = {
  item?: MenuItem;
  group?: MenuGroup;
};

const useHeaderMenu = () => {
  const { t } = useTranslation();
  const { activeMenuGroupId, setActiveMenuGroupId } = useHeader();
  const { asPath } = useRouter();
  const { pages } = useRouteData();

  const baselessPathname = removeBasePath(asPath);

  const extractPath = (path?: string) => {
    if (!path) {
      return '';
    }
    const [cleanPath] = path.split('?');

    return cleanPath;
  };

  const filterItems = (items: MenuItem[]) =>
    (createSubset(compact(items)) as MenuItem[]).filter((item) => item.included !== false);

  const flagActiveItem = (item: MenuItem) => ({
    ...item,
    active:
      (item.hasSubItems && baselessPathname.includes(item.path)) ||
      baselessPathname === extractPath(item.path),
  });

  const flagActiveItems = (items: MenuItem[]) => items.map(flagActiveItem);

  const rawGroups: HeaderMenuItem[] = [
    {
      group: {
        id: 'reports',
        title: t('menu.reportsTitle'),
        items: [
          pages.sampleReport,
          pages.pricing,
          {
            path: '/features',
            title: t('menu.featuresTitle'),
            locale: ['en-GB', 'fr', 'it', 'pl', 'ro'],
          },
          {
            path: '/advantages',
            title: t('menu.advantagesTitle'),
          },
          {
            ...pages.business,
            path: `${pages.business.path}#pricing`,
            title: t('menu.businessPricingTitle'),
          },
        ],
        Footer: AppLinks,
      },
    },
    {
      group: {
        id: 'forBusiness',
        title: t('menu.businessTitle'),
        items: [
          { ...pages.business, title: t('menu.dealershipsTitle') },
          { path: '/business/insurance', title: t('menu.businessInsuranceTitle') },
          { path: '/business/leasing', title: t('menu.businessLeasingTitle') },
          { path: '/business/api', href: '/en/business/api', title: t('menu.businessApiTitle') },
          {
            ...pages['affiliate-program'],
            title: t('menu.affiliatesTitle'),
          },
          {
            ...pages.business,
            path: `${pages.business.path}#pricing`,
            title: t('menu.businessPricingTitle'),
          },
        ],
      },
    },
    {
      group: {
        id: 'company',
        title: t('menu.companyTitle'),
        items: [
          pages.about,
          pages.contacts,
          pages.press,
          {
            id: 'careers',
            locale: ['en', 'lt'],
            path: '/career',
            title: t('menu.careerTitle'),
          },
        ],
      },
    },
    {
      item: {
        id: 'blog',
        title: t('blogSection.title'),
        path: `/${BLOG_ROOT_PATH}`,
        localeExcept: ['el-GR', 'pt'],
      },
    },
    {
      item: pages.help,
    },
  ];

  const menuGroups = rawGroups.map((headerItem) => {
    const item = headerItem.item ? flagActiveItem(headerItem.item) : undefined;

    const group = headerItem.group
      ? {
          ...headerItem.group,
          items: flagActiveItems(filterItems(headerItem.group.items)),
        }
      : undefined;

    return { item, group };
  });

  const activeMenuGroup = menuGroups
    .map(({ group }) => group)
    .filter(Boolean)
    .find((group) => group?.id === activeMenuGroupId);

  return {
    menuGroups,
    setActiveMenuGroupId,
    activeMenuGroup,
    flagActiveItems,
    filterItems,
  };
};

export { useHeaderMenu };
