import { Button } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { useRouteData } from 'context/RouteDataProvider';
import { NO_VIN_PRECHECK_URL } from 'constants/links';
import { beautifyPath, getRoute } from 'utils/router';
import type { SurroundingBackground } from './types';

type ContinueWithoutVinCtaProps = {
  disabled?: boolean;
  surroundingBackground?: SurroundingBackground;
  ctaLabel?: string;
};

const ContinueWithoutVinCta = ({
  disabled,
  surroundingBackground = 'mediumDark',
  ctaLabel,
}: ContinueWithoutVinCtaProps) => {
  const { t } = useTranslation();
  const { basePath } = useRouteData();

  const [, href] = getRoute(NO_VIN_PRECHECK_URL, undefined, basePath);

  return (
    <Button
      href={!disabled ? beautifyPath(href) : undefined}
      variant={
        ['light', 'mediumDark'].includes(surroundingBackground) ? 'outlined' : 'outlinedWhite'
      }
      disabled={disabled}
      size="s"
      fullWidth
      trailingIcon="arrow-right"
      className="text-s+ leading-3"
    >
      {ctaLabel || t('general.continueWithoutVin')}
    </Button>
  );
};

export { ContinueWithoutVinCta };
