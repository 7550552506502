import { j as a } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as S, useState as T } from "react";
import { c } from "./chunks/clsx.0d472180.js";
import { e } from "./chunks/styles.077839a8.js";
import { m as d } from "./chunks/motion.bdfaf94a.js";
import { Text as j } from "./text.mjs";
import "./chunks/cn.2ea4f521.js";
import "./chunks/TypographyElement.0309f27c.js";
const k = {
  type: "spring",
  bounce: 0.2,
  duration: 0.6
}, m = S(
  ({
    size: l = "m",
    variant: u = "blue",
    labelHidden: p,
    label: f,
    disabled: i,
    labelPlacement: g = "right",
    checked: r,
    onChange: t,
    defaultChecked: h = !1,
    ...y
  }, b) => {
    const [x, N] = T(h), n = typeof r < "u", s = n ? r : x, _ = (o) => {
      t == null || t(o), n || N(o.target.checked);
    };
    return /* @__PURE__ */ a.jsxs(
      "label",
      {
        className: c(
          e.root,
          e[u],
          e[l],
          e[g],
          s && e.checked,
          i && e.disabled
        ),
        children: [
          /* @__PURE__ */ a.jsx(j, { variant: "s", as: "span", className: c(e.label, p && "visuallyHidden"), children: f }),
          /* @__PURE__ */ a.jsx(
            "input",
            {
              ref: b,
              type: "checkbox",
              ...y,
              checked: s,
              disabled: i,
              className: e.input,
              onChange: _
            }
          ),
          /* @__PURE__ */ a.jsxs(d.div, { className: e.selectionBox, layout: !0, transition: { duration: 0 }, children: [
            /* @__PURE__ */ a.jsx(d.div, { className: e.knob, layout: !0, transition: k }),
            /* @__PURE__ */ a.jsx("span", { className: e.selectionBackground })
          ] })
        ]
      }
    );
  }
);
try {
  m.displayName = "ToggleSwitch", m.__docgenInfo = { description: "", displayName: "ToggleSwitch", props: { label: { defaultValue: null, description: "", name: "label", required: !0, type: { name: "ReactNode" } }, variant: { defaultValue: { value: "blue" }, description: "", name: "variant", required: !1, type: { name: "ToggleSwitchVariant" } }, labelHidden: { defaultValue: null, description: "", name: "labelHidden", required: !1, type: { name: "boolean" } }, labelPlacement: { defaultValue: { value: "right" }, description: "", name: "labelPlacement", required: !1, type: { name: "ToggleSwitchLabelPlacement" } }, size: { defaultValue: { value: "m" }, description: "", name: "size", required: !1, type: { name: "ToggleSwitchSize" } } } };
} catch {
}
export {
  m as ToggleSwitch
};
