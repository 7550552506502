import { cn } from '@carvertical/utils/styling';

type CardProps = {
  children: React.ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Card = ({ children, className, ...props }: CardProps) => (
  <section {...props} className={cn('bg-white p-3 md:rounded-m', className)}>
    {children}
  </section>
);

export { Card };
export type { CardProps };
