import { TextField, TextFieldIconButton } from '@carvertical/ui';
import { filter, toLower } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useMemo, useState } from 'react';
import type { MarketLanguage } from 'modules/shared';
import { MarketSelectionList } from './MarketSelectionList';
import { useMarketSwitcher } from './hooks/useMarketSwitcher';

const matchesSearchQuery = (value: string | MarketLanguage, searchQuery: string) => {
  const marketValue = typeof value === 'string' ? value : value.slug;
  return toLower(marketValue).includes(toLower(searchQuery));
};

const MarketSelection = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const { switcherMarkets } = useMarketSwitcher();
  const filteredMarkets = useMemo(
    () =>
      filter(switcherMarkets, ({ countryCode, countryCodeLong, languages, name }) =>
        [name, countryCode, countryCodeLong, ...languages].some((value) =>
          matchesSearchQuery(value, searchQuery),
        ),
      ),
    [searchQuery, switcherMarkets],
  );

  return (
    <>
      <div className="mb-4 flex justify-center">
        <TextField
          fullWidth
          labelHidden
          value={searchQuery}
          className="max-w-[592px]"
          label={t('marketSwitcher.countrySearchPlaceholder')}
          placeholder={t('general.typeToSearchLabel')}
          leadingAddon={
            !searchQuery && (
              <TextFieldIconButton
                icon="magnifying-glass"
                label={t('general.typeToSearchLabel')}
                className="pointer-events-none bg-white text-black shadow-none"
                disabled
              />
            )
          }
          onChange={({ target: { value } }) => setSearchQuery(value)}
        />
      </div>

      <MarketSelectionList markets={filteredMarkets} />
    </>
  );
};

export { MarketSelection };
