import * as u from "react";
import { useRef as m, useState as U, useCallback as v, useId as I, useInsertionEffect as K, useMemo as z, useEffect as T, useContext as B, cloneElement as D, Children as N, isValidElement as S } from "react";
import { u as L, f as V, a as _, P as G, L as H, b as W } from "./motion.bdfaf94a.js";
function F() {
  const t = m(!1);
  return L(() => (t.current = !0, () => {
    t.current = !1;
  }), []), t;
}
function Y() {
  const t = F(), [r, e] = U(0), n = v(() => {
    t.current && e(r + 1);
  }, [r]);
  return [v(() => V.postRender(n), [n]), r];
}
class j extends u.Component {
  getSnapshotBeforeUpdate(r) {
    const e = this.props.childRef.current;
    if (e && r.isPresent && !this.props.isPresent) {
      const n = this.props.sizeRef.current;
      n.height = e.offsetHeight || 0, n.width = e.offsetWidth || 0, n.top = e.offsetTop, n.left = e.offsetLeft;
    }
    return null;
  }
  /**
   * Required with getSnapshotBeforeUpdate to stop React complaining.
   */
  componentDidUpdate() {
  }
  render() {
    return this.props.children;
  }
}
function q({ children: t, isPresent: r }) {
  const e = I(), n = m(null), p = m({
    width: 0,
    height: 0,
    top: 0,
    left: 0
  });
  return K(() => {
    const { width: d, height: l, top: a, left: E } = p.current;
    if (r || !n.current || !d || !l)
      return;
    n.current.dataset.motionPopId = e;
    const f = document.createElement("style");
    return document.head.appendChild(f), f.sheet && f.sheet.insertRule(`
          [data-motion-pop-id="${e}"] {
            position: absolute !important;
            width: ${d}px !important;
            height: ${l}px !important;
            top: ${a}px !important;
            left: ${E}px !important;
          }
        `), () => {
      document.head.removeChild(f);
    };
  }, [r]), u.createElement(j, { isPresent: r, childRef: n, sizeRef: p }, u.cloneElement(t, { ref: n }));
}
const M = ({ children: t, initial: r, isPresent: e, onExitComplete: n, custom: p, presenceAffectsLayout: d, mode: l }) => {
  const a = _(J), E = I(), f = z(
    () => ({
      id: E,
      initial: r,
      isPresent: e,
      custom: p,
      onExitComplete: (o) => {
        a.set(o, !0);
        for (const i of a.values())
          if (!i)
            return;
        n && n();
      },
      register: (o) => (a.set(o, !1), () => a.delete(o))
    }),
    /**
     * If the presence of a child affects the layout of the components around it,
     * we want to make a new context value to ensure they get re-rendered
     * so they can detect that layout change.
     */
    d ? void 0 : [e]
  );
  return z(() => {
    a.forEach((o, i) => a.set(i, !1));
  }, [e]), u.useEffect(() => {
    !e && !a.size && n && n();
  }, [e]), l === "popLayout" && (t = u.createElement(q, { isPresent: e }, t)), u.createElement(G.Provider, { value: f }, t);
};
function J() {
  return /* @__PURE__ */ new Map();
}
function Q(t) {
  return T(() => () => t(), []);
}
const h = (t) => t.key || "";
function X(t, r) {
  t.forEach((e) => {
    const n = h(e);
    r.set(n, e);
  });
}
function Z(t) {
  const r = [];
  return N.forEach(t, (e) => {
    S(e) && r.push(e);
  }), r;
}
const ne = ({ children: t, custom: r, initial: e = !0, onExitComplete: n, exitBeforeEnter: p, presenceAffectsLayout: d = !0, mode: l = "sync" }) => {
  W(!p, "Replace exitBeforeEnter with mode='wait'");
  const a = B(H).forceRender || Y()[0], E = F(), f = Z(t);
  let o = f;
  const i = m(/* @__PURE__ */ new Map()).current, C = m(o), x = m(/* @__PURE__ */ new Map()).current, g = m(!0);
  if (L(() => {
    g.current = !1, X(f, x), C.current = o;
  }), Q(() => {
    g.current = !0, x.clear(), i.clear();
  }), g.current)
    return u.createElement(u.Fragment, null, o.map((s) => u.createElement(M, { key: h(s), isPresent: !0, initial: e ? void 0 : !1, presenceAffectsLayout: d, mode: l }, s)));
  o = [...o];
  const w = C.current.map(h), P = f.map(h), b = w.length;
  for (let s = 0; s < b; s++) {
    const c = w[s];
    P.indexOf(c) === -1 && !i.has(c) && i.set(c, void 0);
  }
  return l === "wait" && i.size && (o = []), i.forEach((s, c) => {
    if (P.indexOf(c) !== -1)
      return;
    const y = x.get(c);
    if (!y)
      return;
    const k = w.indexOf(c);
    let R = s;
    if (!R) {
      const $ = () => {
        x.delete(c), i.delete(c);
        const A = C.current.findIndex((O) => O.key === c);
        if (C.current.splice(A, 1), !i.size) {
          if (C.current = f, E.current === !1)
            return;
          a(), n && n();
        }
      };
      R = u.createElement(M, { key: h(y), isPresent: !1, onExitComplete: $, custom: r, presenceAffectsLayout: d, mode: l }, y), i.set(c, R);
    }
    o.splice(k, 0, R);
  }), o = o.map((s) => {
    const c = s.key;
    return i.has(c) ? s : u.createElement(M, { key: h(s), isPresent: !0, presenceAffectsLayout: d, mode: l }, s);
  }), process.env.NODE_ENV !== "production" && l === "wait" && o.length > 1 && console.warn(`You're attempting to animate multiple children within AnimatePresence, but its mode is set to "wait". This will lead to odd visual behaviour.`), u.createElement(u.Fragment, null, i.size ? o : o.map((s) => D(s)));
};
export {
  ne as A
};
