import { Subscribe } from 'unstated';
import { Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import AuthContainer from 'containers/AuthContainer';
import { useMode } from 'context/ModeProvider';
import styles from './GotCreditsQuestion.module.scss';

const GotCreditsQuestion = () => {
  const { isApp } = useMode();
  const { t } = useTranslation();

  return (
    <Subscribe to={[AuthContainer]}>
      {(auth: AuthContainer) =>
        auth.state.unauthenticated && (
          <Text variant="xs" align="center">
            {t('pricingSection.creditsQuestion')}{' '}
            {isApp ? (
              t('pricingSection.logInAction')
            ) : (
              <button className={styles.button} type="button" onClick={() => auth.logIn()}>
                <Text variant="xs" textColor="inherited">
                  {t('pricingSection.logInAction')}
                </Text>
              </button>
            )}
          </Text>
        )
      }
    </Subscribe>
  );
};

export { GotCreditsQuestion };
