import { LinkButton, type LinkButtonSize } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { useModal } from 'modules/shared';

type RefundPolicyButtonProps = {
  size?: LinkButtonSize;
};

const RefundPolicyButton = ({ size = 'm' }: RefundPolicyButtonProps) => {
  const { t } = useTranslation('precheck');
  const { open } = useModal('refundPolicy');

  return (
    <LinkButton
      leadingIcon="shield-check"
      size={size}
      className="underline hover:underline"
      onClick={open}
    >
      {t('refundPolicy.title')}
    </LinkButton>
  );
};

export { RefundPolicyButton };
