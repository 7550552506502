import { cn } from '@carvertical/utils/styling';

type SidebarProps = {
  as?: React.ElementType;
  className?: string;
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const Sidebar = ({ as: RenderAs = 'div', className, children, ...props }: SidebarProps) => (
  <RenderAs
    className={cn('border-0 border-r border-solid border-r-grey-200 bg-white', className)}
    {...props}
  >
    <div className="sticky top-8 w-full py-4">{children}</div>
  </RenderAs>
);

export { Sidebar };
export type { SidebarProps };
