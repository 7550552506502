globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"ccabd873190a3eae754fd8a53fac102f93ac6d3a"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';
import sentryCommonConfig from './sentry.common.config';

Sentry.init({
  ...sentryCommonConfig,
  // The following errors can be tolerated and shouldn’t be reported to Sentry:
  ignoreErrors: [
    // Legacy browsers:
    'Unexpected token',

    // ResizeObserver couldn't deliver all observations within a single animation frame, which is fine:
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    /Loading (?:CSS )?chunk \d+ failed/,

    // In-app Webview Browser for Android devices, which is fine
    // https://github.com/getsentry/sentry-javascript/issues/10011#issuecomment-2180849453
    "Cannot read properties of undefined (reading 'domInteractive')",

    // User clicks on recaptcha and don't perform any submit action, which is fine
    // https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-1886350898
    'Non-Error promise rejection captured with value: Timeout',

    // When a rejection is raised with a non-error object, from which we cannot extract much information
    // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-954602174
    'Non-Error promise rejection captured',
    'Non-Error exception captured',

    // Unknown events and non-error objects are being thrown, as expected behavior
    // https://sentry.zendesk.com/hc/en-us/articles/26649121915291-I-m-getting-Object-captured-as-exception-with-keys
    'Object captured as exception',
  ],
});
