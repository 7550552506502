import { defineConfig, type VariantProps } from 'cva';
import { cn } from './cn';

const { cva, compose } = defineConfig({
  hooks: {
    onComplete: (className) => cn(className),
  },
});

export { cva, compose };
export type { VariantProps };
