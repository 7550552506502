import { j as c } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as f } from "./chunks/clsx.0d472180.js";
import { forwardRef as g } from "react";
import { g as r, a as y } from "./chunks/utils.60e8c32c.js";
import { n as C } from "./chunks/styles.077839a8.js";
import "./chunks/lodash.14d7cb6d.js";
var R = /* @__PURE__ */ ((e) => (e.Root = "GridItem", e))(R || {});
const n = g(
  ({
    as: e = "div",
    span: s,
    start: t,
    className: a,
    style: o,
    children: i,
    ...m
  }, l) => {
    const p = r(s || 0), d = r(t || "auto"), u = y([
      { name: "span", config: p },
      { name: "start", config: d }
    ]);
    return /* @__PURE__ */ c.jsx(
      e,
      {
        ref: l,
        "data-testid": "GridItem",
        className: f(C.root, a),
        style: {
          ...o,
          ...u
        },
        ...m,
        children: i
      }
    );
  }
);
try {
  n.displayName = "GridItem", n.__docgenInfo = { description: "", displayName: "GridItem", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, span: { defaultValue: null, description: "This props allows element to span more grid columns (column count is defined by `<Grid />` `columnCount` prop)\n\nCan be numeric value OR responsive configuration object, based on mobile-first order.", name: "span", required: !1, type: { name: "ValueOrResponsiveConfig<GridColumnCountRange>" } }, start: { defaultValue: null, description: "", name: "start", required: !1, type: { name: "ValueOrResponsiveConfig<GridColumnCountRange>" } } } };
} catch {
}
export {
  n as GridItem,
  R as GridItemTestId
};
