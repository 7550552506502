import { find, fromPairs, compact, each, escapeRegExp } from 'lodash';
import { createContext, useContext, useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useRouter } from 'next/router';
import { PRECISION } from 'constants/currencies';
import { format as formatMoney, toPrecision, getVatRate, calculateDiscount } from 'utils/money';
import { getMarket } from 'services/site';
import { getExperiment } from 'services/experiments';
import { getPackages } from 'services/packages';
import { isVoucherAllowed } from 'components/CheckoutPage/utils';
import { useDiscount } from './DiscountProvider';

const CURRENCY_EXPERIMENT_KEY = 'curr';
const CUSTOM_PRICING_QUERY_PARAM = 'p';

const PricingContext = createContext();
let source;

const PricingProvider = (props) => {
  const [fetching, setFetching] = useState(true);
  const [fetchPromise, setFetchPromise] = useState();
  const [packages, setPackages] = useState([]);
  const { voucher } = useDiscount();
  const {
    query: { campaign },
  } = useRouter();

  useEffect(() => {
    (async () => {
      source?.cancel();
      source = axios.CancelToken.source();

      let packages;

      try {
        const promise = getPackages({ voucher, cancelToken: source.token });

        setFetchPromise(promise);

        ({ data: packages } = await promise);
      } catch (err) {
        if (!axios.isCancel(err)) {
          throw err;
        }
        return;
      }

      packages = deserialize(packages);
      const originalPackages = { ...packages };

      const { id: market, pricing } = getMarket();

      const customPricing = getExperiment(CUSTOM_PRICING_QUERY_PARAM);

      const pricingOverrideSuffixes = compact([
        campaign && `${campaign}-${market}`,
        customPricing && `${customPricing}-${market}`,
        market,
        campaign,
        customPricing,
        pricing,
      ]);
      pricingOverrideSuffixes.forEach((suffix) => {
        const pattern = new RegExp(`^(.+)-${escapeRegExp(suffix)}$`);

        each(packages, (pkg) => {
          const [match, id] = pkg.id.match(pattern) || [];

          if (match && !packages[id]?.originalId) {
            const comparisonId =
              campaign && match.replace(`-${campaign}`, '').replace(id, 'standard');

            delete packages[pkg.id];

            packages[id] = {
              ...pkg,
              id,
              originalId: pkg.id,
              ...(comparisonId && {
                comparedWith: originalPackages[comparisonId],
              }),
            };
          }
        });
      });

      setPackages(packages);

      setFetching(false);
    })();
  }, [voucher, campaign]);

  const value = useMemo(
    () => ({
      fetching,
      fetchPromise,
      packages,
    }),
    [fetching, fetchPromise, packages],
  );

  return <PricingContext.Provider value={value} {...props} />;
};

const usePackages = () => useContext(PricingContext);

const usePricing = (id, { currency } = {}) => {
  const market = getMarket();
  const { fetching, packages } = usePackages();
  const discount = useDiscount();

  const experimentCurrency = useMemo(() => {
    const currency = getExperiment(CURRENCY_EXPERIMENT_KEY);
    return market.allowedCurrencyOverrides?.includes(currency) ? currency : undefined;
  }, [market]);

  currency = experimentCurrency ?? currency ?? market.currency;

  return useMemo(() => {
    if (fetching) {
      return { fetching };
    }

    const pkg = packages[id];
    if (!pkg) {
      return {};
    }

    const reportCount = pkg.packageReportCount;

    let voucher;
    let percentage = 0;
    if (discount.voucher && isVoucherAllowed(pkg)) {
      ({ voucher, percentage } = discount);
    }

    const calculatePrices = ({ vatApplied }) => {
      const vatCountryCode = vatApplied && market.vat.alreadyIncluded ? market.countryCode : null;

      let fullPrice = getPriceFromPackage(pkg, { currency, vatCountryCode });
      const price = toPrecision(fullPrice * (1 - percentage / 100), PRECISION[currency]);

      const baseFullPriceWithoutDiscount =
        getPriceFromPackage(pkg.comparedWith || packages.standard, {
          currency,
          vatCountryCode,
        }) * reportCount;

      const checkoutDisSavings = toPrecision(
        baseFullPriceWithoutDiscount - fullPrice,
        PRECISION[currency],
      );
      const checkoutDisBasePackagePrice = fullPrice;
      const checkoutDisPackageVoucherSavings = percentage
        ? toPrecision(checkoutDisBasePackagePrice * (percentage / 100), PRECISION[currency])
        : 0;
      const baseFullPrice = !percentage ? baseFullPriceWithoutDiscount : fullPrice;

      const baseDiscount = calculateDiscount(fullPrice, baseFullPrice);
      const baseDiscountWithoutDiscount = calculateDiscount(
        fullPrice,
        baseFullPriceWithoutDiscount,
      );
      fullPrice = baseFullPrice;

      return {
        price,
        unitPrice: toPrecision(price / reportCount, PRECISION[currency]),
        fullPrice,
        checkoutDisFullPrice: baseFullPriceWithoutDiscount,
        checkoutDisBasePackagePrice,
        checkoutDisSavings,
        checkoutDisPackageVoucherSavings,
        savings: toPrecision(fullPrice - price, PRECISION[currency]),
        baseDiscount,
        ...(percentage && {
          pricingBeforeDiscount: {
            fullPrice: baseFullPriceWithoutDiscount,
            baseDiscount: baseDiscountWithoutDiscount,
          },
        }),
      };
    };

    const { baseDiscount, ...gross } = calculatePrices({ vatApplied: true });
    const net = calculatePrices({ vatApplied: false });

    if (baseDiscount) {
      percentage = baseDiscount;
    }

    return {
      $: createFormatter(currency),
      id: pkg.id,
      productId: pkg.productId,
      reportProductId: pkg.packageProductId,
      reportCount,
      currency,
      ...gross,
      net,
      ...(percentage && { discount: percentage, baseDiscount }),
      ...(voucher && { voucher }),
    };
  }, [id, currency, fetching, packages, discount, market]);
};

const usePackageId = (productId) => {
  const { packages } = usePackages();
  return (find(packages, { productId }) || {}).id;
};

// Helpers

function getPriceFromPackage(pkg, { currency, vatCountryCode }) {
  return toPrecision(
    pkg.packagePricing[currency] * getVatRate(vatCountryCode).factor,
    PRECISION[currency],
  );
}

function createFormatter(currency) {
  return (amount) => formatMoney(amount, currency);
}

function deserialize(packages) {
  const market = getMarket();
  return fromPairs(
    packages
      .filter(
        ({ visible, disabled, packageProductId, packagePricing }) =>
          visible &&
          !disabled &&
          packageProductId === market.products.report &&
          packagePricing[market.currency],
      )
      .map(({ title: id, ...pkg }) => [id, { id, ...pkg }]),
  );
}

export default PricingProvider;

// Hooks
export { usePackages, usePricing, usePackageId };
