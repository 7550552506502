import { a as me, c as Ke, d as xe, f as Se, g as ne, e as we } from "./Collection.e8e46ab5.js";
import { g as O, b as V, d as Q, m as Pe, $ as se, n as Ie, c as Y, j as ae, o as De, p as Be, l as de } from "./utils.371a0d8b.js";
import h, { useMemo as I, createContext as M, useState as L, useRef as D, useEffect as R, useCallback as ce, forwardRef as T, useContext as B } from "react";
import { $ as Ce } from "./Header.de56351a.js";
import { d as F, r as Me, n as Fe, s as Ee, h as ue, t as Z, f as Ae, i as _e } from "./useFocusRing.91eeed6d.js";
import { b as fe, d as Ve, a as ee, e as Re, c as Te, f as ke } from "./useSelectableItem.ca3ec579.js";
let j = /* @__PURE__ */ new Map();
function he(i) {
  let { locale: e } = fe(), t = e + (i ? Object.entries(i).sort((o, r) => o[0] < r[0] ? -1 : 1).join() : "");
  if (j.has(t))
    return j.get(t);
  let l = new Intl.Collator(e, i);
  return j.set(t, l), l;
}
function He(i) {
  let { id: e, label: t, "aria-labelledby": l, "aria-label": o, labelElementType: r = "label" } = i;
  e = O(e);
  let n = O(), s = {};
  t ? (l = l ? `${n} ${l}` : n, s = {
    id: n,
    htmlFor: r === "label" ? e : void 0
  }) : !l && !o && console.warn("If you do not provide a visible label, you must specify an aria-label or aria-labelledby attribute for accessibility");
  let a = Ve({
    id: e,
    "aria-label": o,
    "aria-labelledby": l
  });
  return {
    labelProps: s,
    fieldProps: a
  };
}
const G = /* @__PURE__ */ new WeakMap();
function ze(i) {
  return typeof i == "string" ? i.replace(/\s*/g, "") : "" + i;
}
function Le(i, e) {
  let t = G.get(i);
  if (!t)
    throw new Error("Unknown list");
  return `${t.id}-option-${ze(e)}`;
}
class ge {
  isDisabled(e) {
    var t;
    return this.disabledBehavior === "all" && (((t = e.props) === null || t === void 0 ? void 0 : t.isDisabled) || this.disabledKeys.has(e.key));
  }
  getNextKey(e) {
    for (e = this.collection.getKeyAfter(e); e != null; ) {
      let t = this.collection.getItem(e);
      if (t.type === "item" && !this.isDisabled(t))
        return e;
      e = this.collection.getKeyAfter(e);
    }
    return null;
  }
  getPreviousKey(e) {
    for (e = this.collection.getKeyBefore(e); e != null; ) {
      let t = this.collection.getItem(e);
      if (t.type === "item" && !this.isDisabled(t))
        return e;
      e = this.collection.getKeyBefore(e);
    }
    return null;
  }
  findKey(e, t, l) {
    let o = this.getItem(e);
    if (!o)
      return null;
    let r = o.getBoundingClientRect();
    do
      e = t(e), o = this.getItem(e);
    while (o && l(r, o.getBoundingClientRect()));
    return e;
  }
  isSameRow(e, t) {
    return e.top === t.top || e.left !== t.left;
  }
  isSameColumn(e, t) {
    return e.left === t.left || e.top !== t.top;
  }
  getKeyBelow(e) {
    return this.layout === "grid" && this.orientation === "vertical" ? this.findKey(e, (t) => this.getNextKey(t), this.isSameRow) : this.getNextKey(e);
  }
  getKeyAbove(e) {
    return this.layout === "grid" && this.orientation === "vertical" ? this.findKey(e, (t) => this.getPreviousKey(t), this.isSameRow) : this.getPreviousKey(e);
  }
  getNextColumn(e, t) {
    return t ? this.getPreviousKey(e) : this.getNextKey(e);
  }
  getKeyRightOf(e) {
    return this.layout === "grid" ? this.orientation === "vertical" ? this.getNextColumn(e, this.direction === "rtl") : this.findKey(e, (t) => this.getNextColumn(t, this.direction === "rtl"), this.isSameColumn) : this.orientation === "horizontal" ? this.getNextColumn(e, this.direction === "rtl") : null;
  }
  getKeyLeftOf(e) {
    return this.layout === "grid" ? this.orientation === "vertical" ? this.getNextColumn(e, this.direction === "ltr") : this.findKey(e, (t) => this.getNextColumn(t, this.direction === "ltr"), this.isSameColumn) : this.orientation === "horizontal" ? this.getNextColumn(e, this.direction === "ltr") : null;
  }
  getFirstKey() {
    let e = this.collection.getFirstKey();
    for (; e != null; ) {
      let t = this.collection.getItem(e);
      if ((t == null ? void 0 : t.type) === "item" && !this.isDisabled(t))
        return e;
      e = this.collection.getKeyAfter(e);
    }
    return null;
  }
  getLastKey() {
    let e = this.collection.getLastKey();
    for (; e != null; ) {
      let t = this.collection.getItem(e);
      if (t.type === "item" && !this.isDisabled(t))
        return e;
      e = this.collection.getKeyBefore(e);
    }
    return null;
  }
  getItem(e) {
    return e !== null ? this.ref.current.querySelector(`[data-key="${CSS.escape(e.toString())}"]`) : null;
  }
  getKeyPageAbove(e) {
    let t = this.ref.current, l = this.getItem(e);
    if (!l)
      return null;
    if (!ee(t))
      return this.getFirstKey();
    let o = t.getBoundingClientRect(), r = l.getBoundingClientRect();
    if (this.orientation === "horizontal") {
      let n = o.x - t.scrollLeft, s = Math.max(0, r.x - n + r.width - o.width);
      for (; l && r.x - n > s; )
        e = this.getKeyAbove(e), l = e == null ? null : this.getItem(e), r = l == null ? void 0 : l.getBoundingClientRect();
    } else {
      let n = o.y - t.scrollTop, s = Math.max(0, r.y - n + r.height - o.height);
      for (; l && r.y - n > s; )
        e = this.getKeyAbove(e), l = e == null ? null : this.getItem(e), r = l == null ? void 0 : l.getBoundingClientRect();
    }
    return e ?? this.getFirstKey();
  }
  getKeyPageBelow(e) {
    let t = this.ref.current, l = this.getItem(e);
    if (!l)
      return null;
    if (!ee(t))
      return this.getLastKey();
    let o = t.getBoundingClientRect(), r = l.getBoundingClientRect();
    if (this.orientation === "horizontal") {
      let n = o.x - t.scrollLeft, s = Math.min(t.scrollWidth, r.x - n - r.width + o.width);
      for (; l && r.x - n < s; )
        e = this.getKeyBelow(e), l = e == null ? null : this.getItem(e), r = l == null ? void 0 : l.getBoundingClientRect();
    } else {
      let n = o.y - t.scrollTop, s = Math.min(t.scrollHeight, r.y - n - r.height + o.height);
      for (; l && r.y - n < s; )
        e = this.getKeyBelow(e), l = e == null ? null : this.getItem(e), r = l == null ? void 0 : l.getBoundingClientRect();
    }
    return e ?? this.getLastKey();
  }
  getKeyForSearch(e, t) {
    if (!this.collator)
      return null;
    let l = this.collection, o = t || this.getFirstKey();
    for (; o != null; ) {
      let r = l.getItem(o), n = r.textValue.slice(0, e.length);
      if (r.textValue && this.collator.compare(n, e) === 0)
        return o;
      o = this.getKeyBelow(o);
    }
    return null;
  }
  constructor(...e) {
    if (e.length === 1) {
      let t = e[0];
      this.collection = t.collection, this.ref = t.ref, this.collator = t.collator, this.disabledKeys = t.disabledKeys || /* @__PURE__ */ new Set(), this.disabledBehavior = t.disabledBehavior || "all", this.orientation = t.orientation, this.direction = t.direction, this.layout = t.layout || "stack";
    } else
      this.collection = e[0], this.disabledKeys = e[1], this.ref = e[2], this.collator = e[3], this.layout = "stack", this.orientation = "vertical", this.disabledBehavior = "all";
    this.layout === "stack" && this.orientation === "vertical" && (this.getKeyLeftOf = void 0, this.getKeyRightOf = void 0);
  }
}
function Oe(i) {
  let { selectionManager: e, collection: t, disabledKeys: l, ref: o, keyboardDelegate: r } = i, n = he({
    usage: "search",
    sensitivity: "base"
  }), s = e.disabledBehavior, a = I(() => r || new ge({
    collection: t,
    disabledKeys: l,
    disabledBehavior: s,
    ref: o,
    collator: n
  }), [
    r,
    t,
    l,
    o,
    n,
    s
  ]), { collectionProps: d } = Re({
    ...i,
    ref: o,
    selectionManager: e,
    keyboardDelegate: a
  });
  return {
    listProps: d
  };
}
function Ue(i, e, t) {
  let l = F(i, {
    labelable: !0
  }), o = i.selectionBehavior || "toggle", r = i.linkBehavior || (o === "replace" ? "action" : "override");
  o === "toggle" && r === "action" && (r = "override");
  let { listProps: n } = Oe({
    ...i,
    ref: t,
    selectionManager: e.selectionManager,
    collection: e.collection,
    disabledKeys: e.disabledKeys,
    linkBehavior: r
  }), { focusWithinProps: s } = Me({
    onFocusWithin: i.onFocus,
    onBlurWithin: i.onBlur,
    onFocusWithinChange: i.onFocusChange
  }), a = O(i.id);
  G.set(e, {
    id: a,
    shouldUseVirtualFocus: i.shouldUseVirtualFocus,
    shouldSelectOnPressUp: i.shouldSelectOnPressUp,
    shouldFocusOnHover: i.shouldFocusOnHover,
    isVirtualized: i.isVirtualized,
    onAction: i.onAction,
    linkBehavior: r
  });
  let { labelProps: d, fieldProps: c } = He({
    ...i,
    id: a,
    // listbox is not an HTML input element so it
    // shouldn't be labeled by a <label> element.
    labelElementType: "span"
  });
  return {
    labelProps: d,
    listBoxProps: V(l, s, e.selectionManager.selectionMode === "multiple" ? {
      "aria-multiselectable": "true"
    } : {}, {
      role: "listbox",
      ...V(c, n)
    })
  };
}
function Ne(i, e) {
  return typeof e.getChildren == "function" ? e.getChildren(i.key) : i.childNodes;
}
function Pt(i) {
  let e;
  for (let t of i)
    e = t;
  return e;
}
const te = /* @__PURE__ */ new WeakMap();
function We(i) {
  let e = te.get(i);
  if (e != null)
    return e;
  e = 0;
  let t = (l) => {
    for (let o of l)
      o.type === "section" ? t(Ne(o, i)) : e++;
  };
  return t(i), te.set(i, e), e;
}
function je(i, e, t) {
  var l, o;
  let { key: r } = i, n = G.get(e);
  var s;
  let a = (s = i.isDisabled) !== null && s !== void 0 ? s : e.selectionManager.isDisabled(r);
  var d;
  let c = (d = i.isSelected) !== null && d !== void 0 ? d : e.selectionManager.isSelected(r);
  var g;
  let f = (g = i.shouldSelectOnPressUp) !== null && g !== void 0 ? g : n == null ? void 0 : n.shouldSelectOnPressUp;
  var u;
  let p = (u = i.shouldFocusOnHover) !== null && u !== void 0 ? u : n == null ? void 0 : n.shouldFocusOnHover;
  var y;
  let b = (y = i.shouldUseVirtualFocus) !== null && y !== void 0 ? y : n == null ? void 0 : n.shouldUseVirtualFocus;
  var v;
  let P = (v = i.isVirtualized) !== null && v !== void 0 ? v : n == null ? void 0 : n.isVirtualized, m = Q(), K = Q(), x = {
    role: "option",
    "aria-disabled": a || void 0,
    "aria-selected": e.selectionManager.selectionMode !== "none" ? c : void 0
  };
  Fe() && Ee() || (x["aria-label"] = i["aria-label"], x["aria-labelledby"] = m, x["aria-describedby"] = K);
  let $ = e.collection.getItem(r);
  if (P) {
    let C = Number($ == null ? void 0 : $.index);
    x["aria-posinset"] = Number.isNaN(C) ? void 0 : C + 1, x["aria-setsize"] = We(e.collection);
  }
  let E = n != null && n.onAction ? () => {
    var C;
    return n == null || (C = n.onAction) === null || C === void 0 ? void 0 : C.call(n, r);
  } : void 0, { itemProps: k, isPressed: N, isFocused: A, hasAction: H, allowsSelection: z } = Te({
    selectionManager: e.selectionManager,
    key: r,
    ref: t,
    shouldSelectOnPressUp: f,
    allowsDifferentPressOrigin: f && p,
    isVirtualized: P,
    shouldUseVirtualFocus: b,
    isDisabled: a,
    onAction: E || !($ == null || (l = $.props) === null || l === void 0) && l.onAction ? Pe($ == null || (o = $.props) === null || o === void 0 ? void 0 : o.onAction, E) : void 0,
    linkBehavior: n == null ? void 0 : n.linkBehavior
  }), { hoverProps: W } = ue({
    isDisabled: a || !p,
    onHoverStart() {
      Z() || (e.selectionManager.setFocused(!0), e.selectionManager.setFocusedKey(r));
    }
  }), _ = F($ == null ? void 0 : $.props);
  delete _.id;
  let S = Ae($ == null ? void 0 : $.props);
  return {
    optionProps: {
      ...x,
      ...V(_, k, W, S),
      id: Le(e, r)
    },
    labelProps: {
      id: m
    },
    descriptionProps: {
      id: K
    },
    isFocused: A,
    isFocusVisible: A && Z(),
    isSelected: c,
    isDisabled: a,
    isPressed: N,
    allowsSelection: z,
    hasAction: H
  };
}
function qe(i) {
  let { heading: e, "aria-label": t } = i, l = O();
  return {
    itemProps: {
      role: "presentation"
    },
    headingProps: e ? {
      // Techincally, listbox cannot contain headings according to ARIA.
      // We hide the heading from assistive technology, using role="presentation",
      // and only use it as a visual label for the nested group.
      id: l,
      role: "presentation"
    } : {},
    groupProps: {
      role: "group",
      "aria-label": t,
      "aria-labelledby": e ? l : void 0
    }
  };
}
function Xe(i) {
  let e = F(i, {
    labelable: !0
  }), t;
  return i.orientation === "vertical" && (t = "vertical"), i.elementType !== "hr" ? {
    separatorProps: {
      ...e,
      role: "separator",
      "aria-orientation": t
    }
  } : {
    separatorProps: e
  };
}
const Ye = /* @__PURE__ */ M({});
class Ge {
  build(e, t) {
    return this.context = t, le(() => this.iterateCollection(e));
  }
  *iterateCollection(e) {
    let { children: t, items: l } = e;
    if (typeof t == "function") {
      if (!l)
        throw new Error("props.children was a function but props.items is missing");
      for (let o of e.items)
        yield* this.getFullNode({
          value: o
        }, {
          renderer: t
        });
    } else {
      let o = [];
      h.Children.forEach(t, (n) => {
        o.push(n);
      });
      let r = 0;
      for (let n of o) {
        let s = this.getFullNode({
          element: n,
          index: r
        }, {});
        for (let a of s)
          r++, yield a;
      }
    }
  }
  getKey(e, t, l, o) {
    if (e.key != null)
      return e.key;
    if (t.type === "cell" && t.key != null)
      return `${o}${t.key}`;
    let r = t.value;
    if (r != null) {
      var n;
      let s = (n = r.key) !== null && n !== void 0 ? n : r.id;
      if (s == null)
        throw new Error("No key found for item");
      return s;
    }
    return o ? `${o}.${t.index}` : `$.${t.index}`;
  }
  getChildState(e, t) {
    return {
      renderer: t.renderer || e.renderer
    };
  }
  *getFullNode(e, t, l, o) {
    let r = e.element;
    if (!r && e.value && t && t.renderer) {
      let a = this.cache.get(e.value);
      if (a && (!a.shouldInvalidate || !a.shouldInvalidate(this.context))) {
        a.index = e.index, a.parentKey = o ? o.key : null, yield a;
        return;
      }
      r = t.renderer(e.value);
    }
    if (h.isValidElement(r)) {
      let a = r.type;
      if (typeof a != "function" && typeof a.getCollectionNode != "function") {
        let f = typeof r.type == "function" ? r.type.name : r.type;
        throw new Error(`Unknown element <${f}> in collection.`);
      }
      let d = a.getCollectionNode(r.props, this.context), c = e.index, g = d.next();
      for (; !g.done && g.value; ) {
        let f = g.value;
        e.index = c;
        let u = f.key;
        u || (u = f.element ? null : this.getKey(r, e, t, l));
        let y = [
          ...this.getFullNode({
            ...f,
            key: u,
            index: c,
            wrapper: Je(e.wrapper, f.wrapper)
          }, this.getChildState(t, f), l ? `${l}${r.key}` : r.key, o)
        ];
        for (let b of y) {
          if (b.value = f.value || e.value, b.value && this.cache.set(b.value, b), e.type && b.type !== e.type)
            throw new Error(`Unsupported type <${q(b.type)}> in <${q(o.type)}>. Only <${q(e.type)}> is supported.`);
          c++, yield b;
        }
        g = d.next(y);
      }
      return;
    }
    if (e.key == null)
      return;
    let n = this, s = {
      type: e.type,
      props: e.props,
      key: e.key,
      parentKey: o ? o.key : null,
      value: e.value,
      level: o ? o.level + 1 : 0,
      index: e.index,
      rendered: e.rendered,
      textValue: e.textValue,
      "aria-label": e["aria-label"],
      wrapper: e.wrapper,
      shouldInvalidate: e.shouldInvalidate,
      hasChildNodes: e.hasChildNodes,
      childNodes: le(function* () {
        if (!e.hasChildNodes)
          return;
        let a = 0;
        for (let d of e.childNodes()) {
          d.key != null && (d.key = `${s.key}${d.key}`), d.index = a;
          let c = n.getFullNode(d, n.getChildState(t, d), s.key, s);
          for (let g of c)
            a++, yield g;
        }
      })
    };
    yield s;
  }
  constructor() {
    this.cache = /* @__PURE__ */ new WeakMap();
  }
}
function le(i) {
  let e = [], t = null;
  return {
    *[Symbol.iterator]() {
      for (let l of e)
        yield l;
      t || (t = i());
      for (let l of t)
        e.push(l), yield l;
    }
  };
}
function Je(i, e) {
  if (i && e)
    return (t) => i(e(t));
  if (i)
    return i;
  if (e)
    return e;
}
function q(i) {
  return i[0].toUpperCase() + i.slice(1);
}
function Qe(i, e, t) {
  let l = I(() => new Ge(), []), { children: o, items: r, collection: n } = i;
  return I(() => {
    if (n)
      return n;
    let a = l.build({
      children: o,
      items: r
    }, t);
    return e(a);
  }, [
    l,
    o,
    r,
    n,
    t,
    e
  ]);
}
function Ze(i, e) {
  return typeof e.getChildren == "function" ? e.getChildren(i.key) : i.childNodes;
}
function et(i) {
  return tt(i, 0);
}
function tt(i, e) {
  if (e < 0)
    return;
  let t = 0;
  for (let l of i) {
    if (t === e)
      return l;
    t++;
  }
}
function X(i, e, t) {
  if (e.parentKey === t.parentKey)
    return e.index - t.index;
  let l = [
    ...ie(i, e),
    e
  ], o = [
    ...ie(i, t),
    t
  ], r = l.slice(0, o.length).findIndex((n, s) => n !== o[s]);
  return r !== -1 ? (e = l[r], t = o[r], e.index - t.index) : l.findIndex((n) => n === t) >= 0 ? 1 : (o.findIndex((n) => n === e) >= 0, -1);
}
function ie(i, e) {
  let t = [];
  for (; (e == null ? void 0 : e.parentKey) != null; )
    e = i.getItem(e.parentKey), t.unshift(e);
  return t;
}
class oe {
  *[Symbol.iterator]() {
    yield* this.iterable;
  }
  get size() {
    return this.keyMap.size;
  }
  getKeys() {
    return this.keyMap.keys();
  }
  getKeyBefore(e) {
    let t = this.keyMap.get(e);
    return t ? t.prevKey : null;
  }
  getKeyAfter(e) {
    let t = this.keyMap.get(e);
    return t ? t.nextKey : null;
  }
  getFirstKey() {
    return this.firstKey;
  }
  getLastKey() {
    return this.lastKey;
  }
  getItem(e) {
    return this.keyMap.get(e);
  }
  at(e) {
    const t = [
      ...this.getKeys()
    ];
    return this.getItem(t[e]);
  }
  getChildren(e) {
    let t = this.keyMap.get(e);
    return (t == null ? void 0 : t.childNodes) || [];
  }
  constructor(e) {
    this.keyMap = /* @__PURE__ */ new Map(), this.iterable = e;
    let t = (r) => {
      if (this.keyMap.set(r.key, r), r.childNodes && r.type === "section")
        for (let n of r.childNodes)
          t(n);
    };
    for (let r of e)
      t(r);
    let l, o = 0;
    for (let [r, n] of this.keyMap)
      l ? (l.nextKey = r, n.prevKey = l.key) : (this.firstKey = r, n.prevKey = void 0), n.type === "item" && (n.index = o++), l = n, l.nextKey = void 0;
    this.lastKey = l == null ? void 0 : l.key;
  }
}
class w extends Set {
  constructor(e, t, l) {
    super(e), e instanceof w ? (this.anchorKey = t || e.anchorKey, this.currentKey = l || e.currentKey) : (this.anchorKey = t, this.currentKey = l);
  }
}
function lt(i, e, t) {
  let [l, o] = L(i || e), r = D(i !== void 0), n = i !== void 0;
  R(() => {
    let d = r.current;
    d !== n && console.warn(`WARN: A component changed from ${d ? "controlled" : "uncontrolled"} to ${n ? "controlled" : "uncontrolled"}.`), r.current = n;
  }, [
    n
  ]);
  let s = n ? i : l, a = ce((d, ...c) => {
    let g = (f, ...u) => {
      t && (Object.is(s, f) || t(f, ...u)), n || (s = f);
    };
    typeof d == "function" ? (console.warn("We can not support a function callback. See Github Issues for details https://github.com/adobe/react-spectrum/issues/2320"), o((u, ...p) => {
      let y = d(n ? s : u, ...p);
      return g(y, ...c), n ? u : y;
    })) : (n || o(d), g(d, ...c));
  }, [
    n,
    s,
    t
  ]);
  return [
    s,
    a
  ];
}
function it(i, e) {
  if (i.size !== e.size)
    return !1;
  for (let t of i)
    if (!e.has(t))
      return !1;
  return !0;
}
function ot(i) {
  let { selectionMode: e = "none", disallowEmptySelection: t, allowDuplicateSelectionEvents: l, selectionBehavior: o = "toggle", disabledBehavior: r = "all" } = i, n = D(!1), [, s] = L(!1), a = D(null), d = D(null), [, c] = L(null), g = I(() => re(i.selectedKeys), [
    i.selectedKeys
  ]), f = I(() => re(i.defaultSelectedKeys, new w()), [
    i.defaultSelectedKeys
  ]), [u, p] = lt(g, f, i.onSelectionChange), y = I(() => i.disabledKeys ? new Set(i.disabledKeys) : /* @__PURE__ */ new Set(), [
    i.disabledKeys
  ]), [b, v] = L(o);
  o === "replace" && b === "toggle" && typeof u == "object" && u.size === 0 && v("replace");
  let P = D(o);
  return R(() => {
    o !== P.current && (v(o), P.current = o);
  }, [
    o
  ]), {
    selectionMode: e,
    disallowEmptySelection: t,
    selectionBehavior: b,
    setSelectionBehavior: v,
    get isFocused() {
      return n.current;
    },
    setFocused(m) {
      n.current = m, s(m);
    },
    get focusedKey() {
      return a.current;
    },
    get childFocusStrategy() {
      return d.current;
    },
    setFocusedKey(m, K = "first") {
      a.current = m, d.current = K, c(m);
    },
    selectedKeys: u,
    setSelectedKeys(m) {
      (l || !it(m, u)) && p(m);
    },
    disabledKeys: y,
    disabledBehavior: r
  };
}
function re(i, e) {
  return i ? i === "all" ? "all" : new w(i) : e;
}
class rt {
  /**
  * The type of selection that is allowed in the collection.
  */
  get selectionMode() {
    return this.state.selectionMode;
  }
  /**
  * Whether the collection allows empty selection.
  */
  get disallowEmptySelection() {
    return this.state.disallowEmptySelection;
  }
  /**
  * The selection behavior for the collection.
  */
  get selectionBehavior() {
    return this.state.selectionBehavior;
  }
  /**
  * Sets the selection behavior for the collection.
  */
  setSelectionBehavior(e) {
    this.state.setSelectionBehavior(e);
  }
  /**
  * Whether the collection is currently focused.
  */
  get isFocused() {
    return this.state.isFocused;
  }
  /**
  * Sets whether the collection is focused.
  */
  setFocused(e) {
    this.state.setFocused(e);
  }
  /**
  * The current focused key in the collection.
  */
  get focusedKey() {
    return this.state.focusedKey;
  }
  /** Whether the first or last child of the focused key should receive focus. */
  get childFocusStrategy() {
    return this.state.childFocusStrategy;
  }
  /**
  * Sets the focused key.
  */
  setFocusedKey(e, t) {
    (e == null || this.collection.getItem(e)) && this.state.setFocusedKey(e, t);
  }
  /**
  * The currently selected keys in the collection.
  */
  get selectedKeys() {
    return this.state.selectedKeys === "all" ? new Set(this.getSelectAllKeys()) : this.state.selectedKeys;
  }
  /**
  * The raw selection value for the collection.
  * Either 'all' for select all, or a set of keys.
  */
  get rawSelection() {
    return this.state.selectedKeys;
  }
  /**
  * Returns whether a key is selected.
  */
  isSelected(e) {
    return this.state.selectionMode === "none" ? !1 : (e = this.getKey(e), this.state.selectedKeys === "all" ? this.canSelectItem(e) : this.state.selectedKeys.has(e));
  }
  /**
  * Whether the selection is empty.
  */
  get isEmpty() {
    return this.state.selectedKeys !== "all" && this.state.selectedKeys.size === 0;
  }
  /**
  * Whether all items in the collection are selected.
  */
  get isSelectAll() {
    if (this.isEmpty)
      return !1;
    if (this.state.selectedKeys === "all")
      return !0;
    if (this._isSelectAll != null)
      return this._isSelectAll;
    let e = this.getSelectAllKeys(), t = this.state.selectedKeys;
    return this._isSelectAll = e.every((l) => t.has(l)), this._isSelectAll;
  }
  get firstSelectedKey() {
    let e = null;
    for (let t of this.state.selectedKeys) {
      let l = this.collection.getItem(t);
      (!e || l && X(this.collection, l, e) < 0) && (e = l);
    }
    return e == null ? void 0 : e.key;
  }
  get lastSelectedKey() {
    let e = null;
    for (let t of this.state.selectedKeys) {
      let l = this.collection.getItem(t);
      (!e || l && X(this.collection, l, e) > 0) && (e = l);
    }
    return e == null ? void 0 : e.key;
  }
  get disabledKeys() {
    return this.state.disabledKeys;
  }
  get disabledBehavior() {
    return this.state.disabledBehavior;
  }
  /**
  * Extends the selection to the given key.
  */
  extendSelection(e) {
    if (this.selectionMode === "none")
      return;
    if (this.selectionMode === "single") {
      this.replaceSelection(e);
      return;
    }
    e = this.getKey(e);
    let t;
    if (this.state.selectedKeys === "all")
      t = new w([
        e
      ], e, e);
    else {
      let l = this.state.selectedKeys, o = l.anchorKey || e;
      t = new w(l, o, e);
      for (let r of this.getKeyRange(o, l.currentKey || e))
        t.delete(r);
      for (let r of this.getKeyRange(e, o))
        this.canSelectItem(r) && t.add(r);
    }
    this.state.setSelectedKeys(t);
  }
  getKeyRange(e, t) {
    let l = this.collection.getItem(e), o = this.collection.getItem(t);
    return l && o ? X(this.collection, l, o) <= 0 ? this.getKeyRangeInternal(e, t) : this.getKeyRangeInternal(t, e) : [];
  }
  getKeyRangeInternal(e, t) {
    let l = [], o = e;
    for (; o; ) {
      let r = this.collection.getItem(o);
      if ((r && r.type === "item" || r.type === "cell" && this.allowsCellSelection) && l.push(o), o === t)
        return l;
      o = this.collection.getKeyAfter(o);
    }
    return [];
  }
  getKey(e) {
    let t = this.collection.getItem(e);
    if (!t || t.type === "cell" && this.allowsCellSelection)
      return e;
    for (; t.type !== "item" && t.parentKey != null; )
      t = this.collection.getItem(t.parentKey);
    return !t || t.type !== "item" ? null : t.key;
  }
  /**
  * Toggles whether the given key is selected.
  */
  toggleSelection(e) {
    if (this.selectionMode === "none")
      return;
    if (this.selectionMode === "single" && !this.isSelected(e)) {
      this.replaceSelection(e);
      return;
    }
    if (e = this.getKey(e), e == null)
      return;
    let t = new w(this.state.selectedKeys === "all" ? this.getSelectAllKeys() : this.state.selectedKeys);
    t.has(e) ? t.delete(e) : this.canSelectItem(e) && (t.add(e), t.anchorKey = e, t.currentKey = e), !(this.disallowEmptySelection && t.size === 0) && this.state.setSelectedKeys(t);
  }
  /**
  * Replaces the selection with only the given key.
  */
  replaceSelection(e) {
    if (this.selectionMode === "none" || (e = this.getKey(e), e == null))
      return;
    let t = this.canSelectItem(e) ? new w([
      e
    ], e, e) : new w();
    this.state.setSelectedKeys(t);
  }
  /**
  * Replaces the selection with the given keys.
  */
  setSelectedKeys(e) {
    if (this.selectionMode === "none")
      return;
    let t = new w();
    for (let l of e)
      if (l = this.getKey(l), l != null && (t.add(l), this.selectionMode === "single"))
        break;
    this.state.setSelectedKeys(t);
  }
  getSelectAllKeys() {
    let e = [], t = (l) => {
      for (; l != null; ) {
        if (this.canSelectItem(l)) {
          let o = this.collection.getItem(l);
          o.type === "item" && e.push(l), o.hasChildNodes && (this.allowsCellSelection || o.type !== "item") && t(et(Ze(o, this.collection)).key);
        }
        l = this.collection.getKeyAfter(l);
      }
    };
    return t(this.collection.getFirstKey()), e;
  }
  /**
  * Selects all items in the collection.
  */
  selectAll() {
    !this.isSelectAll && this.selectionMode === "multiple" && this.state.setSelectedKeys("all");
  }
  /**
  * Removes all keys from the selection.
  */
  clearSelection() {
    !this.disallowEmptySelection && (this.state.selectedKeys === "all" || this.state.selectedKeys.size > 0) && this.state.setSelectedKeys(new w());
  }
  /**
  * Toggles between select all and an empty selection.
  */
  toggleSelectAll() {
    this.isSelectAll ? this.clearSelection() : this.selectAll();
  }
  select(e, t) {
    this.selectionMode !== "none" && (this.selectionMode === "single" ? this.isSelected(e) && !this.disallowEmptySelection ? this.toggleSelection(e) : this.replaceSelection(e) : this.selectionBehavior === "toggle" || t && (t.pointerType === "touch" || t.pointerType === "virtual") ? this.toggleSelection(e) : this.replaceSelection(e));
  }
  /**
  * Returns whether the current selection is equal to the given selection.
  */
  isSelectionEqual(e) {
    if (e === this.state.selectedKeys)
      return !0;
    let t = this.selectedKeys;
    if (e.size !== t.size)
      return !1;
    for (let l of e)
      if (!t.has(l))
        return !1;
    for (let l of t)
      if (!e.has(l))
        return !1;
    return !0;
  }
  canSelectItem(e) {
    var t;
    if (this.state.selectionMode === "none" || this.state.disabledKeys.has(e))
      return !1;
    let l = this.collection.getItem(e);
    return !(!l || !(l == null || (t = l.props) === null || t === void 0) && t.isDisabled || l.type === "cell" && !this.allowsCellSelection);
  }
  isDisabled(e) {
    var t, l;
    return this.state.disabledBehavior === "all" && (this.state.disabledKeys.has(e) || !!(!((l = this.collection.getItem(e)) === null || l === void 0 || (t = l.props) === null || t === void 0) && t.isDisabled));
  }
  isLink(e) {
    var t, l;
    return !!(!((l = this.collection.getItem(e)) === null || l === void 0 || (t = l.props) === null || t === void 0) && t.href);
  }
  getItemProps(e) {
    var t;
    return (t = this.collection.getItem(e)) === null || t === void 0 ? void 0 : t.props;
  }
  constructor(e, t, l) {
    this.collection = e, this.state = t;
    var o;
    this.allowsCellSelection = (o = l == null ? void 0 : l.allowsCellSelection) !== null && o !== void 0 ? o : !1, this._isSelectAll = null;
  }
}
function nt(i) {
  let { filter: e } = i, t = ot(i), l = I(() => i.disabledKeys ? new Set(i.disabledKeys) : /* @__PURE__ */ new Set(), [
    i.disabledKeys
  ]), o = ce((d) => e ? new oe(e(d)) : new oe(d), [
    e
  ]), r = I(() => ({
    suppressTextValueWarning: i.suppressTextValueWarning
  }), [
    i.suppressTextValueWarning
  ]), n = Qe(i, o, r), s = I(() => new rt(n, t), [
    n,
    t
  ]);
  const a = D(null);
  return R(() => {
    if (t.focusedKey != null && !n.getItem(t.focusedKey)) {
      const d = a.current.getItem(t.focusedKey), c = [
        ...a.current.getKeys()
      ].map((b) => {
        const v = a.current.getItem(b);
        return v.type === "item" ? v : null;
      }).filter((b) => b !== null), g = [
        ...n.getKeys()
      ].map((b) => {
        const v = n.getItem(b);
        return v.type === "item" ? v : null;
      }).filter((b) => b !== null), f = c.length - g.length;
      let u = Math.min(f > 1 ? Math.max(d.index - f + 1, 0) : d.index, g.length - 1), p, y = !1;
      for (; u >= 0; ) {
        if (!s.isDisabled(g[u].key)) {
          p = g[u];
          break;
        }
        u < g.length - 1 && !y ? u++ : (y = !0, u > d.index && (u = d.index), u--);
      }
      t.setFocusedKey(p ? p.key : null);
    }
    a.current = n;
  }, [
    n,
    s,
    t,
    t.focusedKey
  ]), {
    collection: n,
    disabledKeys: l,
    selectionManager: s
  };
}
const pe = /* @__PURE__ */ M(null);
function st(i, e) {
  let { render: t } = B(pe);
  return /* @__PURE__ */ h.createElement(h.Fragment, null, t(i, e));
}
const at = /* @__PURE__ */ T(st), J = /* @__PURE__ */ M({}), be = /* @__PURE__ */ M({});
function dt(i, e) {
  let [t, l] = se(i, e, be), { elementType: o, orientation: r, style: n, className: s } = t, a = o || "hr";
  a === "hr" && r === "vertical" && (a = "div");
  let { separatorProps: d } = Xe({
    elementType: o,
    orientation: r
  }), c = me("separator", i, e);
  return c || /* @__PURE__ */ h.createElement(a, {
    ...F(t),
    ...d,
    style: n,
    className: s ?? "react-aria-Separator",
    ref: l,
    slot: t.slot || void 0
  });
}
const ct = /* @__PURE__ */ T(dt), ye = /* @__PURE__ */ M(null), U = /* @__PURE__ */ M(null);
function ut(i, e) {
  [i, e] = se(i, e, ye);
  let t = B(Ie), l = B(U);
  return B(Ke) ? /* @__PURE__ */ h.createElement(xe, i) : l ? t ? null : /* @__PURE__ */ h.createElement($e, {
    state: l,
    props: i,
    listBoxRef: e
  }) : /* @__PURE__ */ h.createElement(ft, {
    props: i,
    listBoxRef: e
  });
}
function ft({ props: i, listBoxRef: e }) {
  let { portal: t, collection: l } = Se(i);
  i = {
    ...i,
    collection: l,
    children: null,
    items: null
  };
  let o = nt(i);
  return /* @__PURE__ */ h.createElement(h.Fragment, null, t, /* @__PURE__ */ h.createElement($e, {
    state: o,
    props: i,
    listBoxRef: e
  }));
}
const It = /* @__PURE__ */ T(ut);
function $e({ state: i, props: e, listBoxRef: t }) {
  let { dragAndDropHooks: l, layout: o = "stack", orientation: r = "vertical" } = e, { collection: n, selectionManager: s } = i, a = !!(l != null && l.useDraggableCollectionState), d = !!(l != null && l.useDroppableCollectionState), { direction: c } = fe(), { disabledBehavior: g, disabledKeys: f } = s, u = he({
    usage: "search",
    sensitivity: "base"
  }), p = I(() => e.keyboardDelegate || new ge({
    collection: n,
    collator: u,
    ref: t,
    disabledKeys: f,
    disabledBehavior: g,
    layout: o,
    orientation: r,
    direction: c
  }), [
    n,
    u,
    t,
    g,
    f,
    r,
    c,
    e.keyboardDelegate,
    o
  ]), { listBoxProps: y } = Ue({
    ...e,
    shouldSelectOnPressUp: a || e.shouldSelectOnPressUp,
    keyboardDelegate: p
  }, i, t), b = ne({
    items: n,
    children: (S) => {
      switch (S.type) {
        case "section":
          return /* @__PURE__ */ h.createElement(ht, {
            section: S
          });
        case "separator":
          return /* @__PURE__ */ h.createElement(ct, S.props);
        case "item":
          return /* @__PURE__ */ h.createElement(ve, {
            item: S
          });
        default:
          throw new Error("Unsupported node type in Menu: " + S.type);
      }
    }
  }), v = D(a), P = D(d);
  R(() => {
    v.current !== a && console.warn("Drag hooks were provided during one render, but not another. This should be avoided as it may produce unexpected behavior."), P.current !== d && console.warn("Drop hooks were provided during one render, but not another. This should be avoided as it may produce unexpected behavior.");
  }, [
    a,
    d
  ]);
  let m, K, x, $ = !1, E = null, k = D(null);
  if (a && l) {
    m = l.useDraggableCollectionState({
      collection: n,
      selectionManager: s,
      preview: l.renderDragPreview ? k : void 0
    }), l.useDraggableCollection({}, m, t);
    let S = l.DragPreview;
    E = l.renderDragPreview ? /* @__PURE__ */ h.createElement(S, {
      ref: k
    }, l.renderDragPreview) : null;
  }
  if (d && l) {
    K = l.useDroppableCollectionState({
      collection: n,
      selectionManager: s
    });
    let S = l.dropTargetDelegate || new l.ListDropTargetDelegate(n, t, {
      orientation: r,
      layout: o,
      direction: c
    });
    x = l.useDroppableCollection({
      keyboardDelegate: p,
      dropTargetDelegate: S
    }, K, t), $ = K.isDropTarget({
      type: "root"
    });
  }
  let { focusProps: N, isFocused: A, isFocusVisible: H } = _e(), z = {
    isDropTarget: $,
    isEmpty: i.collection.size === 0,
    isFocused: A,
    isFocusVisible: H,
    layout: e.layout || "stack",
    state: i
  }, W = Y({
    className: e.className,
    style: e.style,
    defaultClassName: "react-aria-ListBox",
    values: z
  }), _ = null;
  return i.collection.size === 0 && e.renderEmptyState && (_ = /* @__PURE__ */ h.createElement("div", {
    // eslint-disable-next-line
    role: "option",
    style: {
      display: "contents"
    }
  }, e.renderEmptyState(z))), /* @__PURE__ */ h.createElement(ke, null, /* @__PURE__ */ h.createElement("div", {
    ...F(e),
    ...V(y, N, x == null ? void 0 : x.collectionProps),
    ...W,
    ref: t,
    slot: e.slot || void 0,
    onScroll: e.onScroll,
    "data-drop-target": $ || void 0,
    "data-empty": i.collection.size === 0 || void 0,
    "data-focused": A || void 0,
    "data-focus-visible": H || void 0,
    "data-layout": e.layout || "stack",
    "data-orientation": e.orientation || "vertical"
  }, /* @__PURE__ */ h.createElement(ae, {
    values: [
      [
        ye,
        e
      ],
      [
        U,
        i
      ],
      [
        J,
        {
          dragAndDropHooks: l,
          dragState: m,
          dropState: K
        }
      ],
      [
        be,
        {
          elementType: "div"
        }
      ],
      [
        pe,
        {
          render: bt
        }
      ]
    ]
  }, b), _, E));
}
function ht({ section: i, className: e, style: t }) {
  var l, o;
  let r = B(U), [n, s] = De();
  var a;
  let { headingProps: d, groupProps: c } = qe({
    heading: s,
    "aria-label": (a = i.props["aria-label"]) !== null && a !== void 0 ? a : void 0
  }), g = ne({
    items: r.collection.getChildren(i.key),
    children: (f) => {
      switch (f.type) {
        case "header":
          return /* @__PURE__ */ h.createElement(gt, {
            item: f,
            headingProps: d,
            headingRef: n
          });
        case "item":
          return /* @__PURE__ */ h.createElement(ve, {
            item: f
          });
        default:
          throw new Error("Unsupported element type in Section: " + f.type);
      }
    }
  });
  return /* @__PURE__ */ h.createElement("section", {
    ...F(i.props),
    ...c,
    className: e || ((l = i.props) === null || l === void 0 ? void 0 : l.className) || "react-aria-Section",
    style: t || ((o = i.props) === null || o === void 0 ? void 0 : o.style),
    ref: i.props.ref
  }, g);
}
function gt({ item: i, headingProps: e, headingRef: t }) {
  let { ref: l, ...o } = i.props;
  return /* @__PURE__ */ h.createElement(Ce, {
    ...e,
    ...o,
    ref: Be(t, l)
  }, i.rendered);
}
function pt(i, e) {
  return we("item", i, e, i.children);
}
const Dt = /* @__PURE__ */ T(pt);
function ve({ item: i }) {
  var e;
  let t = de(i.props.ref), l = B(U), { dragAndDropHooks: o, dragState: r, dropState: n } = B(J), { optionProps: s, labelProps: a, descriptionProps: d, ...c } = je({
    key: i.key,
    "aria-label": (e = i.props) === null || e === void 0 ? void 0 : e["aria-label"]
  }, l, t), { hoverProps: g, isHovered: f } = ue({
    isDisabled: !c.allowsSelection && !c.hasAction,
    onHoverStart: i.props.onHoverStart,
    onHoverChange: i.props.onHoverChange,
    onHoverEnd: i.props.onHoverEnd
  }), u = null;
  r && o && (u = o.useDraggableItem({
    key: i.key
  }, r));
  let p = null;
  n && o && (p = o.useDroppableItem({
    target: {
      type: "item",
      key: i.key,
      dropPosition: "on"
    }
  }, n, t));
  let y = i.props, b = r && r.isDragging(i.key), v = Y({
    ...y,
    id: void 0,
    children: i.rendered,
    defaultClassName: "react-aria-ListBoxItem",
    values: {
      ...c,
      isHovered: f,
      selectionMode: l.selectionManager.selectionMode,
      selectionBehavior: l.selectionManager.selectionBehavior,
      allowsDragging: !!r,
      isDragging: b,
      isDropTarget: p == null ? void 0 : p.isDropTarget
    }
  }), P = (o == null ? void 0 : o.renderDropIndicator) || ((K) => /* @__PURE__ */ h.createElement(at, {
    target: K
  }));
  R(() => {
    i.textValue || console.warn("A `textValue` prop is required for <ListBoxItem> elements with non-plain text children in order to support accessibility features such as type to select.");
  }, [
    i.textValue
  ]);
  let m = y.href ? "a" : "div";
  return /* @__PURE__ */ h.createElement(h.Fragment, null, (o == null ? void 0 : o.useDropIndicator) && P({
    type: "item",
    key: i.key,
    dropPosition: "before"
  }), /* @__PURE__ */ h.createElement(m, {
    ...V(s, g, u == null ? void 0 : u.dragProps, p == null ? void 0 : p.dropProps),
    ...v,
    ref: t,
    "data-allows-dragging": !!r || void 0,
    "data-selected": c.isSelected || void 0,
    "data-disabled": c.isDisabled || void 0,
    "data-hovered": f || void 0,
    "data-focused": c.isFocused || void 0,
    "data-focus-visible": c.isFocusVisible || void 0,
    "data-pressed": c.isPressed || void 0,
    "data-dragging": b || void 0,
    "data-drop-target": (p == null ? void 0 : p.isDropTarget) || void 0,
    "data-selection-mode": l.selectionManager.selectionMode === "none" ? void 0 : l.selectionManager.selectionMode
  }, /* @__PURE__ */ h.createElement(ae, {
    values: [
      [
        Ye,
        {
          slots: {
            label: a,
            description: d
          }
        }
      ]
    ]
  }, v.children)), (o == null ? void 0 : o.useDropIndicator) && l.collection.getKeyAfter(i.key) == null && P({
    type: "item",
    key: i.key,
    dropPosition: "after"
  }));
}
function bt(i, e) {
  e = de(e);
  let { dragAndDropHooks: t, dropState: l } = B(J), { dropIndicatorProps: o, isHidden: r, isDropTarget: n } = t.useDropIndicator(i, l, e);
  return r ? null : /* @__PURE__ */ h.createElement($t, {
    ...i,
    dropIndicatorProps: o,
    isDropTarget: n,
    ref: e
  });
}
function yt(i, e) {
  let { dropIndicatorProps: t, isDropTarget: l, ...o } = i, r = Y({
    ...o,
    defaultClassName: "react-aria-DropIndicator",
    values: {
      isDropTarget: l
    }
  });
  return /* @__PURE__ */ h.createElement("div", {
    ...t,
    ...r,
    // eslint-disable-next-line
    role: "option",
    ref: e,
    "data-drop-target": l || void 0
  });
}
const $t = /* @__PURE__ */ T(yt);
export {
  Dt as $,
  He as a,
  Oe as b,
  Pt as c,
  ge as d,
  nt as e,
  U as f,
  Ye as g,
  he as h,
  G as i,
  Ne as j,
  We as k,
  Le as l,
  lt as m,
  ye as n,
  It as o
};
