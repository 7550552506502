import { Container, Heading, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { cn } from '@carvertical/utils/styling';
import { Section } from 'components/landings/common/Section';
import { IdentifierForm } from 'components/common/IdentifierForm';
import { TrustBanner } from 'components/landings/common/TrustBanner';

type CompactCallToActionSectionProps = {
  title?: string;
  subtitle?: string;
  className?: string;
  textClassName?: string;
  trustBannerShown?: boolean;
};

const CompactCallToActionSection = ({
  title: titleFromProps,
  subtitle: subtitleFromProps,
  className,
  textClassName: textClassNameFromProps,
  trustBannerShown = true,
}: CompactCallToActionSectionProps) => {
  const { t } = useTranslation('home');
  const textClassName = cn('text-center', textClassNameFromProps);
  const title = titleFromProps || t('landing.historyReportTitle');
  const subtitle = subtitleFromProps || t('landing.ctaSubtitle');

  const renderTitle = () => (
    <div className="flex flex-col gap-2">
      <Heading as="h2" variant="m" className={textClassName} textColor="light">
        {title}
      </Heading>

      <Text as="p" variant="m" className={textClassName} textColor="light">
        {subtitle}
      </Text>
    </div>
  );

  const renderIdentifierForm = () => <IdentifierForm surroundingBackground="dark" />;

  const renderTrustBanner = () => trustBannerShown && <TrustBanner color="white" />;

  return (
    <>
      <Section className={cn('lg:hidden', className)} color="colorBlue">
        <Container>
          <div className="flex flex-col items-center gap-3">
            {renderTitle()}

            {renderIdentifierForm()}

            {renderTrustBanner()}
          </div>
        </Container>
      </Section>

      <section className={cn('hidden pb-15 pt-5 lg:flex', className)}>
        <Container className="w-full">
          <div className="grid grid-cols-2 gap-6 rounded-m bg-blue px-13 pb-5 pt-6">
            <div className="flex flex-col gap-4">
              {renderTitle()}

              {renderTrustBanner()}
            </div>

            <div className="mx-auto flex items-center">{renderIdentifierForm()}</div>
          </div>
        </Container>
      </section>
    </>
  );
};

export { CompactCallToActionSection };
