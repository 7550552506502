import { j as a } from "./jsx-runtime.82a9ddf6.js";
import { c as r } from "./clsx.0d472180.js";
import { forwardRef as f } from "react";
import { k as e } from "./styles.077839a8.js";
import { Text as x } from "../text.mjs";
const l = f(
  ({
    label: t,
    selectionIndicator: i,
    className: o,
    selectionBoxClassName: c,
    selectionBackgroundClassName: p,
    selectionIndicatorWrapperClassName: d,
    disabled: n,
    status: s,
    ...m
  }, u) => /* @__PURE__ */ a.jsxs(
    "label",
    {
      className: r(
        "flex items-center gap-2",
        e.root,
        n && e.disabled,
        o
      ),
      children: [
        /* @__PURE__ */ a.jsx(
          "input",
          {
            ref: u,
            disabled: n,
            className: r(e.input, s && e[s]),
            "aria-invalid": s === "error" ? !0 : void 0,
            ...m
          }
        ),
        /* @__PURE__ */ a.jsxs("span", { className: r(e.selectionBox, c), children: [
          /* @__PURE__ */ a.jsx("span", { className: r(e.selectionBackground, p) }),
          /* @__PURE__ */ a.jsx(
            "span",
            {
              className: r(e.selectionIndicatorWrapper, d),
              children: i
            }
          )
        ] }),
        /* @__PURE__ */ a.jsx(x, { variant: "s", as: "span", children: t })
      ]
    }
  )
);
try {
  l.displayName = "OptionBase", l.__docgenInfo = { description: "", displayName: "OptionBase", props: { type: { defaultValue: null, description: "", name: "type", required: !0, type: { name: '"checkbox" | "radio"' } }, selectionIndicator: { defaultValue: null, description: "", name: "selectionIndicator", required: !0, type: { name: "ReactNode" } }, selectionBoxClassName: { defaultValue: null, description: "", name: "selectionBoxClassName", required: !1, type: { name: "string" } }, selectionBackgroundClassName: { defaultValue: null, description: "", name: "selectionBackgroundClassName", required: !1, type: { name: "string" } }, selectionIndicatorWrapperClassName: { defaultValue: null, description: "", name: "selectionIndicatorWrapperClassName", required: !1, type: { name: "string" } }, label: { defaultValue: null, description: "", name: "label", required: !0, type: { name: "ReactNode" } }, status: { defaultValue: null, description: "", name: "status", required: !1, type: { name: '"error"' } } } };
} catch {
}
export {
  l as O
};
