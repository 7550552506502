import { j as i } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as r } from "./chunks/cn.2ea4f521.js";
import { T as s } from "./chunks/TypographyElement.0309f27c.js";
import "react";
const t = ({ variant: e, className: a, children: l, ...n }) => /* @__PURE__ */ i.jsx(
  s,
  {
    ...n,
    className: r(
      e === "2xl" && "text-h-2xl",
      e === "xl" && "text-h-xl",
      e === "l" && "text-h-l",
      e === "m" && "text-h-m",
      e === "s" && "text-h-s",
      e === "xs" && "text-h-xs",
      a
    ),
    children: l
  }
);
try {
  t.displayName = "Heading", t.__docgenInfo = { description: "", displayName: "Heading", props: { as: { defaultValue: null, description: 'This prop controls what HTML tag to render (**it does not change styling**).\nExample: set `variant="h2"` and `as="span"`, which will render `<span>` element with H2 styles.', name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, align: { defaultValue: null, description: "", name: "align", required: !1, type: { name: '"center" | "left" | "right" | "justify"' } }, textColor: { defaultValue: null, description: "", name: "textColor", required: !1, type: { name: '"dark" | "light" | "darkSecondary" | "highlighted" | "inherited"' } }, italic: { defaultValue: null, description: "", name: "italic", required: !1, type: { name: "boolean" } }, inline: { defaultValue: null, description: "", name: "inline", required: !1, type: { name: "boolean" } }, variant: { defaultValue: null, description: "", name: "variant", required: !0, type: { name: "HeadingVariant" } } } };
} catch {
}
export {
  t as Heading
};
