import { j as a } from "./chunks/jsx-runtime.82a9ddf6.js";
import { $ as d, a as u } from "./chunks/TagGroup.e4e7e2ef.js";
import { c as f } from "./chunks/cn.2ea4f521.js";
import { forwardRef as c } from "react";
import { T as y } from "./chunks/TagGroupContext.b8226d23.js";
import "./chunks/index.1f4a6407.js";
import "./chunks/utils.371a0d8b.js";
import "./chunks/clsx.0d472180.js";
import "react-dom";
import "./chunks/useFocusRing.91eeed6d.js";
import "./chunks/useFocusable.59f06832.js";
import "./chunks/Collection.e8e46ab5.js";
import "./chunks/Label.afa424d9.js";
import "./chunks/useSelectableItem.ca3ec579.js";
import "./chunks/ListBox.ba6e4e6c.js";
import "./chunks/Header.de56351a.js";
import "./chunks/useHasTabbableChild.8b7adf4c.js";
const l = c(
  ({
    children: r,
    size: e = "m",
    variant: t = "outline",
    selectionMode: i = "single",
    className: o,
    onChange: n,
    listClassName: s,
    ...m
  }, p) => /* @__PURE__ */ a.jsx(y, { value: { size: e, variant: t }, children: /* @__PURE__ */ a.jsx(
    d,
    {
      ...m,
      className: o,
      selectionMode: i,
      onSelectionChange: n,
      children: /* @__PURE__ */ a.jsx(
        u,
        {
          ref: p,
          className: f(
            "flex flex-wrap",
            {
              "gap-0.75": e === "m",
              "gap-1": e === "l"
            },
            s
          ),
          children: r
        }
      )
    }
  ) })
);
try {
  l.displayName = "TagGroup", l.__docgenInfo = { description: "", displayName: "TagGroup", props: { className: { defaultValue: null, description: "", name: "className", required: !1, type: { name: "string" } }, listClassName: { defaultValue: null, description: "", name: "listClassName", required: !1, type: { name: "string" } }, size: { defaultValue: { value: "m" }, description: "", name: "size", required: !1, type: { name: "TagSize" } }, variant: { defaultValue: { value: "outline" }, description: "", name: "variant", required: !1, type: { name: "TagVariant" } }, selectionMode: { defaultValue: { value: "single" }, description: "", name: "selectionMode", required: !1, type: { name: "SelectionMode" } }, selectedKeys: { defaultValue: null, description: "", name: "selectedKeys", required: !1, type: { name: '"all" | Iterable<Key>' } }, defaultSelectedKeys: { defaultValue: null, description: "", name: "defaultSelectedKeys", required: !1, type: { name: '"all" | Iterable<Key>' } }, onChange: { defaultValue: null, description: "", name: "onChange", required: !1, type: { name: "(keys: Selection) => void" } }, onRemove: { defaultValue: null, description: "", name: "onRemove", required: !1, type: { name: "(keys: Set<Key>) => void" } } } };
} catch {
}
export {
  l as TagGroup
};
