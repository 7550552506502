import { useTranslation } from 'next-i18next';
import Page from 'components/common/Page';
import { ContentFaqSection } from 'components/common/sections';
import { MarketSection } from '../common/MarketSection';
import { AffiliateHero } from './AffiliateHero';
import { AffiliateFeaturesSection } from './AffiliateFeaturesSection';
import { AffiliateTrustBanner } from './AffiliateTrustBanner';
import { AffiliateCtaSection } from './AffiliateCtaSection';
import { OverviewSection } from './OverviewSection';
import { AffiliateStepsSection } from './AffiliateSteps';

const AffiliatePage = () => {
  const { t } = useTranslation('affiliate');

  return (
    <Page>
      <AffiliateHero />

      <AffiliateTrustBanner />

      <AffiliateStepsSection />

      <OverviewSection />

      <AffiliateFeaturesSection />

      <AffiliateCtaSection />

      <MarketSection />

      <ContentFaqSection title={t('faqSection.title')} sectionVariant="l" />
    </Page>
  );
};

export { AffiliatePage };
