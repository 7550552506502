import { useTranslation } from 'next-i18next';
import { Container, Grid, Heading, Stack, Text } from '@carvertical/ui';
import { type Feature, FEATURES as BASE_FEATURES } from 'constants/features';
import { format } from 'utils/money';
import { useRouteData } from 'context/RouteDataProvider';
import ArtDirectedImage from 'components/common/ArtDirectedImage';
import { FeatureCheckList } from 'components/common/FeatureCheckList';
import mobileImageUrl from './assets/images/car-report-mobile.png';
import desktopImageUrl from './assets/images/car-report-desktop.png';
import styles from './DisclosureSection.module.scss';

const FEATURES: Feature[] = [...BASE_FEATURES, { id: 'other', titleKey: 'common:features.other' }];

const VIN = '1VWBN7A35CC••••••';
const DAMAGE_COST = 12_000;

const DisclosureSection = () => {
  const { market } = useRouteData();
  const { t } = useTranslation(['pricing', 'home', 'common']);

  return (
    <section className={styles.root}>
      <Container>
        <Grid rowGap={6} columnCount={{ mobileUp: 1, desktopUp: 12, desktopBigUp: 10 }}>
          <Stack gap={{ mobileUp: 3, desktopUp: 4 }} className={styles.content}>
            <Stack>
              <Heading as="h2" variant="l" className={styles.title}>
                {t('home:checksSection.title')}
              </Heading>
              <Text variant="l">{t('pricing:disclosureSection.subtitle')}</Text>
            </Stack>

            <FeatureCheckList features={FEATURES} />
          </Stack>

          <Stack gap={0} as="figure" crossAxisAlign="center" className={styles.figure}>
            <div className={styles.imageWrapper}>
              <ArtDirectedImage
                src={mobileImageUrl}
                sizes="100vw"
                height={340}
                width={368}
                className={styles.image}
                alt=""
                quality={100}
                // @ts-expect-error TS(2739)
                srcSet={{
                  forDesktopUp: {
                    src: desktopImageUrl,
                    height: 480,
                    width: 488,
                    sizes: '50vw',
                  },
                }}
              />
              <Text variant="m+" className={styles.price}>
                {format(DAMAGE_COST, market.currency)}
              </Text>
              <Text className={styles.vin} variant="2xs">
                {t('common:general.vinShortLabel')}:{VIN}
              </Text>
            </div>
          </Stack>
        </Grid>
      </Container>
    </section>
  );
};

export { DisclosureSection };
