import { useRouteData } from 'context/RouteDataProvider';
import { PortableTextRenderer } from 'modules/content/components/common/PortableTextRenderer';
import { FaqSection, type FaqSectionProps } from './FaqSection';

const ContentFaqSection = (props: FaqSectionProps) => {
  const { faq } = useRouteData();

  const faqItems = faq?.[0]?.questions.map((question) => ({
    id: question.slug,
    headingLevel: 'h3' as const,
    buttonNode: question.question,
    contentNode: <PortableTextRenderer value={question.answer} />,
  }));

  if (!faqItems?.length) {
    return null;
  }

  return <FaqSection {...props} items={faqItems} />;
};

export { ContentFaqSection };
