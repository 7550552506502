import { b as u, e as g, $ as y, c as E } from "./utils.371a0d8b.js";
import { c as T, d as p, i as k, h as w } from "./useFocusRing.91eeed6d.js";
import H, { createContext as O } from "react";
import { $ as S } from "./useFocusable.59f06832.js";
function V(e, d) {
  let {
    elementType: a = "button",
    isDisabled: s,
    onPress: t,
    onPressStart: i,
    onPressEnd: r,
    onPressUp: n,
    onPressChange: c,
    preventFocusOnPress: o,
    allowFocusWhenDisabled: f,
    // @ts-ignore
    onClick: $,
    href: m,
    target: x,
    rel: h,
    type: P = "button"
  } = e, l;
  a === "button" ? l = {
    type: P,
    disabled: s
  } : l = {
    role: "button",
    tabIndex: s ? void 0 : 0,
    href: a === "a" && s ? void 0 : m,
    target: a === "a" ? x : void 0,
    type: a === "input" ? P : void 0,
    disabled: a === "input" ? s : void 0,
    "aria-disabled": !s || a === "input" ? void 0 : s,
    rel: a === "a" ? h : void 0
  };
  let { pressProps: v, isPressed: F } = T({
    onPressStart: i,
    onPressEnd: r,
    onPressChange: c,
    onPress: t,
    onPressUp: n,
    isDisabled: s,
    preventFocusOnPress: o,
    ref: d
  }), { focusableProps: b } = S(e, d);
  f && (b.tabIndex = s ? -1 : b.tabIndex);
  let C = u(b, v, p(e, {
    labelable: !0
  }));
  return {
    isPressed: F,
    buttonProps: u(l, C, {
      "aria-haspopup": e["aria-haspopup"],
      "aria-expanded": e["aria-expanded"],
      "aria-controls": e["aria-controls"],
      "aria-pressed": e["aria-pressed"],
      onClick: (D) => {
        $ && ($(D), console.warn("onClick is deprecated, please use onPress"));
      }
    })
  };
}
const B = /* @__PURE__ */ new Set([
  "form",
  "formAction",
  "formEncType",
  "formMethod",
  "formNoValidate",
  "formTarget",
  "name",
  "value"
]), I = /* @__PURE__ */ O({});
function N(e, d) {
  [e, d] = y(e, d, I);
  let a = e, { buttonProps: s, isPressed: t } = V(e, d), { focusProps: i, isFocused: r, isFocusVisible: n } = k(e), { hoverProps: c, isHovered: o } = w(e), f = E({
    ...e,
    values: {
      isHovered: o,
      isPressed: t,
      isFocused: r,
      isFocusVisible: n,
      isDisabled: e.isDisabled || !1
    },
    defaultClassName: "react-aria-Button"
  });
  return /* @__PURE__ */ H.createElement("button", {
    ...p(e, {
      propNames: B
    }),
    ...u(s, i, c),
    ...f,
    ref: d,
    slot: e.slot || void 0,
    "data-disabled": e.isDisabled || void 0,
    "data-pressed": a.isPressed || t || void 0,
    "data-hovered": o || void 0,
    "data-focused": r || void 0,
    "data-focus-visible": n || void 0
  });
}
const _ = /* @__PURE__ */ g(N);
export {
  _ as $,
  I as a
};
