import { g as Pe, a as P, h as Le, b as ce, f as Fe } from "./utils.371a0d8b.js";
import q, { useState as ge, useEffect as z, useRef as S, useContext as Te, useMemo as ne } from "react";
import { flushSync as ke } from "react-dom";
import { j as De, c as Se, k as Ae, l as L, a as V, m as Ce, n as Me, e as Ee, o as de, p as pe, q as Re } from "./useFocusRing.91eeed6d.js";
function bt(t, e) {
  let { id: r, "aria-label": n, "aria-labelledby": o } = t;
  return r = Pe(r), o && n ? o = [
    .../* @__PURE__ */ new Set([
      r,
      ...o.trim().split(/\s+/)
    ])
  ].join(" ") : o && (o = o.trim().split(/\s+/).join(" ")), !n && !o && e && (n = e), {
    id: r,
    "aria-label": n,
    "aria-labelledby": o
  };
}
function Ie(t, e) {
  let r = window.getComputedStyle(t), n = /(auto|scroll)/.test(r.overflow + r.overflowX + r.overflowY);
  return n && e && (n = t.scrollHeight !== t.clientHeight || t.scrollWidth !== t.clientWidth), n;
}
function Oe(t, e) {
  const r = [];
  for (; t && t !== document.documentElement; )
    Ie(t, e) && r.push(t), t = t.parentElement;
  return r;
}
let Be = 0;
const oe = /* @__PURE__ */ new Map();
function Ue(t) {
  let [e, r] = ge();
  return P(() => {
    if (!t)
      return;
    let n = oe.get(t);
    if (n)
      r(n.element.id);
    else {
      let o = `react-aria-description-${Be++}`;
      r(o);
      let l = document.createElement("div");
      l.id = o, l.style.display = "none", l.textContent = t, document.body.appendChild(l), n = {
        refCount: 0,
        element: l
      }, oe.set(t, n);
    }
    return n.refCount++, () => {
      n && --n.refCount === 0 && (n.element.remove(), oe.delete(t));
    };
  }, [
    t
  ]), {
    "aria-describedby": t ? e : void 0
  };
}
function We(t, e, r, n) {
  let o = Le(r), l = r == null;
  z(() => {
    if (l || !t.current)
      return;
    let a = t.current;
    return a.addEventListener(e, o, n), () => {
      a.removeEventListener(e, o, n);
    };
  }, [
    t,
    e,
    n,
    l,
    o
  ]);
}
function Ke(t, e) {
  let r = be(t, e, "left"), n = be(t, e, "top"), o = e.offsetWidth, l = e.offsetHeight, a = t.scrollLeft, i = t.scrollTop, { borderTopWidth: b, borderLeftWidth: c } = getComputedStyle(t), u = t.scrollLeft + parseInt(c, 10), v = t.scrollTop + parseInt(b, 10), d = u + t.clientWidth, p = v + t.clientHeight;
  r <= a ? a = r - parseInt(c, 10) : r + o > d && (a += r + o - d), n <= v ? i = n - parseInt(b, 10) : n + l > p && (i += n + l - p), t.scrollLeft = a, t.scrollTop = i;
}
function be(t, e, r) {
  const n = r === "left" ? "offsetLeft" : "offsetTop";
  let o = 0;
  for (; e.offsetParent && (o += e[n], e.offsetParent !== t); ) {
    if (e.offsetParent.contains(t)) {
      o -= t[n];
      break;
    }
    e = e.offsetParent;
  }
  return o;
}
function ve(t, e) {
  if (document.contains(t)) {
    let a = document.scrollingElement || document.documentElement;
    if (window.getComputedStyle(a).overflow === "hidden") {
      let b = Oe(t);
      for (let c of b)
        Ke(c, t);
    } else {
      var r;
      let { left: b, top: c } = t.getBoundingClientRect();
      t == null || (r = t.scrollIntoView) === null || r === void 0 || r.call(t, {
        block: "nearest"
      });
      let { left: u, top: v } = t.getBoundingClientRect();
      if (Math.abs(b - u) > 1 || Math.abs(c - v) > 1) {
        var n, o, l;
        e == null || (o = e.containingElement) === null || o === void 0 || (n = o.scrollIntoView) === null || n === void 0 || n.call(o, {
          block: "center",
          inline: "center"
        }), (l = t.scrollIntoView) === null || l === void 0 || l.call(t, {
          block: "nearest"
        });
      }
    }
  }
}
const ze = 500;
function He(t) {
  let { isDisabled: e, onLongPressStart: r, onLongPressEnd: n, onLongPress: o, threshold: l = ze, accessibilityDescription: a } = t;
  const i = S();
  let { addGlobalListener: b, removeGlobalListener: c } = De(), { pressProps: u } = Se({
    isDisabled: e,
    onPressStart(d) {
      if (d.continuePropagation(), (d.pointerType === "mouse" || d.pointerType === "touch") && (r && r({
        ...d,
        type: "longpressstart"
      }), i.current = setTimeout(() => {
        d.target.dispatchEvent(new PointerEvent("pointercancel", {
          bubbles: !0
        })), o && o({
          ...d,
          type: "longpress"
        }), i.current = void 0;
      }, l), d.pointerType === "touch")) {
        let p = (h) => {
          h.preventDefault();
        };
        b(d.target, "contextmenu", p, {
          once: !0
        }), b(window, "pointerup", () => {
          setTimeout(() => {
            c(d.target, "contextmenu", p);
          }, 30);
        }, {
          once: !0
        });
      }
    },
    onPressEnd(d) {
      i.current && clearTimeout(i.current), n && (d.pointerType === "mouse" || d.pointerType === "touch") && n({
        ...d,
        type: "longpressend"
      });
    }
  }), v = Ue(o && !e ? a : void 0);
  return {
    longPressProps: ce(u, v)
  };
}
function je(t) {
  const e = Ae(t);
  if (!(t instanceof e.HTMLElement) && !(t instanceof e.SVGElement))
    return !1;
  let { display: r, visibility: n } = t.style, o = r !== "none" && n !== "hidden" && n !== "collapse";
  if (o) {
    const { getComputedStyle: l } = t.ownerDocument.defaultView;
    let { display: a, visibility: i } = l(t);
    o = a !== "none" && i !== "hidden" && i !== "collapse";
  }
  return o;
}
function qe(t, e) {
  return !t.hasAttribute("hidden") && // Ignore HiddenSelect when tree walking.
  !t.hasAttribute("data-react-aria-prevent-focus") && (t.nodeName === "DETAILS" && e && e.nodeName !== "SUMMARY" ? t.hasAttribute("open") : !0);
}
function we(t, e) {
  return t.nodeName !== "#comment" && je(t) && qe(t, e) && (!t.parentElement || we(t.parentElement, t));
}
const $e = /* @__PURE__ */ q.createContext(null);
let y = null;
function vt(t) {
  let { children: e, contain: r, restoreFocus: n, autoFocus: o } = t, l = S(null), a = S(null), i = S([]), { parentNode: b } = Te($e) || {}, c = ne(() => new le({
    scopeRef: i
  }), [
    i
  ]);
  P(() => {
    let d = b || m.root;
    if (m.getTreeNode(d.scopeRef) && y && !ee(y, d.scopeRef)) {
      let p = m.getTreeNode(y);
      p && (d = p);
    }
    d.addChild(c), m.addNode(c);
  }, [
    c,
    b
  ]), P(() => {
    let d = m.getTreeNode(i);
    d && (d.contain = !!r);
  }, [
    r
  ]), P(() => {
    var d;
    let p = (d = l.current) === null || d === void 0 ? void 0 : d.nextSibling, h = [];
    for (; p && p !== a.current; )
      h.push(p), p = p.nextSibling;
    i.current = h;
  }, [
    e
  ]), Qe(i, n, r), Je(i, r), _e(i, n, r), Ze(i, o), z(() => {
    const d = L(i.current ? i.current[0] : void 0).activeElement;
    let p = null;
    if (T(d, i.current)) {
      for (let h of m.traverse())
        h.scopeRef && T(d, h.scopeRef.current) && (p = h);
      p === m.getTreeNode(i) && (y = p.scopeRef);
    }
  }, [
    i
  ]), P(() => () => {
    var d, p, h;
    let x = (h = (p = m.getTreeNode(i)) === null || p === void 0 || (d = p.parent) === null || d === void 0 ? void 0 : d.scopeRef) !== null && h !== void 0 ? h : null;
    (i === y || ee(i, y)) && (!x || m.getTreeNode(x)) && (y = x), m.removeTreeNode(i);
  }, [
    i
  ]);
  let u = ne(() => Ge(i), []), v = ne(() => ({
    focusManager: u,
    parentNode: c
  }), [
    c,
    u
  ]);
  return /* @__PURE__ */ q.createElement($e.Provider, {
    value: v
  }, /* @__PURE__ */ q.createElement("span", {
    "data-focus-scope-start": !0,
    hidden: !0,
    ref: l
  }), e, /* @__PURE__ */ q.createElement("span", {
    "data-focus-scope-end": !0,
    hidden: !0,
    ref: a
  }));
}
function Ge(t) {
  return {
    focusNext(e = {}) {
      let r = t.current, { from: n, tabbable: o, wrap: l, accept: a } = e, i = n || L(r[0]).activeElement, b = r[0].previousElementSibling, c = W(r), u = C(c, {
        tabbable: o,
        accept: a
      }, r);
      u.currentNode = T(i, r) ? i : b;
      let v = u.nextNode();
      return !v && l && (u.currentNode = b, v = u.nextNode()), v && A(v, !0), v;
    },
    focusPrevious(e = {}) {
      let r = t.current, { from: n, tabbable: o, wrap: l, accept: a } = e, i = n || L(r[0]).activeElement, b = r[r.length - 1].nextElementSibling, c = W(r), u = C(c, {
        tabbable: o,
        accept: a
      }, r);
      u.currentNode = T(i, r) ? i : b;
      let v = u.previousNode();
      return !v && l && (u.currentNode = b, v = u.previousNode()), v && A(v, !0), v;
    },
    focusFirst(e = {}) {
      let r = t.current, { tabbable: n, accept: o } = e, l = W(r), a = C(l, {
        tabbable: n,
        accept: o
      }, r);
      a.currentNode = r[0].previousElementSibling;
      let i = a.nextNode();
      return i && A(i, !0), i;
    },
    focusLast(e = {}) {
      let r = t.current, { tabbable: n, accept: o } = e, l = W(r), a = C(l, {
        tabbable: n,
        accept: o
      }, r);
      a.currentNode = r[r.length - 1].nextElementSibling;
      let i = a.previousNode();
      return i && A(i, !0), i;
    }
  };
}
const se = [
  "input:not([disabled]):not([type=hidden])",
  "select:not([disabled])",
  "textarea:not([disabled])",
  "button:not([disabled])",
  "a[href]",
  "area[href]",
  "summary",
  "iframe",
  "object",
  "embed",
  "audio[controls]",
  "video[controls]",
  "[contenteditable]"
], Ye = se.join(":not([hidden]),") + ",[tabindex]:not([disabled]):not([hidden])";
se.push('[tabindex]:not([tabindex="-1"]):not([disabled])');
const Xe = se.join(':not([hidden]):not([tabindex="-1"]),');
function W(t) {
  return t[0].parentElement;
}
function J(t) {
  let e = m.getTreeNode(y);
  for (; e && e.scopeRef !== t; ) {
    if (e.contain)
      return !1;
    e = e.parent;
  }
  return !0;
}
function Je(t, e) {
  let r = S(), n = S();
  P(() => {
    let o = t.current;
    if (!e) {
      n.current && (cancelAnimationFrame(n.current), n.current = void 0);
      return;
    }
    const l = L(o ? o[0] : void 0);
    let a = (c) => {
      if (c.key !== "Tab" || c.altKey || c.ctrlKey || c.metaKey || !J(t) || c.isComposing)
        return;
      let u = l.activeElement, v = t.current;
      if (!v || !T(u, v))
        return;
      let d = W(v), p = C(d, {
        tabbable: !0
      }, v);
      if (!u)
        return;
      p.currentNode = u;
      let h = c.shiftKey ? p.previousNode() : p.nextNode();
      h || (p.currentNode = c.shiftKey ? v[v.length - 1].nextElementSibling : v[0].previousElementSibling, h = c.shiftKey ? p.previousNode() : p.nextNode()), c.preventDefault(), h && A(h, !0);
    }, i = (c) => {
      (!y || ee(y, t)) && T(c.target, t.current) ? (y = t, r.current = c.target) : J(t) && !_(c.target, t) ? r.current ? r.current.focus() : y && y.current && te(y.current) : J(t) && (r.current = c.target);
    }, b = (c) => {
      n.current && cancelAnimationFrame(n.current), n.current = requestAnimationFrame(() => {
        if (l.activeElement && J(t) && !_(l.activeElement, t))
          if (y = t, l.body.contains(c.target)) {
            var u;
            r.current = c.target, (u = r.current) === null || u === void 0 || u.focus();
          } else
            y.current && te(y.current);
      });
    };
    return l.addEventListener("keydown", a, !1), l.addEventListener("focusin", i, !1), o == null || o.forEach((c) => c.addEventListener("focusin", i, !1)), o == null || o.forEach((c) => c.addEventListener("focusout", b, !1)), () => {
      l.removeEventListener("keydown", a, !1), l.removeEventListener("focusin", i, !1), o == null || o.forEach((c) => c.removeEventListener("focusin", i, !1)), o == null || o.forEach((c) => c.removeEventListener("focusout", b, !1));
    };
  }, [
    t,
    e
  ]), P(() => () => {
    n.current && cancelAnimationFrame(n.current);
  }, [
    n
  ]);
}
function xe(t) {
  return _(t);
}
function T(t, e) {
  return !t || !e ? !1 : e.some((r) => r.contains(t));
}
function _(t, e = null) {
  if (t instanceof Element && t.closest("[data-react-aria-top-layer]"))
    return !0;
  for (let { scopeRef: r } of m.traverse(m.getTreeNode(e)))
    if (r && T(t, r.current))
      return !0;
  return !1;
}
function $t(t) {
  return _(t, y);
}
function ee(t, e) {
  var r;
  let n = (r = m.getTreeNode(e)) === null || r === void 0 ? void 0 : r.parent;
  for (; n; ) {
    if (n.scopeRef === t)
      return !0;
    n = n.parent;
  }
  return !1;
}
function A(t, e = !1) {
  if (t != null && !e)
    try {
      V(t);
    } catch {
    }
  else if (t != null)
    try {
      t.focus();
    } catch {
    }
}
function te(t, e = !0) {
  let r = t[0].previousElementSibling, n = W(t), o = C(n, {
    tabbable: e
  }, t);
  o.currentNode = r;
  let l = o.nextNode();
  e && !l && (n = W(t), o = C(n, {
    tabbable: !1
  }, t), o.currentNode = r, l = o.nextNode()), A(l);
}
function Ze(t, e) {
  const r = q.useRef(e);
  z(() => {
    if (r.current) {
      y = t;
      const n = L(t.current ? t.current[0] : void 0);
      !T(n.activeElement, y.current) && t.current && te(t.current);
    }
    r.current = !1;
  }, [
    t
  ]);
}
function Qe(t, e, r) {
  P(() => {
    if (e || r)
      return;
    let n = t.current;
    const o = L(n ? n[0] : void 0);
    let l = (a) => {
      let i = a.target;
      T(i, t.current) ? y = t : xe(i) || (y = null);
    };
    return o.addEventListener("focusin", l, !1), n == null || n.forEach((a) => a.addEventListener("focusin", l, !1)), () => {
      o.removeEventListener("focusin", l, !1), n == null || n.forEach((a) => a.removeEventListener("focusin", l, !1));
    };
  }, [
    t,
    e,
    r
  ]);
}
function Ve(t) {
  let e = m.getTreeNode(y);
  for (; e && e.scopeRef !== t; ) {
    if (e.nodeToRestore)
      return !1;
    e = e.parent;
  }
  return (e == null ? void 0 : e.scopeRef) === t;
}
function _e(t, e, r) {
  const n = S(typeof document < "u" ? L(t.current ? t.current[0] : void 0).activeElement : null);
  P(() => {
    let o = t.current;
    const l = L(o ? o[0] : void 0);
    if (!e || r)
      return;
    let a = () => {
      (!y || ee(y, t)) && T(l.activeElement, t.current) && (y = t);
    };
    return l.addEventListener("focusin", a, !1), o == null || o.forEach((i) => i.addEventListener("focusin", a, !1)), () => {
      l.removeEventListener("focusin", a, !1), o == null || o.forEach((i) => i.removeEventListener("focusin", a, !1));
    };
  }, [
    t,
    r
  ]), P(() => {
    const o = L(t.current ? t.current[0] : void 0);
    if (!e)
      return;
    let l = (a) => {
      if (a.key !== "Tab" || a.altKey || a.ctrlKey || a.metaKey || !J(t) || a.isComposing)
        return;
      let i = o.activeElement;
      if (!T(i, t.current))
        return;
      let b = m.getTreeNode(t);
      if (!b)
        return;
      let c = b.nodeToRestore, u = C(o.body, {
        tabbable: !0
      });
      u.currentNode = i;
      let v = a.shiftKey ? u.previousNode() : u.nextNode();
      if ((!c || !o.body.contains(c) || c === o.body) && (c = void 0, b.nodeToRestore = void 0), (!v || !T(v, t.current)) && c) {
        u.currentNode = c;
        do
          v = a.shiftKey ? u.previousNode() : u.nextNode();
        while (T(v, t.current));
        a.preventDefault(), a.stopPropagation(), v ? A(v, !0) : xe(c) ? A(c, !0) : i.blur();
      }
    };
    return r || o.addEventListener("keydown", l, !0), () => {
      r || o.removeEventListener("keydown", l, !0);
    };
  }, [
    t,
    e,
    r
  ]), P(() => {
    const o = L(t.current ? t.current[0] : void 0);
    if (!e)
      return;
    let l = m.getTreeNode(t);
    if (l) {
      var a;
      return l.nodeToRestore = (a = n.current) !== null && a !== void 0 ? a : void 0, () => {
        let i = m.getTreeNode(t);
        if (!i)
          return;
        let b = i.nodeToRestore;
        if (e && b && // eslint-disable-next-line react-hooks/exhaustive-deps
        (T(o.activeElement, t.current) || o.activeElement === o.body && Ve(t))) {
          let c = m.clone();
          requestAnimationFrame(() => {
            if (o.activeElement === o.body) {
              let u = c.getTreeNode(t);
              for (; u; ) {
                if (u.nodeToRestore && u.nodeToRestore.isConnected) {
                  A(u.nodeToRestore);
                  return;
                }
                u = u.parent;
              }
              for (u = c.getTreeNode(t); u; ) {
                if (u.scopeRef && u.scopeRef.current && m.getTreeNode(u.scopeRef)) {
                  te(u.scopeRef.current, !0);
                  return;
                }
                u = u.parent;
              }
            }
          });
        }
      };
    }
  }, [
    t,
    e
  ]);
}
function C(t, e, r) {
  let n = e != null && e.tabbable ? Xe : Ye, o = L(t).createTreeWalker(t, NodeFilter.SHOW_ELEMENT, {
    acceptNode(l) {
      var a;
      return !(e == null || (a = e.from) === null || a === void 0) && a.contains(l) ? NodeFilter.FILTER_REJECT : l.matches(n) && we(l) && (!r || T(l, r)) && (!(e != null && e.accept) || e.accept(l)) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  return e != null && e.from && (o.currentNode = e.from), o;
}
class ue {
  get size() {
    return this.fastMap.size;
  }
  getTreeNode(e) {
    return this.fastMap.get(e);
  }
  addTreeNode(e, r, n) {
    let o = this.fastMap.get(r ?? null);
    if (!o)
      return;
    let l = new le({
      scopeRef: e
    });
    o.addChild(l), l.parent = o, this.fastMap.set(e, l), n && (l.nodeToRestore = n);
  }
  addNode(e) {
    this.fastMap.set(e.scopeRef, e);
  }
  removeTreeNode(e) {
    if (e === null)
      return;
    let r = this.fastMap.get(e);
    if (!r)
      return;
    let n = r.parent;
    for (let l of this.traverse())
      l !== r && r.nodeToRestore && l.nodeToRestore && r.scopeRef && r.scopeRef.current && T(l.nodeToRestore, r.scopeRef.current) && (l.nodeToRestore = r.nodeToRestore);
    let o = r.children;
    n && (n.removeChild(r), o.size > 0 && o.forEach((l) => n && n.addChild(l))), this.fastMap.delete(r.scopeRef);
  }
  // Pre Order Depth First
  *traverse(e = this.root) {
    if (e.scopeRef != null && (yield e), e.children.size > 0)
      for (let r of e.children)
        yield* this.traverse(r);
  }
  clone() {
    var e;
    let r = new ue();
    var n;
    for (let o of this.traverse())
      r.addTreeNode(o.scopeRef, (n = (e = o.parent) === null || e === void 0 ? void 0 : e.scopeRef) !== null && n !== void 0 ? n : null, o.nodeToRestore);
    return r;
  }
  constructor() {
    this.fastMap = /* @__PURE__ */ new Map(), this.root = new le({
      scopeRef: null
    }), this.fastMap.set(null, this.root);
  }
}
class le {
  addChild(e) {
    this.children.add(e), e.parent = this;
  }
  removeChild(e) {
    this.children.delete(e), e.parent = void 0;
  }
  constructor(e) {
    this.children = /* @__PURE__ */ new Set(), this.contain = !1, this.scopeRef = e.scopeRef;
  }
}
let m = new ue();
const et = /* @__PURE__ */ new Set([
  "Arab",
  "Syrc",
  "Samr",
  "Mand",
  "Thaa",
  "Mend",
  "Nkoo",
  "Adlm",
  "Rohg",
  "Hebr"
]), tt = /* @__PURE__ */ new Set([
  "ae",
  "ar",
  "arc",
  "bcc",
  "bqi",
  "ckb",
  "dv",
  "fa",
  "glk",
  "he",
  "ku",
  "mzn",
  "nqo",
  "pnb",
  "ps",
  "sd",
  "ug",
  "ur",
  "yi"
]);
function rt(t) {
  if (Intl.Locale) {
    let r = new Intl.Locale(t).maximize(), n = typeof r.getTextInfo == "function" ? r.getTextInfo() : r.textInfo;
    if (n)
      return n.direction === "rtl";
    if (r.script)
      return et.has(r.script);
  }
  let e = t.split("-")[0];
  return tt.has(e);
}
const nt = Symbol.for("react-aria.i18n.locale");
function Ne() {
  let t = typeof window < "u" && window[nt] || typeof navigator < "u" && (navigator.language || navigator.userLanguage) || "en-US";
  try {
    Intl.DateTimeFormat.supportedLocalesOf([
      t
    ]);
  } catch {
    t = "en-US";
  }
  return {
    locale: t,
    direction: rt(t) ? "rtl" : "ltr"
  };
}
let ie = Ne(), Z = /* @__PURE__ */ new Set();
function ye() {
  ie = Ne();
  for (let t of Z)
    t(ie);
}
function ot() {
  let t = Fe(), [e, r] = ge(ie);
  return z(() => (Z.size === 0 && window.addEventListener("languagechange", ye), Z.add(r), () => {
    Z.delete(r), Z.size === 0 && window.removeEventListener("languagechange", ye);
  }), []), t ? {
    locale: "en-US",
    direction: "ltr"
  } : e;
}
const lt = /* @__PURE__ */ q.createContext(null);
function it() {
  let t = ot();
  return Te(lt) || t;
}
function ae(t) {
  return Ce() ? t.altKey : t.ctrlKey;
}
function j(t) {
  return Me() ? t.metaKey : t.ctrlKey;
}
const at = 1e3;
function ct(t) {
  let { keyboardDelegate: e, selectionManager: r, onTypeSelect: n } = t, o = S({
    search: "",
    timeout: null
  }).current, l = (a) => {
    let i = st(a.key);
    if (!i || a.ctrlKey || a.metaKey || !a.currentTarget.contains(a.target))
      return;
    i === " " && o.search.trim().length > 0 && (a.preventDefault(), "continuePropagation" in a || a.stopPropagation()), o.search += i;
    let b = e.getKeyForSearch(o.search, r.focusedKey);
    b == null && (b = e.getKeyForSearch(o.search)), b != null && (r.setFocusedKey(b), n && n(b)), clearTimeout(o.timeout), o.timeout = setTimeout(() => {
      o.search = "";
    }, at);
  };
  return {
    typeSelectProps: {
      // Using a capturing listener to catch the keydown event before
      // other hooks in order to handle the Spacebar event.
      onKeyDownCapture: e.getKeyForSearch ? l : null
    }
  };
}
function st(t) {
  return t.length === 1 || !/^[A-Z]/i.test(t) ? t : "";
}
function yt(t) {
  let { selectionManager: e, keyboardDelegate: r, ref: n, autoFocus: o = !1, shouldFocusWrap: l = !1, disallowEmptySelection: a = !1, disallowSelectAll: i = !1, selectOnFocus: b = e.selectionBehavior === "replace", disallowTypeAhead: c = !1, shouldUseVirtualFocus: u, allowsTabNavigation: v = !1, isVirtualized: d, scrollRef: p = n, linkBehavior: h = "action" } = t, { direction: x } = it(), K = Ee(), G = (s) => {
    if (s.altKey && s.key === "Tab" && s.preventDefault(), !n.current.contains(s.target))
      return;
    const g = ($, U) => {
      if ($ != null) {
        if (e.isLink($) && h === "selection" && b && !ae(s)) {
          ke(() => {
            e.setFocusedKey($, U);
          });
          let H = p.current.querySelector(`[data-key="${CSS.escape($.toString())}"]`), fe = e.getItemProps($);
          K.open(H, s, fe.href, fe.routerOptions);
          return;
        }
        if (e.setFocusedKey($, U), e.isLink($) && h === "override")
          return;
        s.shiftKey && e.selectionMode === "multiple" ? e.extendSelection($) : b && !ae(s) && e.replaceSelection($);
      }
    };
    switch (s.key) {
      case "ArrowDown":
        if (r.getKeyBelow) {
          var w, E;
          s.preventDefault();
          let $ = e.focusedKey != null ? r.getKeyBelow(e.focusedKey) : (w = r.getFirstKey) === null || w === void 0 ? void 0 : w.call(r);
          $ == null && l && ($ = (E = r.getFirstKey) === null || E === void 0 ? void 0 : E.call(r, e.focusedKey)), g($);
        }
        break;
      case "ArrowUp":
        if (r.getKeyAbove) {
          var F, k;
          s.preventDefault();
          let $ = e.focusedKey != null ? r.getKeyAbove(e.focusedKey) : (F = r.getLastKey) === null || F === void 0 ? void 0 : F.call(r);
          $ == null && l && ($ = (k = r.getLastKey) === null || k === void 0 ? void 0 : k.call(r, e.focusedKey)), g($);
        }
        break;
      case "ArrowLeft":
        if (r.getKeyLeftOf) {
          var X, f;
          s.preventDefault();
          let $ = r.getKeyLeftOf(e.focusedKey);
          $ == null && l && ($ = x === "rtl" ? (X = r.getFirstKey) === null || X === void 0 ? void 0 : X.call(r, e.focusedKey) : (f = r.getLastKey) === null || f === void 0 ? void 0 : f.call(r, e.focusedKey)), g($, x === "rtl" ? "first" : "last");
        }
        break;
      case "ArrowRight":
        if (r.getKeyRightOf) {
          var D, re;
          s.preventDefault();
          let $ = r.getKeyRightOf(e.focusedKey);
          $ == null && l && ($ = x === "rtl" ? (D = r.getLastKey) === null || D === void 0 ? void 0 : D.call(r, e.focusedKey) : (re = r.getFirstKey) === null || re === void 0 ? void 0 : re.call(r, e.focusedKey)), g($, x === "rtl" ? "last" : "first");
        }
        break;
      case "Home":
        if (r.getFirstKey) {
          s.preventDefault();
          let $ = r.getFirstKey(e.focusedKey, j(s));
          e.setFocusedKey($), j(s) && s.shiftKey && e.selectionMode === "multiple" ? e.extendSelection($) : b && e.replaceSelection($);
        }
        break;
      case "End":
        if (r.getLastKey) {
          s.preventDefault();
          let $ = r.getLastKey(e.focusedKey, j(s));
          e.setFocusedKey($), j(s) && s.shiftKey && e.selectionMode === "multiple" ? e.extendSelection($) : b && e.replaceSelection($);
        }
        break;
      case "PageDown":
        if (r.getKeyPageBelow) {
          s.preventDefault();
          let $ = r.getKeyPageBelow(e.focusedKey);
          g($);
        }
        break;
      case "PageUp":
        if (r.getKeyPageAbove) {
          s.preventDefault();
          let $ = r.getKeyPageAbove(e.focusedKey);
          g($);
        }
        break;
      case "a":
        j(s) && e.selectionMode === "multiple" && i !== !0 && (s.preventDefault(), e.selectAll());
        break;
      case "Escape":
        !a && e.selectedKeys.size !== 0 && (s.stopPropagation(), s.preventDefault(), e.clearSelection());
        break;
      case "Tab":
        if (!v) {
          if (s.shiftKey)
            n.current.focus();
          else {
            let $ = C(n.current, {
              tabbable: !0
            }), U, H;
            do
              H = $.lastChild(), H && (U = H);
            while (H);
            U && !U.contains(document.activeElement) && pe(U);
          }
          break;
        }
    }
  }, N = S({
    top: 0,
    left: 0
  });
  We(p, "scroll", d ? null : () => {
    N.current = {
      top: p.current.scrollTop,
      left: p.current.scrollLeft
    };
  });
  let Y = (s) => {
    if (e.isFocused) {
      s.currentTarget.contains(s.target) || e.setFocused(!1);
      return;
    }
    if (s.currentTarget.contains(s.target)) {
      if (e.setFocused(!0), e.focusedKey == null) {
        let E = (k) => {
          k != null && (e.setFocusedKey(k), b && e.replaceSelection(k));
        }, F = s.relatedTarget;
        var g, w;
        F && s.currentTarget.compareDocumentPosition(F) & Node.DOCUMENT_POSITION_FOLLOWING ? E((g = e.lastSelectedKey) !== null && g !== void 0 ? g : r.getLastKey()) : E((w = e.firstSelectedKey) !== null && w !== void 0 ? w : r.getFirstKey());
      } else
        d || (p.current.scrollTop = N.current.top, p.current.scrollLeft = N.current.left);
      if (!d && e.focusedKey != null) {
        let E = p.current.querySelector(`[data-key="${CSS.escape(e.focusedKey.toString())}"]`);
        E && (E.contains(document.activeElement) || pe(E), de() === "keyboard" && ve(E, {
          containingElement: n.current
        }));
      }
    }
  }, R = (s) => {
    s.currentTarget.contains(s.relatedTarget) || e.setFocused(!1);
  };
  const M = S(o);
  z(() => {
    if (M.current) {
      let s = null;
      o === "first" && (s = r.getFirstKey()), o === "last" && (s = r.getLastKey());
      let g = e.selectedKeys;
      if (g.size) {
        for (let w of g)
          if (e.canSelectItem(w)) {
            s = w;
            break;
          }
      }
      e.setFocused(!0), e.setFocusedKey(s), s == null && !u && V(n.current);
    }
  }, []);
  let I = S(e.focusedKey);
  z(() => {
    let s = de();
    if (e.isFocused && e.focusedKey != null && (p != null && p.current)) {
      let g = p.current.querySelector(`[data-key="${CSS.escape(e.focusedKey.toString())}"]`);
      g && (s === "keyboard" || M.current) && (d || Ke(p.current, g), s !== "virtual" && ve(g, {
        containingElement: n.current
      }));
    }
    e.isFocused && e.focusedKey == null && I.current != null && V(n.current), I.current = e.focusedKey, M.current = !1;
  }, [
    d,
    p,
    e.focusedKey,
    e.isFocused,
    n
  ]);
  let O = {
    onKeyDown: G,
    onFocus: Y,
    onBlur: R,
    onMouseDown(s) {
      p.current === s.target && s.preventDefault();
    }
  }, { typeSelectProps: Q } = ct({
    keyboardDelegate: r,
    selectionManager: e
  });
  c || (O = ce(Q, O));
  let B;
  return u || (B = e.focusedKey == null ? 0 : -1), {
    collectionProps: {
      ...O,
      tabIndex: B
    }
  };
}
function ht(t) {
  let { selectionManager: e, key: r, ref: n, shouldSelectOnPressUp: o, shouldUseVirtualFocus: l, focus: a, isDisabled: i, onAction: b, allowsDifferentPressOrigin: c, linkBehavior: u = "action" } = t, v = Ee(), d = (f) => {
    if (f.pointerType === "keyboard" && ae(f))
      e.toggleSelection(r);
    else {
      if (e.selectionMode === "none")
        return;
      if (e.isLink(r)) {
        if (u === "selection") {
          let D = e.getItemProps(r);
          v.open(n.current, f, D.href, D.routerOptions), e.setSelectedKeys(e.selectedKeys);
          return;
        } else if (u === "override" || u === "none")
          return;
      }
      e.selectionMode === "single" ? e.isSelected(r) && !e.disallowEmptySelection ? e.toggleSelection(r) : e.replaceSelection(r) : f && f.shiftKey ? e.extendSelection(r) : e.selectionBehavior === "toggle" || f && (j(f) || f.pointerType === "touch" || f.pointerType === "virtual") ? e.toggleSelection(r) : e.replaceSelection(r);
    }
  };
  z(() => {
    r === e.focusedKey && e.isFocused && !l && (a ? a() : document.activeElement !== n.current && V(n.current));
  }, [
    n,
    r,
    e.focusedKey,
    e.childFocusStrategy,
    e.isFocused,
    l
  ]), i = i || e.isDisabled(r);
  let p = {};
  !l && !i ? p = {
    tabIndex: r === e.focusedKey ? 0 : -1,
    onFocus(f) {
      f.target === n.current && e.setFocusedKey(r);
    }
  } : i && (p.onMouseDown = (f) => {
    f.preventDefault();
  });
  let h = e.isLink(r) && u === "override", x = e.isLink(r) && u !== "selection" && u !== "none", K = !i && e.canSelectItem(r) && !h, G = (b || x) && !i, N = G && (e.selectionBehavior === "replace" ? !K : !K || e.isEmpty), Y = G && K && e.selectionBehavior === "replace", R = N || Y, M = S(null), I = R && K, O = S(!1), Q = S(!1), B = (f) => {
    if (b && b(), x) {
      let D = e.getItemProps(r);
      v.open(n.current, f, D.href, D.routerOptions);
    }
  }, s = {};
  o ? (s.onPressStart = (f) => {
    M.current = f.pointerType, O.current = I, f.pointerType === "keyboard" && (!R || me()) && d(f);
  }, c ? (s.onPressUp = N ? null : (f) => {
    f.pointerType !== "keyboard" && K && d(f);
  }, s.onPress = N ? B : null) : s.onPress = (f) => {
    if (N || Y && f.pointerType !== "mouse") {
      if (f.pointerType === "keyboard" && !he())
        return;
      B(f);
    } else
      f.pointerType !== "keyboard" && K && d(f);
  }) : (s.onPressStart = (f) => {
    M.current = f.pointerType, O.current = I, Q.current = N, K && (f.pointerType === "mouse" && !N || f.pointerType === "keyboard" && (!G || me())) && d(f);
  }, s.onPress = (f) => {
    (f.pointerType === "touch" || f.pointerType === "pen" || f.pointerType === "virtual" || f.pointerType === "keyboard" && R && he() || f.pointerType === "mouse" && Q.current) && (R ? B(f) : K && d(f));
  }), p["data-key"] = r, s.preventFocusOnPress = l;
  let { pressProps: g, isPressed: w } = Se(s), E = Y ? (f) => {
    M.current === "mouse" && (f.stopPropagation(), f.preventDefault(), B(f));
  } : void 0, { longPressProps: F } = He({
    isDisabled: !I,
    onLongPress(f) {
      f.pointerType === "touch" && (d(f), e.setSelectionBehavior("toggle"));
    }
  }), k = (f) => {
    M.current === "touch" && O.current && f.preventDefault();
  }, X = e.isLink(r) ? (f) => {
    Re.isOpening || f.preventDefault();
  } : void 0;
  return {
    itemProps: ce(p, K || N ? g : {}, I ? F : {}, {
      onDoubleClick: E,
      onDragStartCapture: k,
      onClick: X
    }),
    isPressed: w,
    isSelected: e.isSelected(r),
    isFocused: e.isFocused && e.focusedKey === r,
    isDisabled: i,
    allowsSelection: K,
    hasAction: R
  };
}
function he() {
  let t = window.event;
  return (t == null ? void 0 : t.key) === "Enter";
}
function me() {
  let t = window.event;
  return (t == null ? void 0 : t.key) === " " || (t == null ? void 0 : t.code) === "Space";
}
export {
  C as $,
  Ie as a,
  it as b,
  ht as c,
  bt as d,
  yt as e,
  vt as f,
  Ue as g,
  ve as h,
  $t as i,
  He as j
};
