import { a as Bu } from "./index.1f4a6407.js";
import { b as xu, c as Cu, h as Eu, g as gu, e as ku } from "./Collection.e8e46ab5.js";
import { g as _, b as B, d as Au, m as hu, $ as O, o as yu, j as U, c as W, l as Pu } from "./utils.371a0d8b.js";
import { $ as S, a as Fu, b as g, c as Iu, d as zu } from "./Label.afa424d9.js";
import { b as Su, c as Tu, d as wu, e as Mu, f as T, g as Lu } from "./ListBox.ba6e4e6c.js";
import { u as H, d as y, v as G, a as E, t as ju, r as Nu, i as q, h as Ku } from "./useFocusRing.91eeed6d.js";
import v, { useRef as A, useEffect as F, useMemo as Ru, useState as Vu, forwardRef as w, useContext as Z, createContext as J } from "react";
import { g as Q, b as X, c as _u, $ as Ou, h as k } from "./useSelectableItem.ca3ec579.js";
import { $ as Uu } from "./useHasTabbableChild.8b7adf4c.js";
function Wu(e, u) {
  const t = A(!0), o = A(null);
  F(() => (t.current = !0, () => {
    t.current = !1;
  }), []), F(() => {
    t.current ? t.current = !1 : (!o.current || u.some((i, l) => !Object.is(i, o[l]))) && e(), o.current = u;
  }, u);
}
const M = /* @__PURE__ */ new WeakMap();
function N(e, u) {
  let { id: t } = M.get(e);
  if (!t)
    throw new Error("Unknown list");
  return `${t}-${Hu(u)}`;
}
function Hu(e) {
  return typeof e == "string" ? e.replace(/\s*/g, "") : "" + e;
}
var Y = {};
Y = {
  deselectedItem: (e) => `${e.item} غير المحدد`,
  longPressToSelect: "اضغط مطولًا للدخول إلى وضع التحديد.",
  select: "تحديد",
  selectedAll: "جميع العناصر المحددة.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "لم يتم تحديد عناصر",
    one: () => `${u.number(e.count)} عنصر محدد`,
    other: () => `${u.number(e.count)} عنصر محدد`
  })}.`,
  selectedItem: (e) => `${e.item} المحدد`
};
var ee = {};
ee = {
  deselectedItem: (e) => `${e.item} не е избран.`,
  longPressToSelect: "Натиснете и задръжте за да влезете в избирателен режим.",
  select: "Изберете",
  selectedAll: "Всички елементи са избрани.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Няма избрани елементи",
    one: () => `${u.number(e.count)} избран елемент`,
    other: () => `${u.number(e.count)} избрани елементи`
  })}.`,
  selectedItem: (e) => `${e.item} избран.`
};
var ue = {};
ue = {
  deselectedItem: (e) => `Položka ${e.item} není vybrána.`,
  longPressToSelect: "Dlouhým stisknutím přejdete do režimu výběru.",
  select: "Vybrat",
  selectedAll: "Vybrány všechny položky.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nevybrány žádné položky",
    one: () => `Vybrána ${u.number(e.count)} položka`,
    other: () => `Vybráno ${u.number(e.count)} položek`
  })}.`,
  selectedItem: (e) => `Vybrána položka ${e.item}.`
};
var te = {};
te = {
  deselectedItem: (e) => `${e.item} ikke valgt.`,
  longPressToSelect: "Lav et langt tryk for at aktivere valgtilstand.",
  select: "Vælg",
  selectedAll: "Alle elementer valgt.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Ingen elementer valgt",
    one: () => `${u.number(e.count)} element valgt`,
    other: () => `${u.number(e.count)} elementer valgt`
  })}.`,
  selectedItem: (e) => `${e.item} valgt.`
};
var le = {};
le = {
  deselectedItem: (e) => `${e.item} nicht ausgewählt.`,
  longPressToSelect: "Gedrückt halten, um Auswahlmodus zu öffnen.",
  select: "Auswählen",
  selectedAll: "Alle Elemente ausgewählt.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Keine Elemente ausgewählt",
    one: () => `${u.number(e.count)} Element ausgewählt`,
    other: () => `${u.number(e.count)} Elemente ausgewählt`
  })}.`,
  selectedItem: (e) => `${e.item} ausgewählt.`
};
var oe = {};
oe = {
  deselectedItem: (e) => `Δεν επιλέχθηκε το στοιχείο ${e.item}.`,
  longPressToSelect: "Πατήστε παρατεταμένα για να μπείτε σε λειτουργία επιλογής.",
  select: "Επιλογή",
  selectedAll: "Επιλέχθηκαν όλα τα στοιχεία.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Δεν επιλέχθηκαν στοιχεία",
    one: () => `Επιλέχθηκε ${u.number(e.count)} στοιχείο`,
    other: () => `Επιλέχθηκαν ${u.number(e.count)} στοιχεία`
  })}.`,
  selectedItem: (e) => `Επιλέχθηκε το στοιχείο ${e.item}.`
};
var ne = {};
ne = {
  deselectedItem: (e) => `${e.item} not selected.`,
  select: "Select",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "No items selected",
    one: () => `${u.number(e.count)} item selected`,
    other: () => `${u.number(e.count)} items selected`
  })}.`,
  selectedAll: "All items selected.",
  selectedItem: (e) => `${e.item} selected.`,
  longPressToSelect: "Long press to enter selection mode."
};
var re = {};
re = {
  deselectedItem: (e) => `${e.item} no seleccionado.`,
  longPressToSelect: "Mantenga pulsado para abrir el modo de selección.",
  select: "Seleccionar",
  selectedAll: "Todos los elementos seleccionados.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Ningún elemento seleccionado",
    one: () => `${u.number(e.count)} elemento seleccionado`,
    other: () => `${u.number(e.count)} elementos seleccionados`
  })}.`,
  selectedItem: (e) => `${e.item} seleccionado.`
};
var ie = {};
ie = {
  deselectedItem: (e) => `${e.item} pole valitud.`,
  longPressToSelect: "Valikurežiimi sisenemiseks vajutage pikalt.",
  select: "Vali",
  selectedAll: "Kõik üksused valitud.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Üksusi pole valitud",
    one: () => `${u.number(e.count)} üksus valitud`,
    other: () => `${u.number(e.count)} üksust valitud`
  })}.`,
  selectedItem: (e) => `${e.item} valitud.`
};
var ae = {};
ae = {
  deselectedItem: (e) => `Kohdetta ${e.item} ei valittu.`,
  longPressToSelect: "Siirry valintatilaan painamalla pitkään.",
  select: "Valitse",
  selectedAll: "Kaikki kohteet valittu.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Ei yhtään kohdetta valittu",
    one: () => `${u.number(e.count)} kohde valittu`,
    other: () => `${u.number(e.count)} kohdetta valittu`
  })}.`,
  selectedItem: (e) => `${e.item} valittu.`
};
var se = {};
se = {
  deselectedItem: (e) => `${e.item} non sélectionné.`,
  longPressToSelect: "Appuyez de manière prolongée pour passer en mode de sélection.",
  select: "Sélectionner",
  selectedAll: "Tous les éléments sélectionnés.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Aucun élément sélectionné",
    one: () => `${u.number(e.count)} élément sélectionné`,
    other: () => `${u.number(e.count)} éléments sélectionnés`
  })}.`,
  selectedItem: (e) => `${e.item} sélectionné.`
};
var ce = {};
ce = {
  deselectedItem: (e) => `${e.item} לא נבחר.`,
  longPressToSelect: "הקשה ארוכה לכניסה למצב בחירה.",
  select: "בחר",
  selectedAll: "כל הפריטים נבחרו.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "לא נבחרו פריטים",
    one: () => `פריט ${u.number(e.count)} נבחר`,
    other: () => `${u.number(e.count)} פריטים נבחרו`
  })}.`,
  selectedItem: (e) => `${e.item} נבחר.`
};
var de = {};
de = {
  deselectedItem: (e) => `Stavka ${e.item} nije odabrana.`,
  longPressToSelect: "Dugo pritisnite za ulazak u način odabira.",
  select: "Odaberite",
  selectedAll: "Odabrane su sve stavke.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nije odabrana nijedna stavka",
    one: () => `Odabrana je ${u.number(e.count)} stavka`,
    other: () => `Odabrano je ${u.number(e.count)} stavki`
  })}.`,
  selectedItem: (e) => `Stavka ${e.item} je odabrana.`
};
var $e = {};
$e = {
  deselectedItem: (e) => `${e.item} nincs kijelölve.`,
  longPressToSelect: "Nyomja hosszan a kijelöléshez.",
  select: "Kijelölés",
  selectedAll: "Az összes elem kijelölve.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Egy elem sincs kijelölve",
    one: () => `${u.number(e.count)} elem kijelölve`,
    other: () => `${u.number(e.count)} elem kijelölve`
  })}.`,
  selectedItem: (e) => `${e.item} kijelölve.`
};
var be = {};
be = {
  deselectedItem: (e) => `${e.item} non selezionato.`,
  longPressToSelect: "Premi a lungo per passare alla modalità di selezione.",
  select: "Seleziona",
  selectedAll: "Tutti gli elementi selezionati.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nessun elemento selezionato",
    one: () => `${u.number(e.count)} elemento selezionato`,
    other: () => `${u.number(e.count)} elementi selezionati`
  })}.`,
  selectedItem: (e) => `${e.item} selezionato.`
};
var me = {};
me = {
  deselectedItem: (e) => `${e.item} が選択されていません。`,
  longPressToSelect: "長押しして選択モードを開きます。",
  select: "選択",
  selectedAll: "すべての項目を選択しました。",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "項目が選択されていません",
    one: () => `${u.number(e.count)} 項目を選択しました`,
    other: () => `${u.number(e.count)} 項目を選択しました`
  })}。`,
  selectedItem: (e) => `${e.item} を選択しました。`
};
var pe = {};
pe = {
  deselectedItem: (e) => `${e.item}이(가) 선택되지 않았습니다.`,
  longPressToSelect: "선택 모드로 들어가려면 길게 누르십시오.",
  select: "선택",
  selectedAll: "모든 항목이 선택되었습니다.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "선택된 항목이 없습니다",
    one: () => `${u.number(e.count)}개 항목이 선택되었습니다`,
    other: () => `${u.number(e.count)}개 항목이 선택되었습니다`
  })}.`,
  selectedItem: (e) => `${e.item}이(가) 선택되었습니다.`
};
var fe = {};
fe = {
  deselectedItem: (e) => `${e.item} nepasirinkta.`,
  longPressToSelect: "Norėdami įjungti pasirinkimo režimą, paspauskite ir palaikykite.",
  select: "Pasirinkti",
  selectedAll: "Pasirinkti visi elementai.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nepasirinktas nė vienas elementas",
    one: () => `Pasirinktas ${u.number(e.count)} elementas`,
    other: () => `Pasirinkta elementų: ${u.number(e.count)}`
  })}.`,
  selectedItem: (e) => `Pasirinkta: ${e.item}.`
};
var ve = {};
ve = {
  deselectedItem: (e) => `Vienums ${e.item} nav atlasīts.`,
  longPressToSelect: "Ilgi turiet nospiestu. lai ieslēgtu atlases režīmu.",
  select: "Atlasīt",
  selectedAll: "Atlasīti visi vienumi.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nav atlasīts neviens vienums",
    one: () => `Atlasīto vienumu skaits: ${u.number(e.count)}`,
    other: () => `Atlasīto vienumu skaits: ${u.number(e.count)}`
  })}.`,
  selectedItem: (e) => `Atlasīts vienums ${e.item}.`
};
var De = {};
De = {
  deselectedItem: (e) => `${e.item} er ikke valgt.`,
  longPressToSelect: "Bruk et langt trykk for å gå inn i valgmodus.",
  select: "Velg",
  selectedAll: "Alle elementer er valgt.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Ingen elementer er valgt",
    one: () => `${u.number(e.count)} element er valgt`,
    other: () => `${u.number(e.count)} elementer er valgt`
  })}.`,
  selectedItem: (e) => `${e.item} er valgt.`
};
var Be = {};
Be = {
  deselectedItem: (e) => `${e.item} niet geselecteerd.`,
  longPressToSelect: "Druk lang om de selectiemodus te openen.",
  select: "Selecteren",
  selectedAll: "Alle items geselecteerd.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Geen items geselecteerd",
    one: () => `${u.number(e.count)} item geselecteerd`,
    other: () => `${u.number(e.count)} items geselecteerd`
  })}.`,
  selectedItem: (e) => `${e.item} geselecteerd.`
};
var xe = {};
xe = {
  deselectedItem: (e) => `Nie zaznaczono ${e.item}.`,
  longPressToSelect: "Naciśnij i przytrzymaj, aby wejść do trybu wyboru.",
  select: "Zaznacz",
  selectedAll: "Wszystkie zaznaczone elementy.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nie zaznaczono żadnych elementów",
    one: () => `${u.number(e.count)} zaznaczony element`,
    other: () => `${u.number(e.count)} zaznaczonych elementów`
  })}.`,
  selectedItem: (e) => `Zaznaczono ${e.item}.`
};
var Ce = {};
Ce = {
  deselectedItem: (e) => `${e.item} não selecionado.`,
  longPressToSelect: "Mantenha pressionado para entrar no modo de seleção.",
  select: "Selecionar",
  selectedAll: "Todos os itens selecionados.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nenhum item selecionado",
    one: () => `${u.number(e.count)} item selecionado`,
    other: () => `${u.number(e.count)} itens selecionados`
  })}.`,
  selectedItem: (e) => `${e.item} selecionado.`
};
var Ee = {};
Ee = {
  deselectedItem: (e) => `${e.item} não selecionado.`,
  longPressToSelect: "Prima continuamente para entrar no modo de seleção.",
  select: "Selecionar",
  selectedAll: "Todos os itens selecionados.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nenhum item selecionado",
    one: () => `${u.number(e.count)} item selecionado`,
    other: () => `${u.number(e.count)} itens selecionados`
  })}.`,
  selectedItem: (e) => `${e.item} selecionado.`
};
var ge = {};
ge = {
  deselectedItem: (e) => `${e.item} neselectat.`,
  longPressToSelect: "Apăsați lung pentru a intra în modul de selectare.",
  select: "Selectare",
  selectedAll: "Toate elementele selectate.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Niciun element selectat",
    one: () => `${u.number(e.count)} element selectat`,
    other: () => `${u.number(e.count)} elemente selectate`
  })}.`,
  selectedItem: (e) => `${e.item} selectat.`
};
var ke = {};
ke = {
  deselectedItem: (e) => `${e.item} не выбрано.`,
  longPressToSelect: "Нажмите и удерживайте для входа в режим выбора.",
  select: "Выбрать",
  selectedAll: "Выбраны все элементы.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Нет выбранных элементов",
    one: () => `${u.number(e.count)} элемент выбран`,
    other: () => `${u.number(e.count)} элементов выбрано`
  })}.`,
  selectedItem: (e) => `${e.item} выбрано.`
};
var Ae = {};
Ae = {
  deselectedItem: (e) => `Nevybraté položky: ${e.item}.`,
  longPressToSelect: "Dlhším stlačením prejdite do režimu výberu.",
  select: "Vybrať",
  selectedAll: "Všetky vybraté položky.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Žiadne vybraté položky",
    one: () => `${u.number(e.count)} vybratá položka`,
    other: () => `Počet vybratých položiek:${u.number(e.count)}`
  })}.`,
  selectedItem: (e) => `Vybraté položky: ${e.item}.`
};
var he = {};
he = {
  deselectedItem: (e) => `Element ${e.item} ni izbran.`,
  longPressToSelect: "Za izbirni način pritisnite in dlje časa držite.",
  select: "Izberite",
  selectedAll: "Vsi elementi so izbrani.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Noben element ni izbran",
    one: () => `${u.number(e.count)} element je izbran`,
    other: () => `${u.number(e.count)} elementov je izbranih`
  })}.`,
  selectedItem: (e) => `Element ${e.item} je izbran.`
};
var ye = {};
ye = {
  deselectedItem: (e) => `${e.item} nije izabrano.`,
  longPressToSelect: "Dugo pritisnite za ulazak u režim biranja.",
  select: "Izaberite",
  selectedAll: "Izabrane su sve stavke.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nije izabrana nijedna stavka",
    one: () => `Izabrana je ${u.number(e.count)} stavka`,
    other: () => `Izabrano je ${u.number(e.count)} stavki`
  })}.`,
  selectedItem: (e) => `${e.item} je izabrano.`
};
var Pe = {};
Pe = {
  deselectedItem: (e) => `${e.item} ej markerat.`,
  longPressToSelect: "Tryck länge när du vill öppna väljarläge.",
  select: "Markera",
  selectedAll: "Alla markerade objekt.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Inga markerade objekt",
    one: () => `${u.number(e.count)} markerat objekt`,
    other: () => `${u.number(e.count)} markerade objekt`
  })}.`,
  selectedItem: (e) => `${e.item} markerat.`
};
var Fe = {};
Fe = {
  deselectedItem: (e) => `${e.item} seçilmedi.`,
  longPressToSelect: "Seçim moduna girmek için uzun basın.",
  select: "Seç",
  selectedAll: "Tüm ögeler seçildi.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Hiçbir öge seçilmedi",
    one: () => `${u.number(e.count)} öge seçildi`,
    other: () => `${u.number(e.count)} öge seçildi`
  })}.`,
  selectedItem: (e) => `${e.item} seçildi.`
};
var Ie = {};
Ie = {
  deselectedItem: (e) => `${e.item} не вибрано.`,
  longPressToSelect: "Виконайте довге натиснення, щоб перейти в режим вибору.",
  select: "Вибрати",
  selectedAll: "Усі елементи вибрано.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Жодних елементів не вибрано",
    one: () => `${u.number(e.count)} елемент вибрано`,
    other: () => `Вибрано елементів: ${u.number(e.count)}`
  })}.`,
  selectedItem: (e) => `${e.item} вибрано.`
};
var ze = {};
ze = {
  deselectedItem: (e) => `未选择 ${e.item}。`,
  longPressToSelect: "长按以进入选择模式。",
  select: "选择",
  selectedAll: "已选择所有项目。",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "未选择项目",
    one: () => `已选择 ${u.number(e.count)} 个项目`,
    other: () => `已选择 ${u.number(e.count)} 个项目`
  })}。`,
  selectedItem: (e) => `已选择 ${e.item}。`
};
var Se = {};
Se = {
  deselectedItem: (e) => `未選取「${e.item}」。`,
  longPressToSelect: "長按以進入選擇模式。",
  select: "選取",
  selectedAll: "已選取所有項目。",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "未選取任何項目",
    one: () => `已選取 ${u.number(e.count)} 個項目`,
    other: () => `已選取 ${u.number(e.count)} 個項目`
  })}。`,
  selectedItem: (e) => `已選取「${e.item}」。`
};
var L = {};
L = {
  "ar-AE": Y,
  "bg-BG": ee,
  "cs-CZ": ue,
  "da-DK": te,
  "de-DE": le,
  "el-GR": oe,
  "en-US": ne,
  "es-ES": re,
  "et-EE": ie,
  "fi-FI": ae,
  "fr-FR": se,
  "he-IL": ce,
  "hr-HR": de,
  "hu-HU": $e,
  "it-IT": be,
  "ja-JP": me,
  "ko-KR": pe,
  "lt-LT": fe,
  "lv-LV": ve,
  "nb-NO": De,
  "nl-NL": Be,
  "pl-PL": xe,
  "pt-BR": Ce,
  "pt-PT": Ee,
  "ro-RO": ge,
  "ru-RU": ke,
  "sk-SK": Ae,
  "sl-SI": he,
  "sr-SP": ye,
  "sv-SE": Pe,
  "tr-TR": Fe,
  "uk-UA": Ie,
  "zh-CN": ze,
  "zh-TW": Se
};
function Gu(e) {
  return e && e.__esModule ? e.default : e;
}
function qu(e, u) {
  let { getRowText: t = (r) => {
    var s, n, $, c;
    return (c = (s = (n = u.collection).getTextValue) === null || s === void 0 ? void 0 : s.call(n, r)) !== null && c !== void 0 ? c : ($ = u.collection.getItem(r)) === null || $ === void 0 ? void 0 : $.textValue;
  } } = e, o = S(Gu(L), "@react-aria/grid"), i = u.selectionManager.rawSelection, l = A(i);
  Wu(() => {
    var r;
    if (!u.selectionManager.isFocused) {
      l.current = i;
      return;
    }
    let s = K(i, l.current), n = K(l.current, i), $ = u.selectionManager.selectionBehavior === "replace", c = [];
    if (u.selectionManager.selectedKeys.size === 1 && $) {
      if (u.collection.getItem(u.selectionManager.selectedKeys.keys().next().value)) {
        let d = t(u.selectionManager.selectedKeys.keys().next().value);
        d && c.push(o.format("selectedItem", {
          item: d
        }));
      }
    } else if (s.size === 1 && n.size === 0) {
      let d = t(s.keys().next().value);
      d && c.push(o.format("selectedItem", {
        item: d
      }));
    } else if (n.size === 1 && s.size === 0 && u.collection.getItem(n.keys().next().value)) {
      let d = t(n.keys().next().value);
      d && c.push(o.format("deselectedItem", {
        item: d
      }));
    }
    u.selectionManager.selectionMode === "multiple" && (c.length === 0 || i === "all" || i.size > 1 || l.current === "all" || ((r = l.current) === null || r === void 0 ? void 0 : r.size) > 1) && c.push(i === "all" ? o.format("selectedAll") : o.format("selectedCount", {
      count: i.size
    })), c.length > 0 && Fu(c.join(" ")), l.current = i;
  }, [
    i
  ]);
}
function K(e, u) {
  let t = /* @__PURE__ */ new Set();
  if (e === "all" || u === "all")
    return t;
  for (let o of e.keys())
    u.has(o) || t.add(o);
  return t;
}
function Zu(e) {
  return e && e.__esModule ? e.default : e;
}
function Ju(e) {
  let u = S(Zu(L), "@react-aria/grid"), t = H(), o = (t === "pointer" || t === "virtual" || t == null) && typeof window < "u" && "ontouchstart" in window, i = Ru(() => {
    let r = e.selectionManager.selectionMode, s = e.selectionManager.selectionBehavior, n;
    return o && (n = u.format("longPressToSelect")), s === "replace" && r !== "none" && e.hasItemActions ? n : void 0;
  }, [
    e.selectionManager.selectionMode,
    e.selectionManager.selectionBehavior,
    e.hasItemActions,
    u,
    o
  ]);
  return Q(i);
}
function Qu(e, u, t) {
  let { isVirtualized: o, keyboardDelegate: i, onAction: l, linkBehavior: r = "action" } = e;
  !e["aria-label"] && !e["aria-labelledby"] && console.warn("An aria-label or aria-labelledby prop is required for accessibility.");
  let { listProps: s } = Su({
    selectionManager: u.selectionManager,
    collection: u.collection,
    disabledKeys: u.disabledKeys,
    ref: t,
    keyboardDelegate: i,
    isVirtualized: o,
    selectOnFocus: u.selectionManager.selectionBehavior === "replace",
    shouldFocusWrap: e.shouldFocusWrap,
    linkBehavior: r
  }), n = _(e.id);
  M.set(u, {
    id: n,
    onAction: l,
    linkBehavior: r
  });
  let $ = Ju({
    selectionManager: u.selectionManager,
    hasItemActions: !!l
  }), c = Uu(t, {
    isDisabled: u.collection.size !== 0
  }), d = y(e, {
    labelable: !0
  }), b = B(
    d,
    {
      role: "grid",
      id: n,
      "aria-multiselectable": u.selectionManager.selectionMode === "multiple" ? "true" : void 0
    },
    // If collection is empty, make sure the grid is tabbable unless there is a child tabbable element.
    u.collection.size === 0 ? {
      tabIndex: c ? -1 : 0
    } : s,
    $
  );
  return o && (b["aria-rowcount"] = u.collection.size, b["aria-colcount"] = 1), qu({}, u), {
    gridProps: b
  };
}
const R = {
  expand: {
    ltr: "ArrowRight",
    rtl: "ArrowLeft"
  },
  collapse: {
    ltr: "ArrowLeft",
    rtl: "ArrowRight"
  }
};
function Xu(e, u, t) {
  var o, i;
  let { node: l, isVirtualized: r, shouldSelectOnPressUp: s } = e, { direction: n } = X(), { onAction: $, linkBehavior: c } = M.get(u), d = Au(), b = A(null), f = () => {
    var a;
    (b.current != null && l.key !== b.current || !(!((a = t.current) === null || a === void 0) && a.contains(document.activeElement))) && E(t.current);
  }, m = {}, D, I = u.selectionManager.isLink(l.key);
  l != null && "expandedKeys" in u && (D = [
    ...u.collection.getChildren(l.key)
  ].length > 1, $ == null && !I && u.selectionManager.selectionMode === "none" && D && ($ = () => u.toggleKey(l.key)), m = {
    "aria-expanded": D ? u.expandedKeys.has(l.key) : void 0,
    "aria-level": l.level + 1,
    "aria-posinset": (l == null ? void 0 : l.index) + 1,
    "aria-setsize": l.level > 0 ? Tu(u.collection.getChildren(l == null ? void 0 : l.parentKey)).index + 1 : [
      ...u.collection
    ].filter((p) => p.level === 0).at(-1).index + 1
  });
  let { itemProps: z, ...P } = _u({
    selectionManager: u.selectionManager,
    key: l.key,
    ref: t,
    isVirtualized: r,
    shouldSelectOnPressUp: s,
    onAction: $ || !((o = l.props) === null || o === void 0) && o.onAction ? hu((i = l.props) === null || i === void 0 ? void 0 : i.onAction, $ ? () => $(l.key) : void 0) : void 0,
    focus: f,
    linkBehavior: c
  }), h = (a) => {
    if (!a.currentTarget.contains(a.target))
      return;
    let p = Ou(t.current);
    if (p.currentNode = document.activeElement, "expandedKeys" in u && document.activeElement === t.current) {
      if (a.key === R.expand[n] && u.selectionManager.focusedKey === l.key && D && !u.expandedKeys.has(l.key)) {
        u.toggleKey(l.key), a.stopPropagation();
        return;
      } else if (a.key === R.collapse[n] && u.selectionManager.focusedKey === l.key && D && u.expandedKeys.has(l.key)) {
        u.toggleKey(l.key), a.stopPropagation();
        return;
      }
    }
    switch (a.key) {
      case "ArrowLeft": {
        let x = n === "rtl" ? p.nextNode() : p.previousNode();
        if (x)
          a.preventDefault(), a.stopPropagation(), E(x), k(x, {
            containingElement: g(t.current)
          });
        else if (a.preventDefault(), a.stopPropagation(), n === "rtl")
          E(t.current), k(t.current, {
            containingElement: g(t.current)
          });
        else {
          p.currentNode = t.current;
          let C = V(p);
          C && (E(C), k(C, {
            containingElement: g(t.current)
          }));
        }
        break;
      }
      case "ArrowRight": {
        let x = n === "rtl" ? p.previousNode() : p.nextNode();
        if (x)
          a.preventDefault(), a.stopPropagation(), E(x), k(x, {
            containingElement: g(t.current)
          });
        else if (a.preventDefault(), a.stopPropagation(), n === "ltr")
          E(t.current), k(t.current, {
            containingElement: g(t.current)
          });
        else {
          p.currentNode = t.current;
          let C = V(p);
          C && (E(C), k(C, {
            containingElement: g(t.current)
          }));
        }
        break;
      }
      case "ArrowUp":
      case "ArrowDown":
        !a.altKey && t.current.contains(a.target) && (a.stopPropagation(), a.preventDefault(), t.current.parentElement.dispatchEvent(new KeyboardEvent(a.nativeEvent.type, a.nativeEvent)));
        break;
    }
  }, fu = (a) => {
    if (b.current = l.key, a.target !== t.current) {
      ju() || u.selectionManager.setFocusedKey(l.key);
      return;
    }
  }, vu = P.hasAction ? G(l.props) : {}, j = B(z, vu, {
    role: "row",
    onKeyDownCapture: h,
    onFocus: fu,
    // 'aria-label': [(node.textValue || undefined), rowAnnouncement].filter(Boolean).join(', '),
    "aria-label": l.textValue || void 0,
    "aria-selected": u.selectionManager.canSelectItem(l.key) ? u.selectionManager.isSelected(l.key) : void 0,
    "aria-disabled": u.selectionManager.isDisabled(l.key) || void 0,
    "aria-labelledby": d && l.textValue ? `${N(u, l.key)} ${d}` : void 0,
    id: N(u, l.key)
  });
  r && (j["aria-rowindex"] = l.index + 1);
  let Du = {
    role: "gridcell",
    "aria-colindex": 1
  };
  return {
    rowProps: {
      ...B(j, m)
    },
    gridCellProps: Du,
    descriptionProps: {
      id: d
    },
    ...P
  };
}
function V(e) {
  let u, t;
  do
    t = e.lastChild(), t && (u = t);
  while (t);
  return u;
}
const Te = /* @__PURE__ */ new WeakMap();
function Yu(e, u, t) {
  let { direction: o } = X(), i = e.keyboardDelegate || new wu({
    collection: u.collection,
    ref: t,
    orientation: "horizontal",
    direction: o,
    disabledKeys: u.disabledKeys,
    disabledBehavior: u.selectionManager.disabledBehavior
  }), { labelProps: l, fieldProps: r, descriptionProps: s, errorMessageProps: n } = Iu({
    ...e,
    labelElementType: "span"
  }), { gridProps: $ } = Qu({
    ...e,
    ...r,
    keyboardDelegate: i,
    shouldFocusWrap: !0,
    linkBehavior: "override"
  }, u, t), [c, d] = Vu(!1), { focusWithinProps: b } = Nu({
    onFocusWithinChange: d
  }), f = y(e), m = A(u.collection.size);
  return F(() => {
    t.current && m.current > 0 && u.collection.size === 0 && c && t.current.focus(), m.current = u.collection.size;
  }, [
    u.collection.size,
    c,
    t
  ]), Te.set(u, {
    onRemove: e.onRemove
  }), {
    gridProps: B($, f, {
      role: u.collection.size ? "grid" : null,
      "aria-atomic": !1,
      "aria-relevant": "additions",
      "aria-live": c ? "polite" : "off",
      ...b,
      ...r
    }),
    labelProps: l,
    descriptionProps: s,
    errorMessageProps: n
  };
}
var we = {};
we = {
  removeButtonLabel: "إزالة",
  removeDescription: "اضغط على مفتاح DELETE لإزالة علامة."
};
var Me = {};
Me = {
  removeButtonLabel: "Премахване",
  removeDescription: "Натиснете Delete, за да премахнете маркера."
};
var Le = {};
Le = {
  removeButtonLabel: "Odebrat",
  removeDescription: "Stisknutím klávesy Delete odeberete značku."
};
var je = {};
je = {
  removeButtonLabel: "Fjern",
  removeDescription: "Tryk på Slet for at fjerne tag."
};
var Ne = {};
Ne = {
  removeButtonLabel: "Entfernen",
  removeDescription: "Auf „Löschen“ drücken, um das Tag zu entfernen."
};
var Ke = {};
Ke = {
  removeButtonLabel: "Κατάργηση",
  removeDescription: "Πατήστε Διαγραφή για να καταργήσετε την ετικέτα."
};
var Re = {};
Re = {
  removeDescription: "Press Delete to remove tag.",
  removeButtonLabel: "Remove"
};
var Ve = {};
Ve = {
  removeButtonLabel: "Quitar",
  removeDescription: "Pulse Eliminar para quitar la etiqueta."
};
var _e = {};
_e = {
  removeButtonLabel: "Eemalda",
  removeDescription: "Sildi eemaldamiseks vajutage kustutusklahvi Delete."
};
var Oe = {};
Oe = {
  removeButtonLabel: "Poista",
  removeDescription: "Poista tunniste painamalla Poista-painiketta."
};
var Ue = {};
Ue = {
  removeButtonLabel: "Supprimer",
  removeDescription: "Appuyez sur Supprimer pour supprimer l’étiquette."
};
var We = {};
We = {
  removeButtonLabel: "הסר",
  removeDescription: "לחץ על מחק כדי להסיר תג."
};
var He = {};
He = {
  removeButtonLabel: "Ukloni",
  removeDescription: "Pritisnite Delete za uklanjanje oznake."
};
var Ge = {};
Ge = {
  removeButtonLabel: "Eltávolítás",
  removeDescription: "Nyomja meg a Delete billentyűt a címke eltávolításához."
};
var qe = {};
qe = {
  removeButtonLabel: "Rimuovi",
  removeDescription: "Premi Elimina per rimuovere il tag."
};
var Ze = {};
Ze = {
  removeButtonLabel: "削除",
  removeDescription: "タグを削除するには、Delete キーを押します。"
};
var Je = {};
Je = {
  removeButtonLabel: "제거",
  removeDescription: "태그를 제거하려면 Delete 키를 누르십시오."
};
var Qe = {};
Qe = {
  removeButtonLabel: "Pašalinti",
  removeDescription: "Norėdami pašalinti žymą, paspauskite „Delete“ klavišą."
};
var Xe = {};
Xe = {
  removeButtonLabel: "Noņemt",
  removeDescription: "Nospiediet Delete [Dzēst], lai noņemtu tagu."
};
var Ye = {};
Ye = {
  removeButtonLabel: "Fjern",
  removeDescription: "Trykk på Slett for å fjerne taggen."
};
var eu = {};
eu = {
  removeButtonLabel: "Verwijderen",
  removeDescription: "Druk op Verwijderen om de tag te verwijderen."
};
var uu = {};
uu = {
  removeButtonLabel: "Usuń",
  removeDescription: "Naciśnij Usuń, aby usunąć znacznik."
};
var tu = {};
tu = {
  removeButtonLabel: "Remover",
  removeDescription: "Pressione Delete para remover a tag."
};
var lu = {};
lu = {
  removeButtonLabel: "Eliminar",
  removeDescription: "Prima Delete para eliminar a tag."
};
var ou = {};
ou = {
  removeButtonLabel: "Îndepărtaţi",
  removeDescription: "Apăsați pe Delete (Ștergere) pentru a elimina eticheta."
};
var nu = {};
nu = {
  removeButtonLabel: "Удалить",
  removeDescription: "Нажмите DELETE, чтобы удалить тег."
};
var ru = {};
ru = {
  removeButtonLabel: "Odstrániť",
  removeDescription: "Ak chcete odstrániť značku, stlačte kláves Delete."
};
var iu = {};
iu = {
  removeButtonLabel: "Odstrani",
  removeDescription: "Pritisnite Delete, da odstranite oznako."
};
var au = {};
au = {
  removeButtonLabel: "Ukloni",
  removeDescription: "Pritisnite Obriši da biste uklonili oznaku."
};
var su = {};
su = {
  removeButtonLabel: "Ta bort",
  removeDescription: "Tryck på Radera för att ta bort taggen."
};
var cu = {};
cu = {
  removeButtonLabel: "Kaldır",
  removeDescription: "Etiketi kaldırmak için Sil tuşuna basın."
};
var du = {};
du = {
  removeButtonLabel: "Вилучити",
  removeDescription: "Натисніть Delete, щоб вилучити тег."
};
var $u = {};
$u = {
  removeButtonLabel: "删除",
  removeDescription: "按下“删除”以删除标记。"
};
var bu = {};
bu = {
  removeButtonLabel: "移除",
  removeDescription: "按 Delete 鍵以移除標記。"
};
var mu = {};
mu = {
  "ar-AE": we,
  "bg-BG": Me,
  "cs-CZ": Le,
  "da-DK": je,
  "de-DE": Ne,
  "el-GR": Ke,
  "en-US": Re,
  "es-ES": Ve,
  "et-EE": _e,
  "fi-FI": Oe,
  "fr-FR": Ue,
  "he-IL": We,
  "hr-HR": He,
  "hu-HU": Ge,
  "it-IT": qe,
  "ja-JP": Ze,
  "ko-KR": Je,
  "lt-LT": Qe,
  "lv-LV": Xe,
  "nb-NO": Ye,
  "nl-NL": eu,
  "pl-PL": uu,
  "pt-BR": tu,
  "pt-PT": lu,
  "ro-RO": ou,
  "ru-RU": nu,
  "sk-SK": ru,
  "sl-SI": iu,
  "sr-SP": au,
  "sv-SE": su,
  "tr-TR": cu,
  "uk-UA": du,
  "zh-CN": $u,
  "zh-TW": bu
};
function et(e) {
  return e && e.__esModule ? e.default : e;
}
function ut(e, u, t) {
  let { item: o } = e, i = S(et(mu), "@react-aria/tag"), l = _(), { onRemove: r } = Te.get(u) || {}, { rowProps: s, gridCellProps: n, ...$ } = Xu({
    node: o
  }, u, t);
  delete s.onKeyDownCapture;
  let { descriptionProps: c, ...d } = $, b = (h) => {
    (h.key === "Delete" || h.key === "Backspace") && (h.preventDefault(), u.selectionManager.isSelected(o.key) ? r == null || r(new Set(u.selectionManager.selectedKeys)) : r == null || r(/* @__PURE__ */ new Set([
      o.key
    ])));
  }, f = H();
  f === "virtual" && typeof window < "u" && "ontouchstart" in window && (f = "pointer");
  let m = r && (f === "keyboard" || f === "virtual") ? i.format("removeDescription") : "", D = Q(m), I = o.key === u.selectionManager.focusedKey, z = y(o.props), P = G(o.props);
  return {
    removeButtonProps: {
      "aria-label": i.format("removeButtonLabel"),
      "aria-labelledby": `${l} ${s.id}`,
      id: l,
      onPress: () => r ? r(/* @__PURE__ */ new Set([
        o.key
      ])) : null,
      excludeFromTabOrder: !0
    },
    rowProps: B(s, z, P, {
      tabIndex: I || u.selectionManager.focusedKey == null ? 0 : -1,
      onKeyDown: r ? b : void 0,
      "aria-describedby": D["aria-describedby"]
    }),
    gridCellProps: B(n, {
      "aria-errormessage": e["aria-errormessage"],
      "aria-label": e["aria-label"]
    }),
    ...d,
    allowsRemoving: !!r
  };
}
const tt = /* @__PURE__ */ J(null), pu = /* @__PURE__ */ J(null);
function lt(e, u) {
  [e, u] = O(e, u, tt);
  let t = A(null), [o, i] = yu(), { collection: l, document: r } = xu(), s = Mu({
    ...e,
    children: void 0,
    collection: l
  }), n = y(e), $ = Object.fromEntries(Object.entries(n).map(([D]) => [
    D,
    void 0
  ])), { gridProps: c, labelProps: d, descriptionProps: b, errorMessageProps: f } = Yu({
    ...e,
    ...$,
    label: i
  }, s, t);
  var m;
  return /* @__PURE__ */ v.createElement("div", {
    ...n,
    ref: u,
    slot: e.slot || void 0,
    className: (m = e.className) !== null && m !== void 0 ? m : "react-aria-TagGroup",
    style: e.style
  }, /* @__PURE__ */ v.createElement(U, {
    values: [
      [
        zu,
        {
          ...d,
          elementType: "span",
          ref: o
        }
      ],
      [
        pu,
        {
          ...c,
          ref: t
        }
      ],
      [
        T,
        s
      ],
      [
        Cu,
        r
      ],
      [
        Lu,
        {
          slots: {
            description: b,
            errorMessage: f
          }
        }
      ]
    ]
  }, e.children));
}
const vt = /* @__PURE__ */ w(lt);
function ot(e, u) {
  let t = Eu(e);
  return /* @__PURE__ */ v.createElement(v.Fragment, null, t, /* @__PURE__ */ v.createElement(nt, {
    props: e,
    forwardedRef: u
  }));
}
function nt({ props: e, forwardedRef: u }) {
  let t = Z(T), [o, i] = O(e, u, pu);
  delete o.items, delete o.renderEmptyState;
  let l = gu({
    items: t.collection,
    children: (d) => {
      switch (d.type) {
        case "item":
          return /* @__PURE__ */ v.createElement(it, {
            item: d
          });
        default:
          throw new Error("Unsupported node type in TagList: " + d.type);
      }
    }
  }), { focusProps: r, isFocused: s, isFocusVisible: n } = q(), $ = {
    isEmpty: t.collection.size === 0,
    isFocused: s,
    isFocusVisible: n,
    state: t
  }, c = W({
    className: e.className,
    style: e.style,
    defaultClassName: "react-aria-TagList",
    values: $
  });
  return /* @__PURE__ */ v.createElement("div", {
    ...B(o, r),
    ...c,
    ref: i,
    "data-empty": t.collection.size === 0 || void 0,
    "data-focused": s || void 0,
    "data-focus-visible": n || void 0
  }, t.collection.size === 0 && e.renderEmptyState ? e.renderEmptyState($) : l);
}
const Dt = /* @__PURE__ */ w(ot);
function rt(e, u) {
  return ku("item", e, u, e.children);
}
const Bt = /* @__PURE__ */ w(rt);
function it({ item: e }) {
  let u = Z(T), t = Pu(e.props.ref), { focusProps: o, isFocusVisible: i } = q({
    within: !0
  }), { rowProps: l, gridCellProps: r, removeButtonProps: s, ...n } = ut({
    item: e
  }, u, t), { hoverProps: $, isHovered: c } = Ku({
    isDisabled: !n.allowsSelection,
    onHoverStart: e.props.onHoverStart,
    onHoverChange: e.props.onHoverChange,
    onHoverEnd: e.props.onHoverEnd
  }), d = e.props, b = W({
    ...d,
    id: void 0,
    children: e.rendered,
    defaultClassName: "react-aria-Tag",
    values: {
      ...n,
      isFocusVisible: i,
      isHovered: c,
      selectionMode: u.selectionManager.selectionMode,
      selectionBehavior: u.selectionManager.selectionBehavior
    }
  });
  return F(() => {
    e.textValue || console.warn("A `textValue` prop is required for <Tag> elements with non-plain text children for accessibility.");
  }, [
    e.textValue
  ]), /* @__PURE__ */ v.createElement("div", {
    ref: t,
    ...b,
    ...B(y(d), l, o, $),
    "data-selected": n.isSelected || void 0,
    "data-disabled": n.isDisabled || void 0,
    "data-hovered": c || void 0,
    "data-focused": n.isFocused || void 0,
    "data-focus-visible": i || void 0,
    "data-pressed": n.isPressed || void 0,
    "data-allows-removing": n.allowsRemoving || void 0,
    "data-selection-mode": u.selectionManager.selectionMode === "none" ? void 0 : u.selectionManager.selectionMode
  }, /* @__PURE__ */ v.createElement("div", {
    ...r,
    style: {
      display: "contents"
    }
  }, /* @__PURE__ */ v.createElement(U, {
    values: [
      [
        Bu,
        {
          slots: {
            remove: s
          }
        }
      ]
    ]
  }, b.children)));
}
export {
  vt as $,
  Dt as a,
  Bt as b
};
