/* eslint-disable no-underscore-dangle */
import {
  PortableText,
  type PortableTextComponentProps,
  type PortableTextComponents,
  type PortableTextProps,
  type PortableTextTypeComponent,
} from '@portabletext/react';
import { useMemo, createElement as baseCreateElement, type ReactElement } from 'react';
import { Heading, Stack, Text } from '@carvertical/ui';
import type { ArbitraryTypedObject, PortableTextBlock } from '@portabletext/types';
import type { BlocksBody } from '../../types';
import { TOC_EXTRACTABLE_TAG } from '../../constants';
import { blockComponents } from './blocks/blockComponents';
import { SanityLink } from './SanityLink';
import styles from './PortableTextRenderer.module.scss';

type PortableTextRendererProps = Omit<
  PortableTextProps<PortableTextBlock | ArbitraryTypedObject>,
  'value'
> & {
  fieldName?: string;
  value: BlocksBody | null;
};

type ComponentProps = {
  id: string;
};

const getDeepLinkId = ({ fieldName, key }: { fieldName?: string; key: string }) =>
  fieldName ? `${fieldName}__${key}` : undefined;

const TextComponents: Record<string, (props: ComponentProps) => ReactElement> = {
  h1: (props) => <Heading as="h1" variant="l" {...props} />,
  h2: (props) => <Heading as="h2" variant="m" className={styles.h2} {...props} />,
  h3: (props) => <Heading as="h3" variant="s" className={styles.h3} {...props} />,
  h4: (props) => <Heading as="h3" variant="xs" className={styles.h4} {...props} />,
  p: (props) => <Text variant="m" {...props} className={styles.paragraph} />,
  li: (props) => <li className={styles.li} {...props} />,
  blockquote: (props) => (
    <blockquote
      className="mx-0 border-0 border-l-3 border-solid pl-3 text-xl font-bold text-blue-500 dark:border-blue-500"
      {...props}
    />
  ),
};

const isWrappableType = (block: PortableTextBlock | ArbitraryTypedObject | undefined) =>
  block && ['block', 'block.image'].includes(block._type);

const createDefaultComponents = (fieldName?: string): PortableTextComponents => {
  const prepareProps: (props: PortableTextProps) => ComponentProps = (props) => ({
    // @ts-expect-error TS(2339)
    id: getDeepLinkId({ fieldName, key: props.value._key }),
  });

  const blockComponentsInPt: Record<string, PortableTextTypeComponent> = Object.fromEntries(
    Object.entries(blockComponents).map(([type, Component]) => [
      type,
      ({ index, value }) => (
        <Component
          {...value}
          _blockIndex={index}
          rootHtmlAttributes={{
            'data-block': value._type,
            id: getDeepLinkId({ fieldName, key: value._key }),
          }}
        />
      ),
    ]),
  );

  const createTextElement =
    (component: keyof typeof TextComponents) =>
    ({ children, ...props }: PortableTextComponentProps<PortableTextBlock>) => {
      const TextComponent = TextComponents[component];

      return baseCreateElement(TextComponent, prepareProps(props), children);
    };

  return {
    block: {
      h1: createTextElement('h1'),
      h2: createTextElement('h2'),
      h3: createTextElement('h3'),
      h4: createTextElement('h4'),
      normal: createTextElement('p'),
      blockquote: createTextElement('blockquote'),
    },
    listItem: createTextElement('li'),
    list: {
      bullet: ({ children }) => (
        <Stack as="ul" className={styles.ul}>
          {children}
        </Stack>
      ),
    },
    types: blockComponentsInPt,
  };
};

const PortableTextRenderer = ({
  components: customComponents,
  fieldName,
  value,
}: PortableTextRendererProps) => {
  const defaultComponents = useMemo(() => createDefaultComponents(fieldName), [fieldName]);
  const components: PortableTextComponents = useMemo(
    () => ({
      ...defaultComponents,
      ...(customComponents || {}),
      marks: {
        ...(customComponents?.marks || {}),
        link: SanityLink,
      },
      types: {
        ...(defaultComponents?.types || {}),
        ...(customComponents?.types || {}),
        // eslint-disable-next-line react/no-unstable-nested-components -- Recursive PortableTextRenderer for rich text blocks
        'block.richText': ({ value: nestedValue }) => (
          <PortableTextRenderer value={nestedValue.content} fieldName={fieldName} />
        ),
      },
    }),
    [defaultComponents, customComponents, fieldName],
  );

  if (!value) {
    return null;
  }

  let group: PortableTextBlock[] = [];

  return value.map((block, index, blocks) => {
    if (isWrappableType(block)) {
      group.push(block as PortableTextBlock);

      // If the next block is also text, but not a h2 tag, group it with this one
      const nextBlock = blocks[index + 1];
      if (isWrappableType(nextBlock) && nextBlock?.style !== TOC_EXTRACTABLE_TAG) {
        return null;
      }

      // Otherwise, render the group of text blocks we have
      const groupValue = group;
      group = [];

      return (
        <div key={block._key}>
          <PortableText value={groupValue} components={components} />
        </div>
      );
    }

    return <PortableText key={block._key} value={block} components={components} />;
  });
};

export { PortableTextRenderer };
/* eslint-enable no-underscore-dangle */
