import { j as a } from "./chunks/jsx-runtime.82a9ddf6.js";
import { h as n } from "./chunks/styles.077839a8.js";
import { IconButton as o } from "./iconButton.mjs";
import "react";
import "./chunks/cn.2ea4f521.js";
import "./chunks/cva.88fc6b72.js";
import "./icon.mjs";
import "./chunks/clsx.0d472180.js";
import "./chunks/warnings.8f6035a3.js";
import "./chunks/animations.7a584063.js";
import "./chunks/motion.bdfaf94a.js";
import "./chunks/index.1b20cf8f.js";
import "./anchorOrButton.mjs";
import "./spinner.mjs";
import "./chunks/useTranslatableUILabel.30fa9f56.js";
import "./chunks/TranslatableUIProvider.d50c2f21.js";
const t = ({ ...e }) => /* @__PURE__ */ a.jsx(o, { className: n.root, size: "xs", variant: "transparent", ...e });
try {
  t.displayName = "TextFieldIconButton", t.__docgenInfo = { description: "", displayName: "TextFieldIconButton", props: { label: { defaultValue: null, description: "", name: "label", required: !0, type: { name: "string" } }, icon: { defaultValue: null, description: "", name: "icon", required: !0, type: { name: "IconTypeWithLegacyFallback" } }, as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } }, loading: { defaultValue: null, description: "", name: "loading", required: !1, type: { name: "boolean" } }, iconClassName: { defaultValue: null, description: "", name: "iconClassName", required: !1, type: { name: "string" } } } };
} catch {
}
export {
  t as TextFieldIconButton
};
