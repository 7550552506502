import { PortableText } from '@portabletext/react';
import { Heading, Stack } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { Accordion } from 'components/common/Accordion';
import type { FaqBlockData } from '../../../types';
import { SanityLink } from '../SanityLink';
import styles from './Faq.module.scss';

const Faq = ({ questions, rootHtmlAttributes }: FaqBlockData) => {
  const { t } = useTranslation();

  const accordionItems = questions?.map(({ answer, title }) => ({
    headingLevel: 'h3' as const,
    buttonNode: title,
    contentNode: <PortableText value={answer} components={{ marks: { link: SanityLink } }} />,
    id: title,
  }));

  return (
    <div {...rootHtmlAttributes}>
      <Stack gap={3} crossAxisAlign="stretch">
        <Heading as="h2" variant="m" className={styles.heading}>
          {t('general.faqTitle')}
        </Heading>
        <Accordion items={accordionItems} />
      </Stack>
    </div>
  );
};

export { Faq };
