import { Stack, type StackProps } from '@carvertical/ui';
import { Fragment } from 'react';
import type { PaymentMethod } from 'types/payment';
import { useRouteData } from 'context/RouteDataProvider';
import { usePricing } from 'context/PricingProvider';
import { PAYMENT_METHODS } from 'components/CheckoutPage/paymentMethods';
import { PaymentLogo } from './PaymentLogo';

type Alignment = 'center' | 'left' | 'right';

type PaymentLogosProps = {
  id?: PaymentMethod;
  align?: Alignment;
  height?: number;
  collapsedCountShown?: boolean;
  excludePrimaryPaymentOptions?: boolean;
};

const ALIGNMENT_MAP: Record<Alignment, StackProps['mainAxisAlign']> = {
  left: 'flexStart',
  center: 'center',
  right: 'flexEnd',
};
// TODO: [LAUNCH-2332] - enable crypto payments once alternative to Coingate is ready
// const HIDDEN_METHOD_LOGOS = [PaymentMethod.Crypto];

const PaymentLogos = ({
  align = 'center',
  collapsedCountShown = false,
  excludePrimaryPaymentOptions = false,
  height = 18,
  id,
}: PaymentLogosProps) => {
  const { market } = useRouteData();

  const pricing = usePricing('standard');
  const price = !pricing.fetching
    ? {
        amount: pricing?.price,
        currency: pricing?.currency,
      }
    : undefined;

  const methods = id
    ? [PAYMENT_METHODS[id]]
    : market.checkoutMethods.map((method) => PAYMENT_METHODS[method]);

  // TODO: [LAUNCH-2332] - enable crypto payments once alternative to Coingate is ready
  // const methods = id
  //   ? [PAYMENT_METHODS[id]]
  //   : market.checkoutMethods
  //       .map((method) => PAYMENT_METHODS[method])
  //       .filter((method) => !HIDDEN_METHOD_LOGOS.includes(method.id));

  return (
    <Stack
      as="ul"
      className="unstyledList"
      gap={1}
      type="horizontal"
      mainAxisAlign={ALIGNMENT_MAP[align]}
      crossAxisAlign="center"
      wrap
    >
      {methods.map(({ LogosComponent, logos }) => (
        <Fragment key={id}>
          {logos?.map((logo) => (
            <PaymentLogo key={logo} height={height} logo={logo} title={logo} />
          ))}

          {LogosComponent && price && (
            <LogosComponent
              height={height}
              price={price}
              collapsedCountShown={collapsedCountShown}
              excludePrimaryPaymentOptions={excludePrimaryPaymentOptions}
            />
          )}
        </Fragment>
      ))}
    </Stack>
  );
};

export { PaymentLogos };
export type { PaymentLogosProps };
