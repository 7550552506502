import { useState as t } from "react";
import { h as o, i as r, p as i, w as a } from "./motion.bdfaf94a.js";
import "./cn.2ea4f521.js";
import { a as c } from "./cva.88fc6b72.js";
function v() {
  !o.current && r();
  const [e] = t(i.current);
  return process.env.NODE_ENV !== "production" && a(e !== !0, "You have Reduced Motion enabled on your device. Animations may not appear as expected."), e;
}
const u = c({
  base: [
    "twp inline-flex items-center justify-center whitespace-nowrap",
    "rounded-full shadow-[inset_0_0_0_1px_var(--buttonBorderColor)]",
    "transition active:scale-[0.98]",
    "outline-none outline-offset-0 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-300"
  ],
  variants: {
    variant: {
      yellow: [
        "bg-yellow text-black",
        "hover:bg-yellow-500 hover:active:bg-yellow-600 active:bg-yellow-600"
      ],
      blue: ["bg-blue text-white", "hover:bg-blue-700 hover:active:bg-blue-800 active:bg-blue-800"],
      black: [
        "bg-black text-white",
        "hover:bg-grey-800 hover:active:bg-grey-700 active:bg-grey-700"
      ],
      outlined: [
        "[--buttonBorderColor:theme(colors.black)] text-black",
        "hover:bg-black hover:text-white hover:active:bg-grey-800 active:bg-grey-800"
      ],
      white: [
        "bg-white text-black",
        "hover:bg-grey-200 hover:active:bg-grey-400 active:bg-grey-400",
        "hover:border-grey-200 hover:active:border-grey-400 active:border-grey-400"
      ],
      outlinedWhite: [
        "bg-transparent [--buttonBorderColor:theme(colors.white)] text-white",
        "hover:bg-grey-200 hover:text-black hover:active:bg-grey-400 active:bg-grey-400",
        "active:[--buttonBorderColor:theme(colors.grey.400)] hover:[--buttonBorderColor:theme(colors.grey.200)] hover:active:[--buttonBorderColor:theme(colors.grey.400)]"
      ],
      transparent: [
        "bg-transparent text-black",
        "hover:bg-grey-200 hover:active:bg-grey-400 active:bg-grey-400"
      ],
      red: ["bg-red-600 text-white", "hover:bg-red-700 hover:active:bg-red-800 active:bg-red-800"]
    },
    disabled: {
      true: "active:scale-100"
    },
    loading: {
      true: "pointer-events-none"
    }
  },
  compoundVariants: [
    {
      disabled: !0,
      loading: [!0, !1],
      className: [
        "bg-grey-400 text-white cursor-not-allowed",
        "hover:bg-grey-400 active:bg-grey-400 hover:active:bg-grey-400",
        "active:scale-100",
        "[--buttonBorderColor:theme(colors.grey.400)]"
      ]
    }
  ],
  defaultVariants: {
    variant: "blue"
  }
}), n = {
  duration: 0.5,
  ease: [0.42, 0, 0.12, 1],
  opacity: {
    duration: 0.35,
    ease: [0.42, 0, 0.58, 1]
  }
}, d = {
  initial: { opacity: 0, y: "-100%", scale: 0.9 },
  visible: { opacity: 1, y: 0, scale: 1 },
  exit: { opacity: 0, y: "100%", scale: 0.9 }
}, y = (e) => ({
  initial: {
    x: e ? 0 : "-100%",
    y: e ? "-100%" : 0,
    opacity: 0,
    scale: 0.9
  },
  visible: {
    x: 0,
    y: 0,
    opacity: 1,
    scale: 1
  },
  exit: {
    y: e ? "100%" : 0,
    opacity: 0,
    scale: 0.9,
    transition: e ? n : { duration: 0 }
  }
});
export {
  d as I,
  n as S,
  u as b,
  y as g,
  v as u
};
