import { a as b, b as m } from "./useSelectableItem.ca3ec579.js";
import v, { useMemo as L, createContext as y } from "react";
import { a as w } from "./ListBox.ba6e4e6c.js";
import { d as u, b as x, e as S, $ as E } from "./utils.371a0d8b.js";
function U(a, e) {
  let t = a;
  for (b(t, e) && (t = t.parentElement); t && !b(t, e); )
    t = t.parentElement;
  return t || document.scrollingElement || document.documentElement;
}
const P = Symbol.for("react-aria.i18n.locale"), C = Symbol.for("react-aria.i18n.strings");
let l;
class d {
  /** Returns a localized string for the given key and locale. */
  getStringForLocale(e, t) {
    let n = this.getStringsForLocale(t)[e];
    if (!n)
      throw new Error(`Could not find intl message ${e} in ${t} locale`);
    return n;
  }
  /** Returns all localized strings for the given locale. */
  getStringsForLocale(e) {
    let t = this.strings[e];
    return t || (t = I(e, this.strings, this.defaultLocale), this.strings[e] = t), t;
  }
  static getGlobalDictionaryForPackage(e) {
    if (typeof window > "u")
      return null;
    let t = window[P];
    if (l === void 0) {
      let n = window[C];
      if (!n)
        return null;
      l = {};
      for (let o in n)
        l[o] = new d({
          [t]: n[o]
        }, t);
    }
    let r = l == null ? void 0 : l[e];
    if (!r)
      throw new Error(`Strings for package "${e}" were not included by LocalizedStringProvider. Please add it to the list passed to createLocalizedStringDictionary.`);
    return r;
  }
  constructor(e, t = "en-US") {
    this.strings = Object.fromEntries(Object.entries(e).filter(([, r]) => r)), this.defaultLocale = t;
  }
}
function I(a, e, t = "en-US") {
  if (e[a])
    return e[a];
  let r = M(a);
  if (e[r])
    return e[r];
  for (let n in e)
    if (n.startsWith(r + "-"))
      return e[n];
  return e[t];
}
function M(a) {
  return Intl.Locale ? new Intl.Locale(a).language : a.split("-")[0];
}
const $ = /* @__PURE__ */ new Map(), p = /* @__PURE__ */ new Map();
class F {
  /** Formats a localized string for the given key with the provided variables. */
  format(e, t) {
    let r = this.strings.getStringForLocale(e, this.locale);
    return typeof r == "function" ? r(t, this) : r;
  }
  plural(e, t, r = "cardinal") {
    let n = t["=" + e];
    if (n)
      return typeof n == "function" ? n() : n;
    let o = this.locale + ":" + r, i = $.get(o);
    i || (i = new Intl.PluralRules(this.locale, {
      type: r
    }), $.set(o, i));
    let s = i.select(e);
    return n = t[s] || t.other, typeof n == "function" ? n() : n;
  }
  number(e) {
    let t = p.get(this.locale);
    return t || (t = new Intl.NumberFormat(this.locale), p.set(this.locale, t)), t.format(e);
  }
  select(e, t) {
    let r = e[t] || e.other;
    return typeof r == "function" ? r() : r;
  }
  constructor(e, t) {
    this.locale = e, this.strings = t;
  }
}
const h = /* @__PURE__ */ new WeakMap();
function A(a) {
  let e = h.get(a);
  return e || (e = new d(a), h.set(a, e)), e;
}
function T(a, e) {
  return e && d.getGlobalDictionaryForPackage(e) || A(a);
}
function _(a, e) {
  let { locale: t } = m(), r = T(a, e);
  return L(() => new F(t, r), [
    t,
    r
  ]);
}
const g = 7e3;
let c = null;
function z(a, e = "assertive", t = g) {
  c || (c = new B()), c.announce(a, e, t);
}
class B {
  createLog(e) {
    let t = document.createElement("div");
    return t.setAttribute("role", "log"), t.setAttribute("aria-live", e), t.setAttribute("aria-relevant", "additions"), t;
  }
  destroy() {
    this.node && (document.body.removeChild(this.node), this.node = null);
  }
  announce(e, t = "assertive", r = g) {
    if (!this.node)
      return;
    let n = document.createElement("div");
    n.textContent = e, t === "assertive" ? this.assertiveLog.appendChild(n) : this.politeLog.appendChild(n), e !== "" && setTimeout(() => {
      n.remove();
    }, r);
  }
  clear(e) {
    this.node && ((!e || e === "assertive") && (this.assertiveLog.innerHTML = ""), (!e || e === "polite") && (this.politeLog.innerHTML = ""));
  }
  constructor() {
    this.node = document.createElement("div"), this.node.dataset.liveAnnouncer = "true", Object.assign(this.node.style, {
      border: 0,
      clip: "rect(0 0 0 0)",
      clipPath: "inset(50%)",
      height: "1px",
      margin: "-1px",
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      width: "1px",
      whiteSpace: "nowrap"
    }), this.assertiveLog = this.createLog("assertive"), this.node.appendChild(this.assertiveLog), this.politeLog = this.createLog("polite"), this.node.appendChild(this.politeLog), document.body.prepend(this.node);
  }
}
function H(a) {
  let { description: e, errorMessage: t, isInvalid: r, validationState: n } = a, { labelProps: o, fieldProps: i } = w(a), s = u([
    !!e,
    !!t,
    r,
    n
  ]), f = u([
    !!e,
    !!t,
    r,
    n
  ]);
  return i = x(i, {
    "aria-describedby": [
      s,
      // Use aria-describedby for error message because aria-errormessage is unsupported using VoiceOver or NVDA. See https://github.com/adobe/react-spectrum/issues/1346#issuecomment-740136268
      f,
      a["aria-describedby"]
    ].filter(Boolean).join(" ") || void 0
  }), {
    labelProps: o,
    fieldProps: i,
    descriptionProps: {
      id: s
    },
    errorMessageProps: {
      id: f
    }
  };
}
const D = /* @__PURE__ */ y({});
function R(a, e) {
  [a, e] = E(a, e, D);
  let { elementType: t = "label", ...r } = a;
  return /* @__PURE__ */ v.createElement(t, {
    className: "react-aria-Label",
    ...r,
    ref: e
  });
}
const N = /* @__PURE__ */ S(R);
export {
  _ as $,
  z as a,
  U as b,
  H as c,
  D as d,
  N as e
};
