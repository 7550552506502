import type { PortableTextMarkComponentProps } from '@portabletext/react';
import { Link } from 'components/common/Link';
import ExternalLink from 'components/common/ExternalLink';
import type { LinkData } from '../../types';
import styles from './SanityLink.module.scss';

type SanityLinkProps = PortableTextMarkComponentProps<LinkData>;

const SanityLink = ({ children, value }: SanityLinkProps) => {
  if (!value) {
    return <>children</>;
  }

  if (value.linkType && ['external', 'externalNoRel'].includes(value.linkType)) {
    return (
      <ExternalLink
        href={value.url}
        className={styles.root}
        noRel={value.linkType === 'externalNoRel'}
      >
        {children}
      </ExternalLink>
    );
  }

  const to = value.internalLink?.pathname;

  if (!to) {
    return children;
  }

  return (
    <Link to={to} className={styles.root}>
      {children}
    </Link>
  );
};

export { SanityLink };
export type { SanityLinkProps };
