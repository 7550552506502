import o, { useRef as s, useCallback as m, useState as d, useContext as p, useEffect as H, useMemo as h, createContext as P } from "react";
import { c as j } from "./clsx.0d472180.js";
import C from "react-dom";
const y = typeof document < "u" ? o.useLayoutEffect : () => {
};
function g(e) {
  const t = s(null);
  return y(() => {
    t.current = e;
  }, [
    e
  ]), m((...n) => {
    const r = t.current;
    return r == null ? void 0 : r(...n);
  }, []);
}
function k(e) {
  let [t, n] = d(e), r = s(null), a = g(() => {
    if (!r.current)
      return;
    let u = r.current.next();
    if (u.done) {
      r.current = null;
      return;
    }
    t === u.value ? a() : n(u.value);
  });
  y(() => {
    r.current && a();
  });
  let l = g((u) => {
    r.current = u(t), a();
  });
  return [
    t,
    l
  ];
}
const b = {
  prefix: String(Math.round(Math.random() * 1e10)),
  current: 0
}, M = /* @__PURE__ */ o.createContext(b), I = /* @__PURE__ */ o.createContext(!1);
let F = !!(typeof window < "u" && window.document && window.document.createElement), v = /* @__PURE__ */ new WeakMap();
function q(e = !1) {
  let t = p(M), n = s(null);
  if (n.current === null && !e) {
    var r, a;
    let l = (a = o.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED) === null || a === void 0 || (r = a.ReactCurrentOwner) === null || r === void 0 ? void 0 : r.current;
    if (l) {
      let u = v.get(l);
      u == null ? v.set(l, {
        id: t.current,
        state: l.memoizedState
      }) : l.memoizedState !== u.state && (t.current = u.id, v.delete(l));
    }
    n.current = ++t.current;
  }
  return n.current;
}
function z(e) {
  let t = p(M);
  t === b && !F && console.warn("When server rendering, you must wrap your application in an <SSRProvider> to ensure consistent ids are generated between the client and server.");
  let n = q(!!e), r = t === b && process.env.NODE_ENV === "test" ? "react-aria" : `react-aria${t.prefix}`;
  return e || `${r}-${n}`;
}
function D(e) {
  let t = o.useId(), [n] = d(E()), r = n || process.env.NODE_ENV === "test" ? "react-aria" : `react-aria${b.prefix}`;
  return e || `${r}-${t}`;
}
const K = typeof o.useId == "function" ? D : z;
function A() {
  return !1;
}
function G() {
  return !0;
}
function J(e) {
  return () => {
  };
}
function E() {
  return typeof o.useSyncExternalStore == "function" ? o.useSyncExternalStore(J, A, G) : p(I);
}
let Q = !!(typeof window < "u" && window.document && window.document.createElement), x = /* @__PURE__ */ new Map();
function W(e) {
  let [t, n] = d(e), r = s(null), a = K(t), l = m((u) => {
    r.current = u;
  }, []);
  return Q && x.set(a, l), y(() => {
    let u = a;
    return () => {
      x.delete(u);
    };
  }, [
    a
  ]), H(() => {
    let u = r.current;
    u && (r.current = null, n(u));
  }), a;
}
function X(e, t) {
  if (e === t)
    return e;
  let n = x.get(e);
  if (n)
    return n(t), t;
  let r = x.get(t);
  return r ? (r(e), e) : t;
}
function ee(e = []) {
  let t = W(), [n, r] = k(t), a = m(() => {
    r(function* () {
      yield t, yield document.getElementById(t) ? t : void 0;
    });
  }, [
    t,
    r
  ]);
  return y(a, [
    t,
    a,
    ...e
  ]), n;
}
function Z(...e) {
  return (...t) => {
    for (let n of e)
      typeof n == "function" && n(...t);
  };
}
function L(...e) {
  let t = {
    ...e[0]
  };
  for (let n = 1; n < e.length; n++) {
    let r = e[n];
    for (let a in r) {
      let l = t[a], u = r[a];
      typeof l == "function" && typeof u == "function" && // This is a lot faster than a regex.
      a[0] === "o" && a[1] === "n" && a.charCodeAt(2) >= /* 'A' */
      65 && a.charCodeAt(2) <= /* 'Z' */
      90 ? t[a] = Z(l, u) : (a === "className" || a === "UNSAFE_className") && typeof l == "string" && typeof u == "string" ? t[a] = j(l, u) : a === "id" && l && u ? t.id = X(l, u) : t[a] = u !== void 0 ? u : l;
    }
  }
  return t;
}
function U(...e) {
  return e.length === 1 ? e[0] : (t) => {
    for (let n of e)
      typeof n == "function" ? n(t) : n != null && (n.current = t);
  };
}
function Y(e) {
  const t = s(null);
  return h(() => ({
    get current() {
      return t.current;
    },
    set current(n) {
      t.current = n, typeof e == "function" ? e(n) : e && (e.current = n);
    }
  }), [
    e
  ]);
}
const w = Symbol("default");
function te({ values: e, children: t }) {
  for (let [n, r] of e)
    t = /* @__PURE__ */ o.createElement(n.Provider, {
      value: r
    }, t);
  return t;
}
function ne(e) {
  let { className: t, style: n, children: r, defaultClassName: a, defaultChildren: l, defaultStyle: u, values: i } = e;
  return h(() => {
    let f, $, c;
    return typeof t == "function" ? f = t({
      ...i,
      defaultClassName: a
    }) : f = t, typeof n == "function" ? $ = n({
      ...i,
      defaultStyle: u || {}
    }) : $ = n, typeof r == "function" ? c = r({
      ...i,
      defaultChildren: l
    }) : r == null ? c = l : c = r, {
      className: f ?? a,
      style: $ || u ? {
        ...u,
        ...$
      } : void 0,
      children: c ?? l,
      "data-rac": ""
    };
  }, [
    t,
    n,
    r,
    a,
    l,
    u,
    i
  ]);
}
function B(e, t) {
  let n = p(e);
  if (t === null)
    return null;
  if (n && typeof n == "object" && "slots" in n && n.slots) {
    let r = new Intl.ListFormat().format(Object.keys(n.slots).map((l) => `"${l}"`));
    if (!t && !n.slots[w])
      throw new Error(`A slot prop is required. Valid slot names are ${r}.`);
    let a = t || w;
    if (!n.slots[a])
      throw new Error(`Invalid slot "${t}". Valid slot names are ${r}.`);
    return n.slots[a];
  }
  return n;
}
function re(e, t, n) {
  let r = B(n, e.slot) || {}, { ref: a, ...l } = r, u = Y(h(() => U(t, a), [
    t,
    a
  ])), i = L(l, e);
  return "style" in l && l.style && "style" in e && e.style && (typeof l.style == "function" || typeof e.style == "function" ? i.style = (f) => {
    let $ = typeof l.style == "function" ? l.style(f) : l.style, c = {
      ...f.defaultStyle,
      ...$
    }, V = typeof e.style == "function" ? e.style({
      ...f,
      defaultStyle: c
    }) : e.style;
    return {
      ...c,
      ...V
    };
  } : i.style = {
    ...l.style,
    ...e.style
  }), [
    i,
    u
  ];
}
function ae() {
  let [e, t] = d(!0), n = s(!1), r = m((a) => {
    n.current = !0, t(!!a);
  }, []);
  return y(() => {
    n.current || t(!1);
  }, []), [
    r,
    e
  ];
}
function le(e, t = !0) {
  let [n, r] = d(!0);
  return N(e, n && t, m(() => r(!1), [])), n && t;
}
function ue(e, t) {
  let [n, r] = d(!1), [a, l] = d("idle");
  return !t && e.current && a === "idle" && (n = !0, r(!0), l("exiting")), !e.current && a === "exited" && l("idle"), N(e, n, m(() => {
    l("exited"), r(!1);
  }, [])), n;
}
function N(e, t, n) {
  let r = s(null);
  t && e.current && (r.current = window.getComputedStyle(e.current).animation), y(() => {
    if (t && e.current) {
      let a = window.getComputedStyle(e.current);
      if (a.animationName && a.animationName !== "none" && a.animation !== r.current) {
        let l = (i) => {
          i.target === e.current && (u.removeEventListener("animationend", l), C.flushSync(() => {
            n();
          }));
        }, u = e.current;
        return u.addEventListener("animationend", l), () => {
          u.removeEventListener("animationend", l);
        };
      } else
        n();
    }
  }, [
    e,
    t,
    n
  ]);
}
if (typeof HTMLTemplateElement < "u") {
  const e = Object.getOwnPropertyDescriptor(Node.prototype, "firstChild").get;
  Object.defineProperty(HTMLTemplateElement.prototype, "firstChild", {
    configurable: !0,
    enumerable: !0,
    get: function() {
      return this.dataset.reactAriaHidden ? this.content.firstChild : e.call(this);
    }
  });
}
const S = /* @__PURE__ */ P(!1), O = typeof DocumentFragment < "u" ? new DocumentFragment() : null;
function oe(e) {
  let t = p(S), n = E();
  if (t)
    return /* @__PURE__ */ o.createElement(o.Fragment, null, e.children);
  let r = /* @__PURE__ */ o.createElement(S.Provider, {
    value: !0
  }, e.children);
  return n ? /* @__PURE__ */ o.createElement("template", {
    "data-react-aria-hidden": !0
  }, r) : /* @__PURE__ */ C.createPortal(r, O);
}
function ie(e) {
  let t = (n, r) => p(S) ? null : e(n, r);
  return t.displayName = e.displayName || e.name, o.forwardRef(t);
}
function fe(e) {
  const t = /^(data-.*)$/;
  let n = {};
  for (const r in e)
    t.test(r) || (n[r] = e[r]);
  return n;
}
export {
  re as $,
  y as a,
  L as b,
  ne as c,
  ee as d,
  ie as e,
  E as f,
  W as g,
  g as h,
  oe as i,
  te as j,
  B as k,
  Y as l,
  Z as m,
  S as n,
  ae as o,
  U as p,
  ue as q,
  le as r,
  fe as s
};
