import { j as a } from "./jsx-runtime.82a9ddf6.js";
import { createContext as r, useMemo as s } from "react";
const i = {
  loading: "Loading",
  closeStatusMessage: "Close",
  closeModal: "Close",
  clearInput: "Clear input",
  explanationButtonTitle: "Explanation",
  goToNextPage: "Go to next page",
  goToPreviousPage: "Go to previous page",
  goToPage: "Go to page",
  pagination: "Pagination",
  europe: "Europe",
  unknownCountry: "Unknown"
}, l = r(null), t = ({ children: e, translations: o }) => {
  const n = s(
    () => ({ ...i, ...o }),
    [o]
  );
  return /* @__PURE__ */ a.jsx(l.Provider, { value: n, children: e });
};
try {
  t.displayName = "TranslatableUIProvider", t.__docgenInfo = { description: "", displayName: "TranslatableUIProvider", props: { translations: { defaultValue: null, description: "", name: "translations", required: !0, type: { name: "Partial<{ loading: string; closeStatusMessage: string; closeModal: string; clearInput: string; explanationButtonTitle: string; goToNextPage: string; goToPreviousPage: string; goToPage: string; pagination: string; europe: string; unknownCountry: string; }>" } } } };
} catch {
}
try {
  Context.displayName = "Context", Context.__docgenInfo = { description: `Context lets components pass information deep down without explicitly
passing props.

Created from {@link createContext}`, displayName: "Context", props: {} };
} catch {
}
export {
  i as D,
  t as T,
  l as U
};
