import { Button, Checkbox, LinkButton, Stack, TextArea, TextField } from '@carvertical/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import cx from 'classnames';
import { AnimatePresence, type MotionProps, motion } from 'framer-motion';
import { find, isBoolean } from 'lodash';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRouteData } from 'context/RouteDataProvider';
import { useInquiryMutation } from 'modules/inquiry/hooks';
import { FormField } from 'components/common/ui/forms';
import { Select } from 'components/common/ui/forms/Select';
import { loadNamespaces } from 'services/i18n';
import type { SupportSubject } from 'types/content';
import { Link } from 'components/common/Link';
import { MAIN_ACTIVITIES } from 'modules/business/constants';
import { SUBJECTS } from './constants';
import { type SupportFormFields, getSupportFormSchema } from './schemas';
import styles from './SupportForm.module.scss';

type SupportFormProps = {
  textAreaRows?: number;
  twoColumnsForm?: boolean;
  defaultSubject?: SupportSubject;
  privacyCheckboxShown?: boolean;
  onSuccess: () => void;
};

const ANIMATION_VARIANTS = {
  initial: { opacity: 0, height: 0 },
  animate: { opacity: 1, height: 'auto' },
  exit: { opacity: 0, height: 0 },
};

const ANIMATION_TRANSITION = {
  duration: 0.2,
  ease: 'easeInOut',
};

const ANIMATION_PROPS: MotionProps = {
  variants: ANIMATION_VARIANTS,
  transition: ANIMATION_TRANSITION,
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
};

const WHITESPACE_REGEXP = /[-\s]/g;

const SupportForm = ({
  defaultSubject = 'no-report',
  twoColumnsForm = false,
  privacyCheckboxShown = false,
  textAreaRows = 4,
  onSuccess,
}: SupportFormProps) => {
  const { t } = useTranslation(['common', 'business']);
  const { locale, market } = useRouteData();
  const { sendingInquiry, sendInquiry } = useInquiryMutation<{ vin?: string }>(onSuccess);

  const { identifier } = market;

  const defaultValues = { subject: defaultSubject };

  const form = useForm<SupportFormFields>({
    defaultValues,
    resolver: zodResolver(getSupportFormSchema(identifier, privacyCheckboxShown)),
  });

  useEffect(() => {
    loadNamespaces(['business']);
  }, []);

  const { handleSubmit, watch } = form;

  const subjectValue = watch('subject');
  const selectedSubject = subjectValue ? find(SUBJECTS, { id: subjectValue }) : undefined;

  const vinShown = isBoolean(selectedSubject?.vin);
  const businessCooperationSelected = selectedSubject?.id === 'business/2';

  const submit = ({ subject, vin, ...values }: SupportFormFields) => {
    sendInquiry({
      subject: [subject.split('/')[0], selectedSubject?.labelKey ? t(selectedSubject.labelKey) : '']
        .filter(Boolean)
        .join('|'),
      market: market?.id,
      vin: vin?.replace(WHITESPACE_REGEXP, '').toUpperCase(),
      locale,
      ...values,
    });
  };

  const Field = FormField<SupportFormFields>;

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={handleSubmit(submit)}
        className={cx(styles.root, { [styles.twoColumns]: twoColumnsForm })}
      >
        <Stack as="section" gap={2} className={styles.leftColumn} crossAxisAlign="stretch">
          <Field name="email">
            <TextField
              placeholder={t('footer.emailPlaceholder')}
              type="email"
              label={t('supportModal.emailLabel')}
              fullWidth
              disabled={sendingInquiry}
            />
          </Field>

          <Field name="subject">
            <Select
              label={t('supportModal.subjectLabel')}
              options={SUBJECTS.map(({ id, labelKey }) => ({
                label: t(labelKey),
                value: id,
              }))}
              disabled={sendingInquiry}
              fullWidth
            />
          </Field>

          <AnimatePresence mode="wait">
            {vinShown && (
              <motion.div {...ANIMATION_PROPS} key="vin">
                <Field name="vin">
                  <TextField
                    placeholder="1VWBN7A35CCXXXXXX"
                    label={t('supportModal.vinLabel')}
                    fullWidth
                    disabled={sendingInquiry}
                  />
                </Field>
              </motion.div>
            )}

            {businessCooperationSelected && (
              <motion.div {...ANIMATION_PROPS} key="business">
                <Stack gap={2}>
                  <Field name="companyName">
                    <TextField
                      label={t('business:contactSection.placeholders.companyName')}
                      fullWidth
                      disabled={sendingInquiry}
                    />
                  </Field>

                  <Field name="mainActivity">
                    <Select
                      fullWidth
                      label={t('business:contactSection.placeholders.activity')}
                      options={MAIN_ACTIVITIES.map(({ labelKey }) => ({
                        value: t(`business:${labelKey}`),
                        label: t(`business:${labelKey}`),
                      }))}
                    />
                  </Field>
                </Stack>
              </motion.div>
            )}
          </AnimatePresence>
        </Stack>

        <section className={styles.rightColumn}>
          <Field name="message">
            <TextArea
              className={styles.messageField}
              label={t('supportModal.messageLabel')}
              rows={textAreaRows}
              disabled={sendingInquiry}
            />
          </Field>
        </section>

        <section className={styles.footer}>
          {privacyCheckboxShown && (
            <Field name="privacy">
              <Checkbox
                label={
                  <Trans
                    i18nKey="business:contactSection.termsAccepted"
                    t={t}
                    as="span"
                    components={{
                      1: (
                        <LinkButton
                          as={Link}
                          href="/policies"
                          target="_blank"
                          size="m"
                          variant="blue"
                        />
                      ),
                    }}
                  />
                }
                className={styles.policyCheckbox}
                disabled={sendingInquiry}
              />
            </Field>
          )}
          <Button
            className={styles.submitButton}
            type="submit"
            fullWidth={!twoColumnsForm}
            variant="yellow"
            loading={sendingInquiry}
            size={twoColumnsForm ? 'l' : 'm'}
          >
            {sendingInquiry ? `${t('general.processingLabel')}…` : t('general.sendAction')}
          </Button>
        </section>
      </form>
    </FormProvider>
  );
};

export { SupportForm };
export type { SupportFormProps };
