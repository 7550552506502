import { PortableText } from '@portabletext/react';
import cx from 'classnames';
import type { TableBlockData } from '../../../types';

const TableBlock = ({ rootHtmlAttributes, rows }: TableBlockData) => (
  <table className="my-2 w-full border-collapse" {...rootHtmlAttributes}>
    <tbody>
      {rows.map(({ cells }, rowIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <tr key={rowIndex}>
          {cells.map(({ content }, cellIndex) => (
            // eslint-disable-next-line react/no-array-index-key, jsx-a11y/control-has-associated-label
            <td key={cellIndex} className={cx('p-1', rowIndex % 2 ? 'bg-grey-50' : 'bg-grey-300')}>
              <PortableText value={content} />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export { TableBlock };
