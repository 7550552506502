import { j as r } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as e } from "./chunks/clsx.0d472180.js";
import { b as i } from "./chunks/Tabs.5ab9d535.js";
import { m as s } from "./chunks/motion.bdfaf94a.js";
import "react";
import "./chunks/Collection.e8e46ab5.js";
import "react-dom";
import "./chunks/utils.371a0d8b.js";
import "./chunks/useFocusRing.91eeed6d.js";
import "./chunks/useSelectableItem.ca3ec579.js";
import "./chunks/useHasTabbableChild.8b7adf4c.js";
const a = ({ className: t, ...o }) => /* @__PURE__ */ r.jsx(s.div, { layoutScroll: !0, className: "relative -m-1 flex snap-x snap-mandatory overflow-x-auto p-1", children: /* @__PURE__ */ r.jsx(
  i,
  {
    ...o,
    className: e(
      t,
      "relative flex flex-1",
      // Bottom border
      'after:absolute after:-inset-x-0 after:bottom-0 after:block after:h-[2px] after:bg-grey-200 after:content-[""]'
    )
  }
) });
try {
  a.displayName = "TabList", a.__docgenInfo = { description: "", displayName: "TabList", props: {} };
} catch {
}
export {
  a as TabList
};
