import { j as i } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as r } from "./chunks/cn.2ea4f521.js";
import { T as o } from "./chunks/TypographyElement.0309f27c.js";
import "react";
const t = ({ variant: e = "m", as: l = "p", className: a, children: s, ...n }) => /* @__PURE__ */ i.jsx(
  o,
  {
    ...n,
    as: l,
    className: r(
      e === "l" && "text-l",
      e === "l+" && "text-l+",
      e === "m+" && "text-m+",
      e === "m" && "text-m",
      e === "s+" && "text-s+",
      e === "s" && "text-s",
      e === "xs+" && "text-xs+",
      e === "xs" && "text-xs",
      e === "2xs+" && "text-2xs+",
      e === "2xs" && "text-2xs",
      a
    ),
    children: s
  }
);
try {
  t.displayName = "Text", t.__docgenInfo = { description: "", displayName: "Text", props: { as: { defaultValue: { value: "p" }, description: 'This prop controls what HTML tag to render (**it does not change styling**).\nExample: set `variant="h2"` and `as="span"`, which will render `<span>` element with H2 styles.', name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, align: { defaultValue: null, description: "", name: "align", required: !1, type: { name: '"center" | "left" | "right" | "justify"' } }, textColor: { defaultValue: null, description: "", name: "textColor", required: !1, type: { name: '"dark" | "light" | "darkSecondary" | "highlighted" | "inherited"' } }, italic: { defaultValue: null, description: "", name: "italic", required: !1, type: { name: "boolean" } }, inline: { defaultValue: null, description: "", name: "inline", required: !1, type: { name: "boolean" } }, variant: { defaultValue: { value: "m" }, description: "", name: "variant", required: !1, type: { name: "TextVariant" } } } };
} catch {
}
export {
  t as Text
};
