import { Heading, Text, Button, LinkButton } from '@carvertical/ui';
import cx from 'classnames';
import { IdentifierForm } from 'components/common/IdentifierForm';
import { SanityImage } from '../SanityImage';
import type { VinCheckBlockData } from '../../../types';

type VinCheckProps = VinCheckBlockData;

const V3_LANDING_URL = '/landing/v3';

const VinCheck = ({ buttonText, image, subtitle, title, type, url, background }: VinCheckProps) => {
  const bgBlack = background === 'black';

  return (
    <div
      className={cx('mt-3 flex flex-col items-start gap-2 rounded-s p-3 sm:p-5', {
        'border border-solid border-grey-400 bg-grey-50': background === 'white',
        'bg-blue-300': background === 'blue',
        'bg-black': bgBlack,
      })}
    >
      {image.asset && (
        <div className="flex h-full flex-row items-center">
          <SanityImage
            className="max-h-15 object-contain"
            width="100%"
            image={{ ...image, asset: image.asset }}
          />
        </div>
      )}

      <div
        className={cx(
          'flex flex-col gap-3',
          type === 'link' ? 'items-start' : 'items-stretch',
          type === 'input' ? 'sm:items-stretch' : 'sm:items-start',
        )}
      >
        <div className="flex flex-col items-start gap-1">
          <Heading as="h3" variant="m" textColor={bgBlack ? 'light' : 'dark'}>
            {title}
          </Heading>

          <Text variant="l" textColor={bgBlack ? 'light' : 'dark'}>
            {subtitle}
          </Text>
        </div>

        {type === 'button' && (
          <Button as="a" href={url || V3_LANDING_URL} variant="yellow" size="l">
            {buttonText}
          </Button>
        )}

        {type === 'link' && (
          <LinkButton as="a" href={url || V3_LANDING_URL} variant={bgBlack ? 'white' : 'black'}>
            {buttonText}
          </LinkButton>
        )}

        {type === 'input' && (
          <IdentifierForm
            className="!max-w-full"
            continueWithoutVinCtaVisible={false}
            surroundingBackground={background === 'white' ? 'light' : 'dark'}
          />
        )}
      </div>
    </div>
  );
};

export { VinCheck };
export type { VinCheckProps };
