import { NS_AFFILIATE, NS_AUTH, NS_HOME } from 'constants/i18n';
import type { Faq } from 'types/content';
import { createStaticRoute } from 'modules/prerender/createRoute';
import { bySlug } from 'modules/prerender/services/faq';

type RouteData = {
  faq: Faq[];
};

const { getStaticPaths, getStaticProps } = createStaticRoute({
  id: 'affiliate-program',
  translateNamespace: [NS_HOME, NS_AFFILIATE, NS_AUTH],
  getData: ({ faq }: RouteData) => ({
    preventGeoModal: true,
    faq: faq.filter(bySlug('partner-program')),
  }),
});

export { AffiliatePage as default } from 'components/landings/AffiliatePage';
export { getStaticPaths, getStaticProps };
