export default [
  {
    countryCode: 'at',
    rate: 20,
  },
  {
    countryCode: 'be',
    rate: 21,
  },
  {
    countryCode: 'bg',
    rate: 20,
  },
  {
    countryCode: 'hr',
    rate: 25,
  },
  {
    countryCode: 'cy',
    rate: 19,
  },
  {
    countryCode: 'cz',
    rate: 21,
  },
  {
    countryCode: 'dk',
    rate: 25,
  },
  {
    countryCode: 'ee',
    rate: 22,
  },
  {
    countryCode: 'fi',
    rate: 25.5,
  },
  {
    countryCode: 'fr',
    rate: 20,
  },
  {
    countryCode: 'de',
    rate: 19,
  },
  {
    countryCode: 'gr',
    rate: 24,
  },
  {
    countryCode: 'hu',
    rate: 27,
  },
  {
    countryCode: 'ie',
    rate: 23,
  },
  {
    countryCode: 'it',
    rate: 22,
  },
  {
    countryCode: 'lv',
    rate: 21,
  },
  {
    countryCode: 'lt',
    rate: 21,
  },
  {
    countryCode: 'lu',
    rate: 17,
  },
  {
    countryCode: 'mt',
    rate: 18,
  },
  {
    countryCode: 'nl',
    rate: 21,
  },
  {
    countryCode: 'pl',
    rate: 23,
  },
  {
    countryCode: 'pt',
    rate: 23,
  },
  {
    countryCode: 'ro',
    rate: 19,
  },
  {
    countryCode: 'sk',
    rate: 23,
  },
  {
    countryCode: 'si',
    rate: 22,
  },
  {
    countryCode: 'es',
    rate: 21,
  },
  {
    countryCode: 'se',
    rate: 25,
  },
];
