import { useRouter } from 'next/router';
import { useMedia } from 'react-use';
import { useTranslation } from 'next-i18next';
import { Sidetab } from '@typeform/embed-react';
import { theme } from '@carvertical/ui';
import { useRouteData } from 'context/RouteDataProvider';
import { createMediaQuery } from 'utils/styles';
import type { OptionalMarketIdRecord } from 'types/market';

const SIDETAB_AUTO_OPEN_TIME = 30_000;
const SIDETAB_IDS: OptionalMarketIdRecord<string> = {
  finland: 'rxKVrmfw',
  serbia: 'TWDo0wU5',
  ukraine: 'OeqYaCNN',
};
const EXCLUDED_ROUTES = ['business', 'precheck', 'checkout'];

const SidetabSurvey = () => {
  const {
    market: { id: marketId },
  } = useRouteData();
  const { asPath: pathname } = useRouter();
  const { t } = useTranslation();
  const routeExcluded = EXCLUDED_ROUTES.some((route) => pathname.includes(route));
  const isDesktop = useMedia(createMediaQuery(theme.breakpointDesktop), false);
  const sidetabId = SIDETAB_IDS[marketId];

  if (!isDesktop || !sidetabId || routeExcluded) {
    return null;
  }

  return (
    <Sidetab
      id={sidetabId}
      open="time"
      openValue={SIDETAB_AUTO_OPEN_TIME}
      buttonText={t('sidetabSurvey.ctaLabel')}
      buttonColor={theme.colorBlue700}
    />
  );
};

export { SidetabSurvey, EXCLUDED_ROUTES };
