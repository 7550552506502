import { type TFunction, useTranslation } from 'next-i18next';
import { Container, Grid, GridItem, Heading, Stack } from '@carvertical/ui';
import { Accordion, type AccordionProps } from 'components/common/Accordion';
import { Section, type SectionProps } from '../../landings/common/Section';
import styles from './FaqSection.module.scss';

const FAQ_KEYS = ['service', 'reportInformation', 'reportFormat'] as const;

type FaqSectionProps = {
  items?: AccordionProps['items'];
  title?: string;
  sectionVariant?: SectionProps['variant'];
  className?: string;
};

const getDefaultItems = (t: TFunction) =>
  FAQ_KEYS.map((key) => ({
    id: key,
    headingLevel: 'h3' as const,
    buttonNode: <span>{t(`supportModal.topQuestions.${key}.title`)}</span>,
    contentNode: <span>{t(`supportModal.topQuestions.${key}.description`)}</span>,
  }));

const FaqSection = ({ className, items, sectionVariant = 's', title }: FaqSectionProps) => {
  const { t } = useTranslation('common');

  const defaultItems = getDefaultItems(t);

  const faqItems = items || defaultItems;

  return (
    <Section variant={sectionVariant} color="colorWhite" className={className}>
      <Container className={styles.container}>
        <Grid columnCount={{ mobileUp: 1, tabletLandscapeUp: 12 }}>
          <GridItem
            span={10}
            start={{
              mobileUp: 1,
              tabletLandscapeUp: 2,
            }}
          >
            <Stack gap={{ mobileUp: 5, desktopUp: 8 }} crossAxisAlign="stretch">
              <Stack gap={2}>
                <Heading as="h2" variant="l" align="center">
                  {title || t('general.faqTitle')}
                </Heading>
              </Stack>

              <Accordion items={faqItems} />
            </Stack>
          </GridItem>
        </Grid>
      </Container>
    </Section>
  );
};

export { FaqSection, getDefaultItems };
export type { FaqSectionProps };
