import { b as X, c as U, d as Y, e as Z } from "./Collection.e8e46ab5.js";
import { b as x, g as N, e as ee, $ as te, i as le, c as P, j as ie, k as se, l as D } from "./utils.371a0d8b.js";
import { d as F, f as ne, i as A, h as re } from "./useFocusRing.91eeed6d.js";
import $, { useMemo as g, useState as w, useRef as S, useEffect as M, useCallback as j, forwardRef as k, useContext as B, createContext as H } from "react";
import { c as oe, d as q, b as ae, e as ce } from "./useSelectableItem.ca3ec579.js";
import { $ as de } from "./useHasTabbableChild.8b7adf4c.js";
const G = /* @__PURE__ */ new WeakMap();
function I(i, e, t) {
  return typeof e == "string" && (e = e.replace(/\s+/g, "")), `${G.get(i)}-${t}-${e}`;
}
function ue(i, e, t) {
  let { key: l, isDisabled: s, shouldSelectOnPressUp: n } = i, { selectionManager: r, selectedKey: o } = e, a = l === o, c = s || e.isDisabled || e.selectionManager.isDisabled(l), { itemProps: u, isPressed: d } = oe({
    selectionManager: r,
    key: l,
    ref: t,
    isDisabled: c,
    shouldSelectOnPressUp: n,
    linkBehavior: "selection"
  }), h = I(e, l, "tab"), f = I(e, l, "tabpanel"), { tabIndex: p } = u, b = e.collection.getItem(l), y = F(b == null ? void 0 : b.props, {
    labelable: !0
  });
  delete y.id;
  let K = ne(b == null ? void 0 : b.props);
  return {
    tabProps: x(y, K, u, {
      id: h,
      "aria-selected": a,
      "aria-disabled": c || void 0,
      "aria-controls": a ? f : void 0,
      tabIndex: c ? void 0 : p,
      role: "tab"
    }),
    isSelected: a,
    isDisabled: c,
    isPressed: d
  };
}
function fe(i, e, t) {
  let l = de(t) ? void 0 : 0;
  var s;
  const n = I(e, (s = i.id) !== null && s !== void 0 ? s : e == null ? void 0 : e.selectedKey, "tabpanel"), r = q({
    ...i,
    id: n,
    "aria-labelledby": I(e, e == null ? void 0 : e.selectedKey, "tab")
  });
  return {
    tabPanelProps: x(r, {
      tabIndex: l,
      role: "tabpanel",
      "aria-describedby": i["aria-describedby"],
      "aria-details": i["aria-details"]
    })
  };
}
class he {
  getKeyLeftOf(e) {
    return this.flipDirection ? this.getNextKey(e) : this.getPreviousKey(e);
  }
  getKeyRightOf(e) {
    return this.flipDirection ? this.getPreviousKey(e) : this.getNextKey(e);
  }
  getKeyAbove(e) {
    return this.getPreviousKey(e);
  }
  getKeyBelow(e) {
    return this.getNextKey(e);
  }
  isDisabled(e) {
    var t, l;
    return this.disabledKeys.has(e) || !!(!((l = this.collection.getItem(e)) === null || l === void 0 || (t = l.props) === null || t === void 0) && t.isDisabled);
  }
  getFirstKey() {
    let e = this.collection.getFirstKey();
    return e != null && this.isDisabled(e) && (e = this.getNextKey(e)), e;
  }
  getLastKey() {
    let e = this.collection.getLastKey();
    return e != null && this.isDisabled(e) && (e = this.getPreviousKey(e)), e;
  }
  getNextKey(e) {
    do
      e = this.collection.getKeyAfter(e), e == null && (e = this.collection.getFirstKey());
    while (this.isDisabled(e));
    return e;
  }
  getPreviousKey(e) {
    do
      e = this.collection.getKeyBefore(e), e == null && (e = this.collection.getLastKey());
    while (this.isDisabled(e));
    return e;
  }
  constructor(e, t, l, s = /* @__PURE__ */ new Set()) {
    this.collection = e, this.flipDirection = t === "rtl" && l === "horizontal", this.disabledKeys = s;
  }
}
function ye(i, e, t) {
  let { orientation: l = "horizontal", keyboardActivation: s = "automatic" } = i, { collection: n, selectionManager: r, disabledKeys: o } = e, { direction: a } = ae(), c = g(() => new he(n, a, l, o), [
    n,
    o,
    l,
    a
  ]), { collectionProps: u } = ce({
    ref: t,
    selectionManager: r,
    keyboardDelegate: c,
    selectOnFocus: s === "automatic",
    disallowEmptySelection: !0,
    scrollRef: t,
    linkBehavior: "selection"
  }), d = N();
  G.set(e, d);
  let h = q({
    ...i,
    id: d
  });
  return {
    tabListProps: {
      ...x(u, h),
      role: "tablist",
      "aria-orientation": l,
      tabIndex: void 0
    }
  };
}
class T {
  *[Symbol.iterator]() {
    yield* this.iterable;
  }
  get size() {
    return this.keyMap.size;
  }
  getKeys() {
    return this.keyMap.keys();
  }
  getKeyBefore(e) {
    let t = this.keyMap.get(e);
    return t ? t.prevKey : null;
  }
  getKeyAfter(e) {
    let t = this.keyMap.get(e);
    return t ? t.nextKey : null;
  }
  getFirstKey() {
    return this.firstKey;
  }
  getLastKey() {
    return this.lastKey;
  }
  getItem(e) {
    return this.keyMap.get(e);
  }
  at(e) {
    const t = [
      ...this.getKeys()
    ];
    return this.getItem(t[e]);
  }
  getChildren(e) {
    let t = this.keyMap.get(e);
    return (t == null ? void 0 : t.childNodes) || [];
  }
  constructor(e) {
    this.keyMap = /* @__PURE__ */ new Map(), this.iterable = e;
    let t = (n) => {
      if (this.keyMap.set(n.key, n), n.childNodes && n.type === "section")
        for (let r of n.childNodes)
          t(r);
    };
    for (let n of e)
      t(n);
    let l, s = 0;
    for (let [n, r] of this.keyMap)
      l ? (l.nextKey = n, r.prevKey = l.key) : (this.firstKey = n, r.prevKey = void 0), r.type === "item" && (r.index = s++), l = r, l.nextKey = void 0;
    this.lastKey = l == null ? void 0 : l.key;
  }
}
class v extends Set {
  constructor(e, t, l) {
    super(e), e instanceof v ? (this.anchorKey = t || e.anchorKey, this.currentKey = l || e.currentKey) : (this.anchorKey = t, this.currentKey = l);
  }
}
function J(i, e, t) {
  let [l, s] = w(i || e), n = S(i !== void 0), r = i !== void 0;
  M(() => {
    let c = n.current;
    c !== r && console.warn(`WARN: A component changed from ${c ? "controlled" : "uncontrolled"} to ${r ? "controlled" : "uncontrolled"}.`), n.current = r;
  }, [
    r
  ]);
  let o = r ? i : l, a = j((c, ...u) => {
    let d = (h, ...f) => {
      t && (Object.is(o, h) || t(h, ...f)), r || (o = h);
    };
    typeof c == "function" ? (console.warn("We can not support a function callback. See Github Issues for details https://github.com/adobe/react-spectrum/issues/2320"), s((f, ...p) => {
      let b = c(r ? o : f, ...p);
      return d(b, ...u), r ? f : b;
    })) : (r || s(c), d(c, ...u));
  }, [
    r,
    o,
    t
  ]);
  return [
    o,
    a
  ];
}
function be(i, e) {
  if (i.size !== e.size)
    return !1;
  for (let t of i)
    if (!e.has(t))
      return !1;
  return !0;
}
function $e(i) {
  let { selectionMode: e = "none", disallowEmptySelection: t, allowDuplicateSelectionEvents: l, selectionBehavior: s = "toggle", disabledBehavior: n = "all" } = i, r = S(!1), [, o] = w(!1), a = S(null), c = S(null), [, u] = w(null), d = g(() => z(i.selectedKeys), [
    i.selectedKeys
  ]), h = g(() => z(i.defaultSelectedKeys, new v()), [
    i.defaultSelectedKeys
  ]), [f, p] = J(d, h, i.onSelectionChange), b = g(() => i.disabledKeys ? new Set(i.disabledKeys) : /* @__PURE__ */ new Set(), [
    i.disabledKeys
  ]), [y, K] = w(s);
  s === "replace" && y === "toggle" && typeof f == "object" && f.size === 0 && K("replace");
  let _ = S(s);
  return M(() => {
    s !== _.current && (K(s), _.current = s);
  }, [
    s
  ]), {
    selectionMode: e,
    disallowEmptySelection: t,
    selectionBehavior: y,
    setSelectionBehavior: K,
    get isFocused() {
      return r.current;
    },
    setFocused(m) {
      r.current = m, o(m);
    },
    get focusedKey() {
      return a.current;
    },
    get childFocusStrategy() {
      return c.current;
    },
    setFocusedKey(m, Q = "first") {
      a.current = m, c.current = Q, u(m);
    },
    selectedKeys: f,
    setSelectedKeys(m) {
      (l || !be(m, f)) && p(m);
    },
    disabledKeys: b,
    disabledBehavior: n
  };
}
function z(i, e) {
  return i ? i === "all" ? "all" : new v(i) : e;
}
class ge {
  build(e, t) {
    return this.context = t, L(() => this.iterateCollection(e));
  }
  *iterateCollection(e) {
    let { children: t, items: l } = e;
    if (typeof t == "function") {
      if (!l)
        throw new Error("props.children was a function but props.items is missing");
      for (let s of e.items)
        yield* this.getFullNode({
          value: s
        }, {
          renderer: t
        });
    } else {
      let s = [];
      $.Children.forEach(t, (r) => {
        s.push(r);
      });
      let n = 0;
      for (let r of s) {
        let o = this.getFullNode({
          element: r,
          index: n
        }, {});
        for (let a of o)
          n++, yield a;
      }
    }
  }
  getKey(e, t, l, s) {
    if (e.key != null)
      return e.key;
    if (t.type === "cell" && t.key != null)
      return `${s}${t.key}`;
    let n = t.value;
    if (n != null) {
      var r;
      let o = (r = n.key) !== null && r !== void 0 ? r : n.id;
      if (o == null)
        throw new Error("No key found for item");
      return o;
    }
    return s ? `${s}.${t.index}` : `$.${t.index}`;
  }
  getChildState(e, t) {
    return {
      renderer: t.renderer || e.renderer
    };
  }
  *getFullNode(e, t, l, s) {
    let n = e.element;
    if (!n && e.value && t && t.renderer) {
      let a = this.cache.get(e.value);
      if (a && (!a.shouldInvalidate || !a.shouldInvalidate(this.context))) {
        a.index = e.index, a.parentKey = s ? s.key : null, yield a;
        return;
      }
      n = t.renderer(e.value);
    }
    if ($.isValidElement(n)) {
      let a = n.type;
      if (typeof a != "function" && typeof a.getCollectionNode != "function") {
        let h = typeof n.type == "function" ? n.type.name : n.type;
        throw new Error(`Unknown element <${h}> in collection.`);
      }
      let c = a.getCollectionNode(n.props, this.context), u = e.index, d = c.next();
      for (; !d.done && d.value; ) {
        let h = d.value;
        e.index = u;
        let f = h.key;
        f || (f = h.element ? null : this.getKey(n, e, t, l));
        let b = [
          ...this.getFullNode({
            ...h,
            key: f,
            index: u,
            wrapper: Ke(e.wrapper, h.wrapper)
          }, this.getChildState(t, h), l ? `${l}${n.key}` : n.key, s)
        ];
        for (let y of b) {
          if (y.value = h.value || e.value, y.value && this.cache.set(y.value, y), e.type && y.type !== e.type)
            throw new Error(`Unsupported type <${C(y.type)}> in <${C(s.type)}>. Only <${C(e.type)}> is supported.`);
          u++, yield y;
        }
        d = c.next(b);
      }
      return;
    }
    if (e.key == null)
      return;
    let r = this, o = {
      type: e.type,
      props: e.props,
      key: e.key,
      parentKey: s ? s.key : null,
      value: e.value,
      level: s ? s.level + 1 : 0,
      index: e.index,
      rendered: e.rendered,
      textValue: e.textValue,
      "aria-label": e["aria-label"],
      wrapper: e.wrapper,
      shouldInvalidate: e.shouldInvalidate,
      hasChildNodes: e.hasChildNodes,
      childNodes: L(function* () {
        if (!e.hasChildNodes)
          return;
        let a = 0;
        for (let c of e.childNodes()) {
          c.key != null && (c.key = `${o.key}${c.key}`), c.index = a;
          let u = r.getFullNode(c, r.getChildState(t, c), o.key, o);
          for (let d of u)
            a++, yield d;
        }
      })
    };
    yield o;
  }
  constructor() {
    this.cache = /* @__PURE__ */ new WeakMap();
  }
}
function L(i) {
  let e = [], t = null;
  return {
    *[Symbol.iterator]() {
      for (let l of e)
        yield l;
      t || (t = i());
      for (let l of t)
        e.push(l), yield l;
    }
  };
}
function Ke(i, e) {
  if (i && e)
    return (t) => i(e(t));
  if (i)
    return i;
  if (e)
    return e;
}
function C(i) {
  return i[0].toUpperCase() + i.slice(1);
}
function ve(i, e, t) {
  let l = g(() => new ge(), []), { children: s, items: n, collection: r } = i;
  return g(() => {
    if (r)
      return r;
    let a = l.build({
      children: s,
      items: n
    }, t);
    return e(a);
  }, [
    l,
    s,
    n,
    r,
    t,
    e
  ]);
}
function pe(i, e) {
  return typeof e.getChildren == "function" ? e.getChildren(i.key) : i.childNodes;
}
function me(i) {
  return Se(i, 0);
}
function Se(i, e) {
  if (e < 0)
    return;
  let t = 0;
  for (let l of i) {
    if (t === e)
      return l;
    t++;
  }
}
function E(i, e, t) {
  if (e.parentKey === t.parentKey)
    return e.index - t.index;
  let l = [
    ...O(i, e),
    e
  ], s = [
    ...O(i, t),
    t
  ], n = l.slice(0, s.length).findIndex((r, o) => r !== s[o]);
  return n !== -1 ? (e = l[n], t = s[n], e.index - t.index) : l.findIndex((r) => r === t) >= 0 ? 1 : (s.findIndex((r) => r === e) >= 0, -1);
}
function O(i, e) {
  let t = [];
  for (; (e == null ? void 0 : e.parentKey) != null; )
    e = i.getItem(e.parentKey), t.unshift(e);
  return t;
}
class xe {
  /**
  * The type of selection that is allowed in the collection.
  */
  get selectionMode() {
    return this.state.selectionMode;
  }
  /**
  * Whether the collection allows empty selection.
  */
  get disallowEmptySelection() {
    return this.state.disallowEmptySelection;
  }
  /**
  * The selection behavior for the collection.
  */
  get selectionBehavior() {
    return this.state.selectionBehavior;
  }
  /**
  * Sets the selection behavior for the collection.
  */
  setSelectionBehavior(e) {
    this.state.setSelectionBehavior(e);
  }
  /**
  * Whether the collection is currently focused.
  */
  get isFocused() {
    return this.state.isFocused;
  }
  /**
  * Sets whether the collection is focused.
  */
  setFocused(e) {
    this.state.setFocused(e);
  }
  /**
  * The current focused key in the collection.
  */
  get focusedKey() {
    return this.state.focusedKey;
  }
  /** Whether the first or last child of the focused key should receive focus. */
  get childFocusStrategy() {
    return this.state.childFocusStrategy;
  }
  /**
  * Sets the focused key.
  */
  setFocusedKey(e, t) {
    (e == null || this.collection.getItem(e)) && this.state.setFocusedKey(e, t);
  }
  /**
  * The currently selected keys in the collection.
  */
  get selectedKeys() {
    return this.state.selectedKeys === "all" ? new Set(this.getSelectAllKeys()) : this.state.selectedKeys;
  }
  /**
  * The raw selection value for the collection.
  * Either 'all' for select all, or a set of keys.
  */
  get rawSelection() {
    return this.state.selectedKeys;
  }
  /**
  * Returns whether a key is selected.
  */
  isSelected(e) {
    return this.state.selectionMode === "none" ? !1 : (e = this.getKey(e), this.state.selectedKeys === "all" ? this.canSelectItem(e) : this.state.selectedKeys.has(e));
  }
  /**
  * Whether the selection is empty.
  */
  get isEmpty() {
    return this.state.selectedKeys !== "all" && this.state.selectedKeys.size === 0;
  }
  /**
  * Whether all items in the collection are selected.
  */
  get isSelectAll() {
    if (this.isEmpty)
      return !1;
    if (this.state.selectedKeys === "all")
      return !0;
    if (this._isSelectAll != null)
      return this._isSelectAll;
    let e = this.getSelectAllKeys(), t = this.state.selectedKeys;
    return this._isSelectAll = e.every((l) => t.has(l)), this._isSelectAll;
  }
  get firstSelectedKey() {
    let e = null;
    for (let t of this.state.selectedKeys) {
      let l = this.collection.getItem(t);
      (!e || l && E(this.collection, l, e) < 0) && (e = l);
    }
    return e == null ? void 0 : e.key;
  }
  get lastSelectedKey() {
    let e = null;
    for (let t of this.state.selectedKeys) {
      let l = this.collection.getItem(t);
      (!e || l && E(this.collection, l, e) > 0) && (e = l);
    }
    return e == null ? void 0 : e.key;
  }
  get disabledKeys() {
    return this.state.disabledKeys;
  }
  get disabledBehavior() {
    return this.state.disabledBehavior;
  }
  /**
  * Extends the selection to the given key.
  */
  extendSelection(e) {
    if (this.selectionMode === "none")
      return;
    if (this.selectionMode === "single") {
      this.replaceSelection(e);
      return;
    }
    e = this.getKey(e);
    let t;
    if (this.state.selectedKeys === "all")
      t = new v([
        e
      ], e, e);
    else {
      let l = this.state.selectedKeys, s = l.anchorKey || e;
      t = new v(l, s, e);
      for (let n of this.getKeyRange(s, l.currentKey || e))
        t.delete(n);
      for (let n of this.getKeyRange(e, s))
        this.canSelectItem(n) && t.add(n);
    }
    this.state.setSelectedKeys(t);
  }
  getKeyRange(e, t) {
    let l = this.collection.getItem(e), s = this.collection.getItem(t);
    return l && s ? E(this.collection, l, s) <= 0 ? this.getKeyRangeInternal(e, t) : this.getKeyRangeInternal(t, e) : [];
  }
  getKeyRangeInternal(e, t) {
    let l = [], s = e;
    for (; s; ) {
      let n = this.collection.getItem(s);
      if ((n && n.type === "item" || n.type === "cell" && this.allowsCellSelection) && l.push(s), s === t)
        return l;
      s = this.collection.getKeyAfter(s);
    }
    return [];
  }
  getKey(e) {
    let t = this.collection.getItem(e);
    if (!t || t.type === "cell" && this.allowsCellSelection)
      return e;
    for (; t.type !== "item" && t.parentKey != null; )
      t = this.collection.getItem(t.parentKey);
    return !t || t.type !== "item" ? null : t.key;
  }
  /**
  * Toggles whether the given key is selected.
  */
  toggleSelection(e) {
    if (this.selectionMode === "none")
      return;
    if (this.selectionMode === "single" && !this.isSelected(e)) {
      this.replaceSelection(e);
      return;
    }
    if (e = this.getKey(e), e == null)
      return;
    let t = new v(this.state.selectedKeys === "all" ? this.getSelectAllKeys() : this.state.selectedKeys);
    t.has(e) ? t.delete(e) : this.canSelectItem(e) && (t.add(e), t.anchorKey = e, t.currentKey = e), !(this.disallowEmptySelection && t.size === 0) && this.state.setSelectedKeys(t);
  }
  /**
  * Replaces the selection with only the given key.
  */
  replaceSelection(e) {
    if (this.selectionMode === "none" || (e = this.getKey(e), e == null))
      return;
    let t = this.canSelectItem(e) ? new v([
      e
    ], e, e) : new v();
    this.state.setSelectedKeys(t);
  }
  /**
  * Replaces the selection with the given keys.
  */
  setSelectedKeys(e) {
    if (this.selectionMode === "none")
      return;
    let t = new v();
    for (let l of e)
      if (l = this.getKey(l), l != null && (t.add(l), this.selectionMode === "single"))
        break;
    this.state.setSelectedKeys(t);
  }
  getSelectAllKeys() {
    let e = [], t = (l) => {
      for (; l != null; ) {
        if (this.canSelectItem(l)) {
          let s = this.collection.getItem(l);
          s.type === "item" && e.push(l), s.hasChildNodes && (this.allowsCellSelection || s.type !== "item") && t(me(pe(s, this.collection)).key);
        }
        l = this.collection.getKeyAfter(l);
      }
    };
    return t(this.collection.getFirstKey()), e;
  }
  /**
  * Selects all items in the collection.
  */
  selectAll() {
    !this.isSelectAll && this.selectionMode === "multiple" && this.state.setSelectedKeys("all");
  }
  /**
  * Removes all keys from the selection.
  */
  clearSelection() {
    !this.disallowEmptySelection && (this.state.selectedKeys === "all" || this.state.selectedKeys.size > 0) && this.state.setSelectedKeys(new v());
  }
  /**
  * Toggles between select all and an empty selection.
  */
  toggleSelectAll() {
    this.isSelectAll ? this.clearSelection() : this.selectAll();
  }
  select(e, t) {
    this.selectionMode !== "none" && (this.selectionMode === "single" ? this.isSelected(e) && !this.disallowEmptySelection ? this.toggleSelection(e) : this.replaceSelection(e) : this.selectionBehavior === "toggle" || t && (t.pointerType === "touch" || t.pointerType === "virtual") ? this.toggleSelection(e) : this.replaceSelection(e));
  }
  /**
  * Returns whether the current selection is equal to the given selection.
  */
  isSelectionEqual(e) {
    if (e === this.state.selectedKeys)
      return !0;
    let t = this.selectedKeys;
    if (e.size !== t.size)
      return !1;
    for (let l of e)
      if (!t.has(l))
        return !1;
    for (let l of t)
      if (!e.has(l))
        return !1;
    return !0;
  }
  canSelectItem(e) {
    var t;
    if (this.state.selectionMode === "none" || this.state.disabledKeys.has(e))
      return !1;
    let l = this.collection.getItem(e);
    return !(!l || !(l == null || (t = l.props) === null || t === void 0) && t.isDisabled || l.type === "cell" && !this.allowsCellSelection);
  }
  isDisabled(e) {
    var t, l;
    return this.state.disabledBehavior === "all" && (this.state.disabledKeys.has(e) || !!(!((l = this.collection.getItem(e)) === null || l === void 0 || (t = l.props) === null || t === void 0) && t.isDisabled));
  }
  isLink(e) {
    var t, l;
    return !!(!((l = this.collection.getItem(e)) === null || l === void 0 || (t = l.props) === null || t === void 0) && t.href);
  }
  getItemProps(e) {
    var t;
    return (t = this.collection.getItem(e)) === null || t === void 0 ? void 0 : t.props;
  }
  constructor(e, t, l) {
    this.collection = e, this.state = t;
    var s;
    this.allowsCellSelection = (s = l == null ? void 0 : l.allowsCellSelection) !== null && s !== void 0 ? s : !1, this._isSelectAll = null;
  }
}
function we(i) {
  let { filter: e } = i, t = $e(i), l = g(() => i.disabledKeys ? new Set(i.disabledKeys) : /* @__PURE__ */ new Set(), [
    i.disabledKeys
  ]), s = j((c) => e ? new T(e(c)) : new T(c), [
    e
  ]), n = g(() => ({
    suppressTextValueWarning: i.suppressTextValueWarning
  }), [
    i.suppressTextValueWarning
  ]), r = ve(i, s, n), o = g(() => new xe(r, t), [
    r,
    t
  ]);
  const a = S(null);
  return M(() => {
    if (t.focusedKey != null && !r.getItem(t.focusedKey)) {
      const c = a.current.getItem(t.focusedKey), u = [
        ...a.current.getKeys()
      ].map((y) => {
        const K = a.current.getItem(y);
        return K.type === "item" ? K : null;
      }).filter((y) => y !== null), d = [
        ...r.getKeys()
      ].map((y) => {
        const K = r.getItem(y);
        return K.type === "item" ? K : null;
      }).filter((y) => y !== null), h = u.length - d.length;
      let f = Math.min(h > 1 ? Math.max(c.index - h + 1, 0) : c.index, d.length - 1), p, b = !1;
      for (; f >= 0; ) {
        if (!o.isDisabled(d[f].key)) {
          p = d[f];
          break;
        }
        f < d.length - 1 && !b ? f++ : (b = !0, f > c.index && (f = c.index), f--);
      }
      t.setFocusedKey(p ? p.key : null);
    }
    a.current = r;
  }, [
    r,
    o,
    t,
    t.focusedKey
  ]), {
    collection: r,
    disabledKeys: l,
    selectionManager: o
  };
}
function Ie(i) {
  var e;
  let [t, l] = J(i.selectedKey, (e = i.defaultSelectedKey) !== null && e !== void 0 ? e : null, i.onSelectionChange), s = g(() => t != null ? [
    t
  ] : [], [
    t
  ]), { collection: n, disabledKeys: r, selectionManager: o } = we({
    ...i,
    selectionMode: "single",
    disallowEmptySelection: !0,
    allowDuplicateSelectionEvents: !0,
    selectedKeys: s,
    onSelectionChange: (c) => {
      var u;
      let d = (u = c.values().next().value) !== null && u !== void 0 ? u : null;
      d === t && i.onSelectionChange && i.onSelectionChange(d), l(d);
    }
  }), a = t != null ? n.getItem(t) : null;
  return {
    collection: n,
    disabledKeys: r,
    selectionManager: o,
    selectedKey: t,
    setSelectedKey: l,
    selectedItem: a
  };
}
function Pe(i) {
  var e;
  let t = Ie({
    ...i,
    suppressTextValueWarning: !0,
    defaultSelectedKey: (e = i.defaultSelectedKey) !== null && e !== void 0 ? e : W(i.collection, i.disabledKeys ? new Set(i.disabledKeys) : /* @__PURE__ */ new Set())
  }), { selectionManager: l, collection: s, selectedKey: n } = t, r = S(n);
  return M(() => {
    let o = n;
    (l.isEmpty || !s.getItem(o)) && (o = W(s, t.disabledKeys), o != null && l.setSelectedKeys([
      o
    ])), (o != null && l.focusedKey == null || !l.isFocused && o !== r.current) && l.setFocusedKey(o), r.current = o;
  }), {
    ...t,
    isDisabled: i.isDisabled || !1
  };
}
function W(i, e) {
  let t = null;
  if (i) {
    var l, s, n, r;
    for (t = i.getFirstKey(); (e.has(t) || !((s = i.getItem(t)) === null || s === void 0 || (l = s.props) === null || l === void 0) && l.isDisabled) && t !== i.getLastKey(); )
      t = i.getKeyAfter(t);
    (e.has(t) || !((r = i.getItem(t)) === null || r === void 0 || (n = r.props) === null || n === void 0) && n.isDisabled) && t === i.getLastKey() && (t = i.getFirstKey());
  }
  return t;
}
const R = /* @__PURE__ */ H(null), V = /* @__PURE__ */ H(null);
function Fe(i, e) {
  [i, e] = te(i, e, R);
  let { collection: t, document: l } = X(), { children: s, orientation: n = "horizontal" } = i;
  return s = g(() => typeof s == "function" ? s({
    orientation: n,
    defaultChildren: null
  }) : s, [
    s,
    n
  ]), /* @__PURE__ */ $.createElement($.Fragment, null, /* @__PURE__ */ $.createElement(le, null, /* @__PURE__ */ $.createElement(U.Provider, {
    value: l
  }, s)), /* @__PURE__ */ $.createElement(Me, {
    props: i,
    collection: t,
    tabsRef: e
  }));
}
function Me({ props: i, tabsRef: e, collection: t }) {
  let { orientation: l = "horizontal" } = i, s = Pe({
    ...i,
    collection: t,
    children: void 0
  }), { focusProps: n, isFocused: r, isFocusVisible: o } = A({
    within: !0
  }), a = g(() => ({
    orientation: l,
    isFocusWithin: r,
    isFocusVisible: o
  }), [
    l,
    r,
    o
  ]), c = P({
    ...i,
    defaultClassName: "react-aria-Tabs",
    values: a
  });
  return /* @__PURE__ */ $.createElement("div", {
    ...F(i),
    ...n,
    ...c,
    ref: e,
    slot: i.slot || void 0,
    "data-focused": r || void 0,
    "data-orientation": l,
    "data-focus-visible": o || void 0,
    "data-disabled": s.isDisabled || void 0
  }, /* @__PURE__ */ $.createElement(ie, {
    values: [
      [
        R,
        i
      ],
      [
        V,
        s
      ]
    ]
  }, c.children));
}
const Le = /* @__PURE__ */ k(Fe);
function Ce(i, e) {
  return B(U) ? /* @__PURE__ */ $.createElement(Y, i) : /* @__PURE__ */ $.createElement(Ee, {
    props: i,
    forwardedRef: e
  });
}
function Ee({ props: i, forwardedRef: e }) {
  let t = B(V), { orientation: l = "horizontal", keyboardActivation: s = "automatic" } = se(R), n = D(e), { tabListProps: r } = ye({
    ...i,
    orientation: l,
    keyboardActivation: s
  }, t, n), o = P({
    ...i,
    children: null,
    defaultClassName: "react-aria-TabList",
    values: {
      orientation: l,
      state: t
    }
  }), a = F(i);
  return delete a.id, /* @__PURE__ */ $.createElement("div", {
    ...a,
    ...r,
    ref: n,
    ...o,
    "data-orientation": l || void 0
  }, [
    ...t.collection
  ].map((c) => /* @__PURE__ */ $.createElement(Ae, {
    key: c.key,
    item: c,
    state: t
  })));
}
const Oe = /* @__PURE__ */ k(Ce);
function De(i, e) {
  return Z("item", i, e, i.children);
}
const We = /* @__PURE__ */ k(De);
function Ae({ item: i, state: e }) {
  let t = D(i.props.ref), { tabProps: l, isSelected: s, isDisabled: n, isPressed: r } = ue({
    key: i.key,
    ...i.props
  }, e, t), { focusProps: o, isFocused: a, isFocusVisible: c } = A(), { hoverProps: u, isHovered: d } = re({
    isDisabled: n
  }), h = P({
    ...i.props,
    children: i.rendered,
    defaultClassName: "react-aria-Tab",
    values: {
      isSelected: s,
      isDisabled: n,
      isFocused: a,
      isFocusVisible: c,
      isPressed: r,
      isHovered: d,
      state: e
    }
  }), f = i.props.href ? "a" : "div";
  return /* @__PURE__ */ $.createElement(f, {
    ...x(l, o, u, h),
    ref: t,
    "data-selected": s || void 0,
    "data-disabled": n || void 0,
    "data-focused": a || void 0,
    "data-focus-visible": c || void 0,
    "data-pressed": r || void 0,
    "data-hovered": d || void 0
  });
}
function ke(i, e) {
  const t = B(V);
  let l = D(e), { tabPanelProps: s } = fe(i, t, l), { focusProps: n, isFocused: r, isFocusVisible: o } = A(), a = t.selectedKey === i.id, c = P({
    ...i,
    defaultClassName: "react-aria-TabPanel",
    values: {
      isFocused: r,
      isFocusVisible: o,
      isInert: !a,
      state: t
    }
  });
  if (!a && !i.shouldForceMount)
    return null;
  let u = F(i);
  delete u.id;
  let d = a ? x(u, s, n, c) : c;
  return /* @__PURE__ */ $.createElement("div", {
    ...d,
    ref: l,
    "data-focused": r || void 0,
    "data-focus-visible": o || void 0,
    // @ts-ignore
    inert: a ? void 0 : "true",
    "data-inert": a ? void 0 : "true"
  });
}
const Ue = /* @__PURE__ */ ee(ke);
export {
  Le as $,
  We as a,
  Oe as b,
  Ue as c
};
