import styles from './PaymentLogo.module.scss';

type CommonProps = {
  title: string;
  height: number;
  scale?: number;
};

type PaymentLogoProps = CommonProps &
  (
    | {
        logo: string;
        logoSrc?: never;
      }
    | {
        logoSrc: string;
        logo?: never;
      }
  );

const HEIGHT_SCALE: Record<string, number> = {
  visa: 0.6,
  mastercard: 0.9,
  discover: 0.5,
  paypal: 0.9,
  btc: 0.9,
};

const PaymentLogo = ({
  height,
  logo,
  logoSrc,
  scale: initialScale = 1,
  title,
}: PaymentLogoProps) => {
  const scale = (logo && HEIGHT_SCALE[logo]) || initialScale;

  return (
    <li className={styles.root} style={{ height }}>
      <img
        className={styles.image}
        src={logoSrc ?? `/images/payment-methods/${logo}.png`}
        alt={title}
        style={{
          ...(scale < 1 && {
            height: `${Math.round(100 * scale)}%`,
          }),
          ...(scale > 1 && { transform: `scale(${scale})` }),
        }}
      />
    </li>
  );
};

export { PaymentLogo };
export type { PaymentLogoProps };
