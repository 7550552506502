import {
  Button,
  SkeletonWrapper,
  StatusMessage,
  TextField,
  type SkeletonWrapperProps,
} from '@carvertical/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, type MutationFunction } from '@tanstack/react-query';
import type { ParseKeys } from 'i18next';
import { defaults, isNull, omitBy, omit } from 'lodash';
import { useTranslation } from 'next-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { cn } from '@carvertical/utils/styling';
import { useState } from 'react';
import RadioGroup from 'components/common/RadioGroup';
import { VatInput } from 'components/common/VatInput';
import { FormField } from 'components/common/ui/forms';
import { useAutoAnimate } from 'hooks/useAutoAnimate';
import { captureError } from 'services/errors';
import { UserType } from 'types/user';
import { Select } from 'components/common/ui/forms/Select';
import { useFeatureFlagEnabled } from 'modules/analytics';
import { type UserSettingsFormFields, userSettingsFormSchema } from '../schemas';

type BusinessCategory =
  | 'select'
  | 'car-dealer'
  | 'insurance'
  | 'car-inspection'
  | 'financing'
  | 'other';

type SelectLabelKey = `user:${ParseKeys<'user'>}` | `common:${ParseKeys}`;

type UserTypeOption = { value: BusinessCategory; labelKey: SelectLabelKey };

type UserSettingsFormProps = {
  initialValues: UserSettingsFormFields & { id: string; loading: boolean };
  onSubmit: (values: UserSettingsFormFields) => Promise<void>;
};

const USER_TYPE_OPTIONS: UserTypeOption[] = [
  {
    value: 'select',
    labelKey: 'common:general.selectAction',
  },
  {
    value: 'car-dealer',
    labelKey: 'user:userForm.businessCategories.carDealerLabel',
  },
  {
    value: 'insurance',
    labelKey: 'user:userForm.businessCategories.insuranceLabel',
  },
  {
    value: 'car-inspection',
    labelKey: 'user:userForm.businessCategories.carInspectionLabel',
  },
  {
    value: 'financing',
    labelKey: 'user:userForm.businessCategories.financingLabel',
  },
  {
    value: 'other',
    labelKey: 'user:userForm.businessCategories.otherLabel',
  },
];

const scrollToTop = () => window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });

const useUserSettings = (mutationFn: MutationFunction<unknown, UserSettingsFormFields>) => {
  const { isError, isPending, isSuccess, mutateAsync, reset } = useMutation({
    mutationFn,
    onError: (error) => {
      captureError(error);
      scrollToTop();
    },
    onSuccess: scrollToTop,
  });

  return {
    isError,
    isLoading: isPending,
    isSuccess,
    reset,
    update: mutateAsync,
  };
};

const UserSettingsForm = ({ initialValues, onSubmit }: UserSettingsFormProps) => {
  const { t } = useTranslation(['user', 'common']);
  const [wrapperRef] = useAutoAnimate();
  const { isError, isLoading, isSuccess, reset, update } = useUserSettings(onSubmit);
  const savedCardsEnabled = useFeatureFlagEnabled('savedCards');
  const [vatValidPromise, setVatValidPromise] = useState<Promise<boolean>>(Promise.resolve(false));

  const { loading } = initialValues;
  const usernameFieldShown = initialValues.username !== initialValues.email;

  const defaultValues = defaults<
    Partial<UserSettingsFormFields>,
    Partial<
      Omit<UserSettingsFormFields, 'businessCategory'> & {
        businessCategory: BusinessCategory;
      }
    >
  >(omitBy(initialValues, isNull), {
    userType: UserType.Individual,
    vatCode: '',
  });

  const form = useForm<UserSettingsFormFields>({
    defaultValues,
    resolver: zodResolver(userSettingsFormSchema),
    values: defaultValues,
  });

  const {
    handleSubmit,
    watch,
    resetField,
    formState: { touchedFields },
  } = form;

  const vatCode = watch('vatCode');
  const userType = watch('userType');
  const carDealerSelected = watch('businessCategory') === 'car-dealer';

  const submitUserSettings = async (values: UserSettingsFormFields) => {
    const vatValid = await vatValidPromise;
    if (vatCode && touchedFields.vatCode && !vatValid) {
      return;
    }

    update(values.businessCategory === 'select' ? omit(values, ['businessCategory']) : values);
  };

  const changeUserType = () => {
    resetField('email');
    resetField('phone');
    resetField('address');
  };

  const skeletonWrapperProps: SkeletonWrapperProps = { loading, radius: 'l' };

  const Field = FormField<UserSettingsFormFields>;

  return (
    <FormProvider {...form}>
      <form
        className={cn('flex flex-col gap-4 sm:w-48', savedCardsEnabled && 'mt-6')}
        noValidate
        onSubmit={handleSubmit(submitUserSettings)}
        ref={wrapperRef}
      >
        {isSuccess && (
          <StatusMessage
            closeButtonShown
            onCloseButtonClick={reset}
            title={t('common:general.successTitle')}
            variant="success"
          >
            {t('common:general.successText')}
          </StatusMessage>
        )}

        {isError && (
          <StatusMessage
            closeButtonShown
            onCloseButtonClick={reset}
            title={t('common:general.errorTitle')}
            variant="error"
          >
            {t('common:general.errorText')}
          </StatusMessage>
        )}

        <RadioGroup
          name="userType"
          className="mb-1 gap-4 sm:gap-8"
          onChange={changeUserType}
          loading={loading}
          items={[
            {
              value: UserType.Individual,
              label: t('user:userForm.individualLabel'),
            },
            {
              value: UserType.Company,
              label: t('user:userForm.companyLabel'),
            },
          ]}
        />

        {usernameFieldShown && (
          <Field name="username">
            <TextField disabled fullWidth label={t('user:userForm.usernameLabel')} />
          </Field>
        )}

        <SkeletonWrapper {...skeletonWrapperProps}>
          <Field name="email">
            <TextField
              type="email"
              autoComplete="email"
              label={t('user:userForm.emailLabel')}
              fullWidth
            />
          </Field>
        </SkeletonWrapper>

        <SkeletonWrapper {...skeletonWrapperProps}>
          <Field name="phone">
            <TextField
              type="tel"
              autoComplete="tel"
              label={`${t('user:userForm.phoneLabel')} (${t('common:general.optionalLabel')})`}
              fullWidth
            />
          </Field>
        </SkeletonWrapper>

        {userType === UserType.Individual && (
          <>
            <SkeletonWrapper {...skeletonWrapperProps}>
              <Field name="firstName">
                <TextField
                  autoComplete="given-name"
                  label={t('user:userForm.firstNameLabel')}
                  fullWidth
                />
              </Field>
            </SkeletonWrapper>

            <SkeletonWrapper {...skeletonWrapperProps}>
              <Field name="lastName">
                <TextField
                  autoComplete="family-name"
                  label={t('user:userForm.lastNameLabel')}
                  fullWidth
                />
              </Field>
            </SkeletonWrapper>
          </>
        )}

        {userType === UserType.Company && (
          <>
            <SkeletonWrapper {...skeletonWrapperProps}>
              <Field name="companyName">
                <TextField
                  autoComplete="organization"
                  label={t('user:userForm.companyNameLabel')}
                  fullWidth
                />
              </Field>
            </SkeletonWrapper>

            <SkeletonWrapper {...skeletonWrapperProps}>
              <Field name="entityCode">
                <TextField label={t('user:userForm.entityCodeLabel')} fullWidth />
              </Field>
            </SkeletonWrapper>

            <SkeletonWrapper {...skeletonWrapperProps}>
              <Field name="businessCategory">
                <Select
                  fullWidth
                  label={t('user:userForm.businessCategoryLabel')}
                  options={USER_TYPE_OPTIONS.map(({ labelKey, value }) => ({
                    label: t(labelKey),
                    value,
                  }))}
                  disabled={isLoading}
                />
              </Field>
            </SkeletonWrapper>

            {carDealerSelected && (
              <SkeletonWrapper {...skeletonWrapperProps}>
                <Field name="dealerManagementSystem">
                  <TextField
                    label={`${t('user:userForm.dmsLabel')} (${t('common:general.optionalLabel')})`}
                    fullWidth
                  />
                </Field>
              </SkeletonWrapper>
            )}
          </>
        )}

        <SkeletonWrapper {...skeletonWrapperProps}>
          <Field name="address">
            <TextField
              autoComplete="street-address"
              label={`${t('user:userForm.addressLabel')} ${userType === UserType.Individual ? `(${t('common:general.optionalLabel')})` : ''} `}
              fullWidth
            />
          </Field>
        </SkeletonWrapper>

        <SkeletonWrapper {...skeletonWrapperProps}>
          <VatInput fullWidth name="vatCode" onLoading={setVatValidPromise} />
        </SkeletonWrapper>

        <SkeletonWrapper {...skeletonWrapperProps}>
          <Button type="submit" disabled={isLoading} variant="blue" className="w-full sm:max-w-fit">
            {isLoading
              ? `${t('common:general.savingLabel')}…`
              : t('common:general.saveChangesAction')}
          </Button>
        </SkeletonWrapper>
      </form>
    </FormProvider>
  );
};

export { UserSettingsForm };
