import { $ } from "./utils.371a0d8b.js";
import { a as c } from "./Collection.e8e46ab5.js";
import o, { forwardRef as d, createContext as b } from "react";
const p = /* @__PURE__ */ b({});
function m(e, a) {
  let [r, f] = $(e, a, p), t = c("header", e, a);
  return t || /* @__PURE__ */ o.createElement("header", {
    className: "react-aria-Header",
    ...r,
    ref: f
  }, r.children);
}
const x = /* @__PURE__ */ d(m);
export {
  x as $
};
