import { Button, Modal, ModalTitle } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import type { ParseKeys } from 'i18next';
import { useEffect } from 'react';
import { useModal } from 'modules/shared';
import { useEventTracking } from 'modules/analytics';
import InformationIntegrityIllustration from '../assets/information-integrity.svg';

// FIXME: https://carvertical.atlassian.net/browse/FE-12
const EXPLANATION_KEYS: ParseKeys<'precheck'>[] = [
  // @ts-expect-error TS(2322)
  'refundPolicy.emptyReportExplanation.0',
  // @ts-expect-error TS(2322)
  'refundPolicy.emptyReportExplanation.1',
  // @ts-expect-error TS(2322)
  'refundPolicy.emptyReportExplanation.2',
  // @ts-expect-error TS(2322)
  'refundPolicy.emptyReportExplanation.3',
];

// FIXME: https://carvertical.atlassian.net/browse/FE-12
const REASONS_KEYS: ParseKeys<'precheck'>[] = [
  // @ts-expect-error TS(2322)
  'refundPolicy.emptyReportReasons.0',
  // @ts-expect-error TS(2322)
  'refundPolicy.emptyReportReasons.1',
];

const RefundPolicyModal = () => {
  const { t } = useTranslation(['precheck', 'common']);
  const { isModalOpen, close } = useModal('refundPolicy');
  const { track } = useEventTracking();

  useEffect(() => {
    if (isModalOpen) {
      track({ event: 'RefundPolicyOpened' });
    }
  }, [isModalOpen, track]);

  return (
    <Modal
      open={isModalOpen}
      onClose={close}
      size="m"
      footer={
        <Button className="min-w-15" onClick={close} variant="blue">
          {t('common:general.closeAction')}
        </Button>
      }
    >
      <ModalTitle>{t('refundPolicy.title')}</ModalTitle>

      <div className="mt-4 flex flex-col gap-3">
        <div className="flex flex-col gap-2 sm:flex-row sm:items-end">
          <InformationIntegrityIllustration className="size-8 sm:relative sm:-top-1" original />

          <h3 className="text-xl+">{t('refundPolicy.emptyReportQuestion')}</h3>
        </div>

        <div className="flex flex-col gap-1.5">
          {EXPLANATION_KEYS.map((key) => (
            <p key={key} className="text-m">
              {t(key)}
            </p>
          ))}

          <ul className="m-0">
            {REASONS_KEYS.map((key) => (
              <li key={key} className="text-m">
                {t(key)}
              </li>
            ))}
          </ul>

          <p className="text-m">{t('refundPolicy.emptyReportDisclaimer')}</p>
        </div>
      </div>
    </Modal>
  );
};

export { RefundPolicyModal };
