import { ImageBlock } from './ImageBlock';
import { TableBlock } from './TableBlock';
import { Faq } from './Faq';
import { VinCheck } from './VinCheck';
import { HtmlBlock } from './HtmlBlock';

const blockComponents = {
  'block.image': ImageBlock,
  'block.table': TableBlock,
  'block.faq': Faq,
  'block.vincheck': VinCheck,
  'block.html': HtmlBlock,
};

export { blockComponents };
