import { j as n } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as i } from "./chunks/clsx.0d472180.js";
import { forwardRef as m } from "react";
import { o as p } from "./chunks/styles.077839a8.js";
const r = m(
  ({ as: e = "div", className: o, children: a, ...t }, s) => /* @__PURE__ */ n.jsx(e, { ref: s, className: i(p.root, o), ...t, children: a })
);
try {
  r.displayName = "Container", r.__docgenInfo = { description: "", displayName: "Container", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } } } };
} catch {
}
export {
  r as Container
};
