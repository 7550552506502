import { includes } from 'lodash';
import dynamic from 'next/dynamic';
import type React from 'react';
import type { ParseKeys } from 'i18next';
import { PaymentMethod } from 'types/payment';
import { BGN, BYN, HRK, RON, RSD, UAH } from 'constants/currencies';
import { isBusinessPackage } from 'utils/packages';
import type { RouteDataContextState } from 'context/RouteDataProvider';
import type { MarketId } from 'types/market';
import {
  CardMethod,
  PayPalMethod,
  PayseraMethod,
  NeopayMethod,
  DotpayMethod,
  TpayMethod,
  SofortMethod,
  MBWayMethod,
  ApplePayMethod,
  // CryptoMethod,
  WireMethod,
  GiftMethod,
  BlikMethod,
  GooglePayMethod,
} from './methods';

type LogoComponent = React.ComponentType<{
  height: number;
  collapsedCountShown: boolean;
  excludePrimaryPaymentOptions?: boolean;
  price?: { amount: number; currency: string };
}>;

type Method = {
  Component?: React.ForwardRefExoticComponent<
    React.RefAttributes<unknown> & {
      success?: boolean;
      methodRef?: React.RefObject<unknown>;
    }
  >;
  title?: string;
  titleKey?: ParseKeys<'checkout'>;
  logos?: string[];
  LogosComponent?: LogoComponent;
  id: PaymentMethod;
  common?: boolean;
  standalone?: boolean;
  unsupportedCurrencies?: string[];
  isPrimary?: (marketId?: string) => boolean;
  when?: (params: {
    router: RouteDataContextState<{ query: { package: string } }>;
    active: boolean;
  }) => boolean;
};

type PaymentMethods = {
  [key in PaymentMethod]: Method;
};

const PAYPAL_PRIMARY_MARKETS: MarketId[] = [
  'europe',
  'germany',
  'france',
  'britain',
  'italy',
  'australia',
  'mexico',
];
const BLIK_PRIMARY_MARKETS: MarketId[] = ['poland'];

const PAYMENT_METHODS: PaymentMethods = {
  [PaymentMethod.Blik]: {
    id: PaymentMethod.Blik,
    title: 'Blik',
    Component: BlikMethod,
    isPrimary: (marketId) => includes(BLIK_PRIMARY_MARKETS, marketId),
  },
  [PaymentMethod.ApplePay]: {
    id: PaymentMethod.ApplePay,
    Component: ApplePayMethod,
    isPrimary: () => true,
  },
  [PaymentMethod.GooglePay]: {
    id: PaymentMethod.GooglePay,
    Component: GooglePayMethod,
    isPrimary: () => true,
  },
  [PaymentMethod.Wire]: {
    id: PaymentMethod.Wire,
    titleKey: 'paymentMethods.wire.title',
    Component: WireMethod,
    logos: ['bank'],
    common: true,
    when: ({ router }) => isBusinessPackage(router.query.package),
  },
  [PaymentMethod.Gift]: {
    id: PaymentMethod.Gift,
    titleKey: 'paymentMethods.gift.title',
    Component: GiftMethod,
    common: true,
    standalone: true,
    when: ({ active }) => active,
  },
  [PaymentMethod.Card]: {
    id: PaymentMethod.Card,
    titleKey: 'paymentMethods.card.title',
    logos: ['visa', 'mastercard', 'amex', 'discover'],
    Component: CardMethod,
  },
  [PaymentMethod.Paypal]: {
    id: PaymentMethod.Paypal,
    title: 'PayPal',
    logos: ['paypal'],
    Component: PayPalMethod,
    unsupportedCurrencies: [BGN, BYN, HRK, RON, RSD, UAH],
    isPrimary: (marketId) => includes(PAYPAL_PRIMARY_MARKETS, marketId),
  },
  [PaymentMethod.Paysera]: {
    id: PaymentMethod.Paysera,
    titleKey: 'paymentMethods.paysera.title',
    LogosComponent: dynamic(() => import('../common/PaymentLogos/PayseraLogos'), {
      ssr: false,
    }),
    Component: PayseraMethod,
  },
  [PaymentMethod.Neopay]: {
    id: PaymentMethod.Neopay,
    titleKey: 'paymentMethods.paysera.title',
    LogosComponent: dynamic(() => import('../common/PaymentLogos/NeopayLogos'), {
      ssr: false,
    }),
    Component: NeopayMethod,
  },
  [PaymentMethod.Dotpay]: {
    id: PaymentMethod.Dotpay,
    titleKey: 'paymentMethods.dotpay.title',
    LogosComponent: dynamic(() => import('../common/PaymentLogos/DotpayLogos'), {
      ssr: false,
    }),
    Component: DotpayMethod,
  },
  [PaymentMethod.Tpay]: {
    id: PaymentMethod.Tpay,
    titleKey: 'paymentMethods.tpay.title',
    LogosComponent: dynamic(() => import('../common/PaymentLogos/TpayLogos'), {
      ssr: false,
    }),
    Component: TpayMethod,
  },
  [PaymentMethod.Sofort]: {
    id: PaymentMethod.Sofort,
    title: 'Sofort',
    logos: ['sofort'],
    Component: SofortMethod,
  },
  [PaymentMethod.MBWay]: {
    id: PaymentMethod.MBWay,
    title: 'MB WAY',
    logos: ['mbway'],
    Component: MBWayMethod,
  },
  // TODO: [LAUNCH-2332] - enable crypto payments once alternative to Coingate is ready
  // [PaymentMethod.Crypto]: {
  //   id: PaymentMethod.Crypto,
  //   titleKey: 'paymentMethods.crypto.title',
  //   logos: ['btc', 'eth', 'cv'],
  //   Component: CryptoMethod,
  //   common: true,
  //   when: ({ router }) => !isBusinessPackage(router.query.package),
  // },
};

export { PAYMENT_METHODS };
export type { Method };
